import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCustomerEntryRootComponent } from './components/new-customer-entry-root/new-customer-entry-root.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    NewCustomerEntryRootComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class NewCustomerEntryModule { }
