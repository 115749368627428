import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Address } from '../../interfaces/address';
import { Customer } from '../../interfaces/customer';
import { AddressSelectDialogComponent } from '../address-select-dialog/address-select-dialog.component';

@Component({
  selector: 'app-ship-to[customer]',
  templateUrl: './ship-to.component.html',
  styleUrls: ['./ship-to.component.scss']
})
export class ShipToComponent implements OnInit {
  @Input() customer!: Customer;
  @Input() address: Address | null = null;
  @Output() addressChange = new EventEmitter<Address>()

  get shouldShowEditButton() {
    return this.customer.addresses.length > 1 || !this.address;
  }

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  presentSelectAddress() {
    const dialogRef = this.dialog.open(AddressSelectDialogComponent, { data: this.customer.addresses });
    dialogRef.afterClosed().subscribe(address => {
      if (address) {
        this.address = address
        this.addressChange.emit(address);
      }
    });
  }
}
