import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRootComponent } from './components/user-root/user-root.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';



@NgModule({
  declarations: [
    UserRootComponent,
    ChangePasswordDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class UserModule { }
