import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-info-dialog',
  templateUrl: './status-info-dialog.component.html',
  styleUrls: ['./status-info-dialog.component.scss']
})
export class StatusInfoDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
