import { Injectable } from '@angular/core';
import { WebProviderService } from './web.provider.service';
import { NetsuiteCustomer } from '../interfaces/netsuite-customer';
import { NETSUITECUSTOMER } from '../constants/netsuite-customer';
import { assert } from '../utils/assert.util';
import { NetsuiteCustomerContact } from '../interfaces/netsuite-customer-contact';

@Injectable({
  providedIn: 'root'
})
export class NetsuiteCustomerProviderService {

  constructor(private webClient: WebProviderService) { }

  async create(customer: NetsuiteCustomer) {
    const netsuiteCustomerID = await this.webClient.post<number>(`${NETSUITECUSTOMER.API_URL}`, customer);
    assert(netsuiteCustomerID, 'No netsuite customer id returned');
    return netsuiteCustomerID;
  }

  async update(netsuiteID: number, customer: NetsuiteCustomer) {
    await this.webClient.patch(`${NETSUITECUSTOMER.API_URL}/${netsuiteID}`, customer);
  }

  async updateContacts(netsuiteID: number, contacts: NetsuiteCustomerContact[]) {
    await this.webClient.patch(`${NETSUITECUSTOMER.API_URL}/${netsuiteID}/contacts`, contacts);
  }
}
