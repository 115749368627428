import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/shared/interfaces/customer';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';

@Component({
  selector: 'app-edit-stock-balance-discontinued-carved-item-properties',
  templateUrl: './edit-stock-balance-discontinued-carved-item-properties.component.html',
  styleUrls: ['./edit-stock-balance-discontinued-carved-item-properties.component.scss']
})
export class EditStockBalanceDiscontinuedCarvedItemPropertiesComponent implements OnInit {
  @Input() lineItem!: StockBalanceLineItem
  @Output() lineItemChange = new EventEmitter<LineItem>();
  eligibilityReason: string = "";

  formGroup = this.formBuilder.group({
    freeformSKU: [this.lineItem?.freeformSKU, Validators.required],
    quantity: [this.lineItem?.quantity, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]]
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity, { emitEvent: false });
    this.formGroup.controls.freeformSKU.setValue(this.lineItem.freeformSKU, { emitEvent: false });

    this.formGroup.controls.freeformSKU.valueChanges.subscribe((value) => {
      this.lineItem.freeformSKU = value;
      this.lineItemChange.emit(this.lineItem);
    });

    this.formGroup.controls.quantity.valueChanges.subscribe((value) => {
      this.lineItem.quantity = value ?? 0;
    });

    this.formGroup.controls.freeformSKU.markAsTouched();
  }

  upButtonClicked() {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity + 1);
    this.lineItemChange.emit(this.lineItem);
  }

  downButtonClicked() {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity - 1);
    this.lineItemChange.emit(this.lineItem);
  }

  quantityEditEnded() {
    if (this.lineItem.quantity === null) {
      this.lineItem.quantity = 0;
    }
    this.lineItemChange.emit(this.lineItem);
  }
}
