<div class="InputContainer Flexbox Flexbox--row Flexbox--align-center">
  <app-async-auto-complete class="ItemSelect" label="Order - Enter Style #" [options]="options" (textChange)="autoCompleteTextChange($event)" (valueChanged)="itemSelected($event)" [itemLimit]="15"></app-async-auto-complete>
  <app-auto-complete #searchField appExpandableField [ngClass]="autoCompleteClasses" class="ItemSearch" label="Search" [options]="searchableLineItemOptions" (valueChanged)="searchSelected($event)" (openAnimationEnd)="searchField.resetPanelSize()"></app-auto-complete>
</div>
<div class="ProformaContent Flexbox Flexbox--column">
  <div class="ItemList Flexbox Flexbox--column">
    <div [ngClass]="isDuplicateLineItem(lineItem) && shouldHighlightDuplicates ? 'Duplicate' : ''" class="ItemContainer" *ngFor="let lineItem of lineItems; let i = index">
      <app-edit-item [attr.data-key]="lineItem.key" [lineItem]="lineItem" (lineItemChange)="lineItemChanged(lineItems[i])" [type]="getType(lineItem)" (removeClicked)="removeLineItem(lineItem)" (copyClicked)="copyLineItem(lineItem)" (quickCopyClicked)="quickCopyLineItem(lineItem)" (editButtonClicked)="onEditButtonClicked(i)"></app-edit-item>
    </div>
  </div>

  <div class="Summary Flexbox">
    <mat-card>
      <mat-card-content>
          <table>
            <tr><td>Order Qty</td><td>{{ orderQuantity }}</td></tr>
            <tr><td>Order Total</td><td>{{ orderTotal | money }}</td></tr>
          </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
