<form class="example-form">
  <mat-form-field #formField class="example-full-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input #input [value]="selectedOption?.value" (ngModelChange)="inputTextChanged($event)" (keydown)="inputKeyDown($event)" type="text"
           placeholder="Pick one"
           aria-label="label"
           matInput
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions.value" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>