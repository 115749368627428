import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicate-line-item-dialog',
  templateUrl: './duplicate-line-item-dialog.component.html',
  styleUrls: ['./duplicate-line-item-dialog.component.scss']
})
export class DuplicateLineItemDialogComponent implements OnInit {
  quantity: number = 1;
  constructor() { }

  ngOnInit(): void {
  }

  quantityChanged() {
    this.quantity = Math.max(0, this.quantity);
  }
}
