import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from '../../interfaces/address';

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss']
})
export class AddressSelectComponent implements OnInit {
  @Input() addresses: Address[] = [];
  @Output() addressChange = new EventEmitter<Address>();

  constructor() { }

  ngOnInit(): void {
  }

}
