<h1 mat-dialog-title>Order Info</h1>
<mat-dialog-content>
<div class="MainContent">
<h2 id="sync-times">Sync Times</h2>
<pre class="hljs"><code><div>items: 3:00 AM CT
customers: 9:30 AM CT, 1:30 PM CT, 5:30 PM CT, 9:30 PM CT
orders: 2:00 PM CT, 10:00 PM CT
returns: 2:00 PM CT, 10:00 PM CT
</div></code></pre>
<h2 id="emails-and-recipients">Emails and Recipients</h2>
<h3 id="order-exports-and-export-reminders">Order Exports and Export Reminders</h3>
<p>Triggered when AR approves an active customer's order, a new customer and its order is synced to ROB from HALO, or
if any orders that have been exported from ROB, but not imported into HALO</p>
<pre class="hljs"><code><div>Greyson
Brett
Christina
</div></code></pre>
<h3 id="ar-approval">AR Approval</h3>
<p>Triggered when AR clicks the approve button on an order</p>
<pre class="hljs"><code><div>Rep
Retailer
Frank
Brett
Sonya
Laura
Christina
Greyson
AJ
</div></code></pre>
<h3 id="ar-denial">AR Denial</h3>
<p>Triggered when AR clicks the deny button on an order. Reps will need to reach out to the customer in this case,
because the customer does not receive an email from the system</p>
<pre class="hljs"><code><div>Rep
Frank
Brett
Sonya
Laura
Greyson
AJ
</div></code></pre>
<h3 id="retailer-approval">Retailer Approval</h3>
<p>Triggered when the retailer submits their signature on an order</p>
<pre class="hljs"><code><div>Rep
Retailer
Brett
Sonya
Laura
Greyson
AJ
</div></code></pre>
<h3 id="stock-balance-confirmation">Stock Balance Confirmation</h3>
<p>Triggered when an order with a stock balance has been submitted. Informs the customer that they should not begin
packing their return yet</p>
<pre class="hljs"><code><div>Rep
Retailer
Brett
Greyson
Christina
</div></code></pre>
<h3 id="packing-list">Packing List</h3>
<p>Triggered when AR clicks the approve button on an order with a stock balance</p>
<pre class="hljs"><code><div>Rep
Retailer
Frank
Brett
Greyson
Christina
</div></code></pre>
<h3 id="expiring-quote-day-3-reminder">Expiring Quote Day 3 Reminder</h3>
<p>Triggered on the third day from the last modified date of a quote that has not been signed by a retailer</p>
<pre class="hljs"><code><div>Rep
Retailer
Brett
Greyson
</div></code></pre>
<h3 id="expiring-draft-day-6-reminder">Expiring Quote Day 6 Reminder</h3>
<p>Triggered on the final day from the last modified date of a quote that has not been signed by a retailer</p>
<pre class="hljs"><code><div>Rep
Retailer
Brett
Greyson
</div></code></pre>
<h2 id="new-customers">New Customers</h2>
<p>When creating a new customer, please carry out the following steps:</p>
<ol>
<li>Do a quick search for the customer's name on the order entry page, especially if you know they were a past
customer.</li>
<li><strong>ENTER A JBT NUMBER IF THEY HAVE ONE. THIS WILL SAVE YOU TIME IF THEY EXIST</strong></li>
<li>Have the customer verify their info before submitting (possibly even have them enter the contact info and
address info).</li>
<li>If the customer has not received a confirmation email after the order has been submitted, and you have already
received it, ask them to check their spam. If the email isn't in their spam folder, then ask the customer to
verify their email once more. If the email is correct, contact customer service / Frank / AJ.</li>
</ol>
<h2 id="inactive-customers">Inactive Customers</h2>
<p>Inactive customers are treated as new customers in ROB.</p>

</div>
</mat-dialog-content>
