import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LoginProviderService } from 'src/app/shared/providers/login.provider.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temporary-login-root',
  templateUrl: './temporary-login-root.component.html',
  styleUrls: ['./temporary-login-root.component.scss']
})
export class TemporaryLoginRootComponent implements OnInit {
  @ViewChildren("input") inputs!: QueryList<ElementRef<HTMLInputElement>>;
  isLoading = false;

  formGroup = this.formBuilder.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private loginProvider: LoginProviderService,
    private router: Router,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void { }

  async nextClicked() {
    if (this.formGroup.valid) {
      var username = this.formGroup.controls.username.value
      var password = this.formGroup.controls.password.value
      assert(username, 'Username was null');
      assert(password, 'Password was null');
      this.isLoading = true;
      const didLoginSucceed = await this.loginProvider.login(username, password);
      if (didLoginSucceed) {
        this.router.navigateByUrl('/').then(() => window.location.reload());
      } else {
        this.isLoading = false;
        this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: 'Login failed. Check your username and password and try again.', cancelButtonText: 'OK' } });
      }
    } else {
      Object.keys(this.formGroup.controls).forEach(controlKey => {
        this.formGroup.get(controlKey)?.markAsTouched();
      });
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (Object.values(this.formGroup.value).reduce((flag, value) => flag && !!value, true)) {
        this.nextClicked();
      } else {
        const firstEmptyField = Array.from(this.inputs).find(field => !field.nativeElement.value);
        firstEmptyField?.nativeElement.focus();
      }
    }
  }
}
