import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { AsyncSelectOption } from 'src/app/shared/modules/async-select/interfaces/async-select-option';
import { getMetalString } from 'src/app/shared/utils/metal.util';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { assert } from 'src/app/shared/utils/assert.util';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemProviderService } from 'src/app/shared/providers/item.provider.service';
import { AvailableRingOptions } from 'src/app/shared/interfaces/AvailableRingOptions';
import { SelectedOptions } from './interfaces/selected-options';
import { Item } from 'src/app/shared/interfaces/item';
import { unique } from 'src/app/shared/utils/array.util';

@Component({
  selector: 'app-edit-proforma-item-properties[lineItem]',
  templateUrl: './edit-proforma-item-properties.component.html',
  styleUrls: ['./edit-proforma-item-properties.component.scss']
})
export class EditProformaItemPropertiesComponent implements OnInit {
  @Input() lineItem!: ProformaLineItem;
  @Output() lineItemChange = new EventEmitter<LineItem>();

  formGroup!: FormGroup<{ material: FormControl<AsyncSelectOption | null>; width: FormControl<AsyncSelectOption | null>; fingerSize: FormControl<AsyncSelectOption | null>; edge: FormControl<AsyncSelectOption | null>; quantity: FormControl<number | null>; po: FormControl<string | null>; }>
  isLoading = false;
  fingerSizeOptions: AsyncSelectOption[] = [];
  widthOptions: AsyncSelectOption[] = [];
  metalTypeOptions: AsyncSelectOption[] = [];
  edgeOptions: AsyncSelectOption[] = [];
  availableOptions: AvailableRingOptions[] = [];
  itemCache: { key: number, item: Promise<Item> }[] = [];

  _selectedFingerSizeOption: AsyncSelectOption | null = null;
  _selectedWidthOption: AsyncSelectOption | null = null;
  _selectedMetalTypeOption: AsyncSelectOption | null = null;
  _selectedEdgeOption: AsyncSelectOption | null = null;

  get selectedEdgeOption() {
    return this._selectedEdgeOption;
  }

  set selectedEdgeOption(value: AsyncSelectOption | null) {
    this._selectedEdgeOption = value;
    const relatedEdges = this.availableOptions.map(a => a.edge)
    const matchingEdge = relatedEdges.find(e => !!e && e.id === value?.index);
    this.lineItem.selectedEdge = matchingEdge ?? null;
  }

  get selectedFingerSizeOption() {
    return this._selectedFingerSizeOption;
  }

  set selectedFingerSizeOption(value: AsyncSelectOption | null) {
    this._selectedFingerSizeOption = value;
    this.lineItem.selectedFingerSize = value ? +value.index : null;
  }

  get selectedWidthOption() {
    return this._selectedWidthOption;
  }

  set selectedWidthOption(value: AsyncSelectOption | null) {
    this._selectedWidthOption = value;
    this.lineItem.selectedWidth = value?.value ?? null;
  }

  get selectedMetalTypeOption() {
    return this._selectedMetalTypeOption;
  }

  set selectedMetalTypeOption(value: AsyncSelectOption | null) {
    this._selectedMetalTypeOption = value;
    const relatedItemMetals = this.availableOptions.map(a => a.metals);
    const matchingMetal = relatedItemMetals.find(metals => getMetalString(metals) === value?.value);
    assert(matchingMetal, 'No matching metal for selection');
    this.lineItem.selectedMetals = matchingMetal;
  }

  set lineItemPo(value: string) {
    this.lineItem.poNumber = value;
  }

  get lineItemPo() {
    return this.lineItem?.poNumber ?? '';
  }

  set lineItemQuantity(value: number) {
    this.lineItem.quantity = value;
  }

  get lineItemQuantity() {
    return this.lineItem.quantity;
  }

  get formControls() {
    return this.formGroup.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private itemProvider: ItemProviderService
    ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.availableOptions = await this.itemProvider.getAvailableRingOptions(this.lineItem.item?.family?.key ?? this.lineItem.itemFamily.key);
    assert(this.lineItem.selectedMetals, 'property "selectedMetals" must be defined for every proforma line item.')

    this.selectedFingerSizeOption = this.lineItem.selectedFingerSize ? { index: this.lineItem.selectedFingerSize, value: this.lineItem.selectedFingerSize.toString() } : null;
    this.selectedWidthOption = this.lineItem.selectedWidth ? { index: this.lineItem.selectedWidth, value: this.lineItem.selectedWidth } : null;
    this.selectedMetalTypeOption = { index: getMetalString(this.lineItem.selectedMetals), value: getMetalString(this.lineItem.selectedMetals) };
    this.selectedEdgeOption = this.lineItem.selectedEdge ? { index: this.lineItem.selectedEdge.id, value: this.lineItem.selectedEdge.name } : null;

    const selectedOptions = this.buildSelectOptions();
    this.setSelectedOptions(selectedOptions);

    this.formGroup = this.formBuilder.group({
      material: [this.selectedMetalTypeOption, [Validators.required]],
      width: [this.selectedWidthOption, [Validators.required]],
      fingerSize: [this.selectedFingerSizeOption, [Validators.required]],
      edge: [this.selectedEdgeOption, [Validators.required]],
      quantity: [this.lineItemQuantity, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      po: [this.lineItemPo, []],
    });

    this.toggleDisabled(this.formControls.material, this.metalTypeOptions.length === 1);
    this.toggleDisabled(this.formControls.width, this.widthOptions.length === 1);
    this.toggleDisabled(this.formControls.fingerSize, this.fingerSizeOptions.length === 1);

    this.isLoading = false;

    this.formControls.material.valueChanges.subscribe((value) => {
      this.selectedMetalTypeOption = value;
      this.formControls.width.setValue(null);
      this.formControls.quantity.setValue(1);
      this.formControls.po.setValue('');
      this.selectItem();
      this.updateRingSelects();
      this.toggleSelectsDisable();
    });

    this.formControls.width.valueChanges.subscribe((value) => {
      this.selectedWidthOption = value;
      this.formControls.fingerSize.setValue(null);
      this.formControls.edge.setValue(null);
      this.selectItem();
      this.updateRingSelects();
      this.toggleSelectsDisable();
    });

    this.formControls.fingerSize.valueChanges.subscribe((value) => {
      this.selectedFingerSizeOption = value;
      this.selectItem();
      this.updateRingSelects();
      this.toggleSelectsDisable();
    });

    this.formControls.edge.valueChanges.subscribe((value) => {
      this.selectedEdgeOption = value;
      this.selectItem();
      this.updateRingSelects();
      this.toggleSelectsDisable();
    });

    this.formControls.quantity.valueChanges.subscribe((value) => {
      this.lineItemQuantity = value ?? 0;
    });

    this.formControls.po.valueChanges.subscribe((value) => {
      this.lineItemPo = value ?? '';
    });
  }

  private buildSelectOptions(): SelectedOptions {
    const fingerSizes = Array.from(new Set(this.availableOptions.filter(a => getMetalString(a.metals) === getMetalString(this.lineItem.selectedMetals ?? []) && a.width === this.lineItem.selectedWidth).map(a => a.fingerSize)));
    const widths = Array.from(new Set(this.availableOptions.filter(a => getMetalString(a.metals) === getMetalString(this.lineItem.selectedMetals ?? [])).map(a => a.width)));
    const metals = Array.from(new Set(this.availableOptions.map(a => getMetalString(a.metals))));
    const edges = unique(Array.from(new Set(this.availableOptions.filter(a => getMetalString(a.metals) === getMetalString(this.lineItem.selectedMetals ?? []) && a.width === this.lineItem.selectedWidth).map(a => a.edge))), (a, b) => a.id === b.id);

    const metalSwatchMap = metals.reduce((obj, metal) => {
      const matchingAvailableOption = this.availableOptions.find(option => getMetalString(option.metals) === metal)
      obj[metal] = matchingAvailableOption?.metals[0]?.swatch ? `//assets.benchmarkrings.com/benchmark_ring_fields/${matchingAvailableOption.metals[0].swatch}` : null
      return obj;
    }, {} as any);

    fingerSizes.sort((a,b) => a - b);
    widths.sort((a,b) => parseFloat(a) - parseFloat(b));
    metals.sort((a,b) => a.localeCompare(b));
    edges.sort((a,b) => b.name.localeCompare(a.name));

    const selectedOptions: SelectedOptions = {
      fingerSizeOptions: fingerSizes.map(fingerSize => ({ index: fingerSize, value: fingerSize.toString() })),
      widthOptions: widths.map(width => ({ index: width, value: width })),
      metalTypeOptions: metals.map(metalString => ({ index: metalString, value: metalString, rowImage: metalSwatchMap[metalString] })),
      edgeOptions: edges.filter(e => !!e).map(e => ({ index: e.id, value: e.name }))
    }
    return selectedOptions;
  }

  private toggleSelectsDisable() {
    this.toggleDisabled(this.formControls.material, this.metalTypeOptions.length === 1);
    this.toggleDisabled(this.formControls.width, this.widthOptions.length === 1);
    this.toggleDisabled(this.formControls.fingerSize, this.fingerSizeOptions.length === 1);
  }

   private toggleDisabled(formControl: FormControl, isDisabled: boolean) {
    if (isDisabled) {
      formControl.disable({emitEvent: false});
    } else {
      formControl.enable({ emitEvent: false });
    }
   }

  private setSelectedOptions(selectedOptions: SelectedOptions) {
    this.fingerSizeOptions = selectedOptions.fingerSizeOptions;
    this.widthOptions = selectedOptions.widthOptions;
    this.metalTypeOptions = selectedOptions.metalTypeOptions;
    this.edgeOptions = selectedOptions.edgeOptions;
  }

  updateRingSelects() {
    const selectedOptions = this.buildSelectOptions();
    this.setSelectedOptions(selectedOptions);
    this.selectSingleOptions();
  }

  private selectSingleOptions() {
    if (this.fingerSizeOptions.length === 1) {
      this.selectedFingerSizeOption = this.fingerSizeOptions[0];
      this.formControls.fingerSize.setValue(this.fingerSizeOptions[0], { emitEvent: false });
      this.selectItem({ emitChange: false }).then(() => this.isLoading = false);
    }

    if (this.widthOptions.length === 1) {
      this.selectedWidthOption = this.widthOptions[0];
      this.formControls.width.setValue(this.widthOptions[0], { emitEvent: false });
      this.isLoading = true;
      this.selectItem({ emitChange: false }).then(() => this.isLoading = false);
    }

    if (this.edgeOptions.length === 1) {
      this.selectedEdgeOption = this.edgeOptions[0];
      this.formControls.edge.setValue(this.edgeOptions[0], { emitEvent: false });
      this.isLoading = true;
      this.selectItem({ emitChange: false }).then(() => this.isLoading = false);
    }
  }

  async selectItem(options: { emitChange: boolean } = {emitChange: true}) {
    const selectedItem = await this.getItemWithSelectedOptions();
    this.lineItem.item = selectedItem;
    if (options.emitChange) {
      this.lineItemChange.emit(this.lineItem);
    }
  }

  async getItemWithSelectedOptions() {
    const matchingOption = this.availableOptions.find(a => getMetalString(a.metals ?? []) === this.selectedMetalTypeOption?.value && a.width === (this.selectedWidthOption?.value) && a.fingerSize === +(this.selectedFingerSizeOption?.value ?? NaN) && ( !a.edge || a.edge.id === this.selectedEdgeOption?.index))
    if (matchingOption) {
      try {
        const existingCachedItem = this.itemCache.find(cachedItem => cachedItem.key === matchingOption.id);
        if (existingCachedItem) {
          return existingCachedItem.item;
        }
        const selectedItemPromise = this.itemProvider.getSingle(matchingOption.id);
        this.itemCache.push({ key: matchingOption.id, item: selectedItemPromise });
        const selectedItem = await selectedItemPromise;
        this.itemCache.splice(0, Math.max(this.itemCache.length - 5, 0));
        return selectedItem;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  }

  upButtonClicked() {
    this.formControls.quantity.setValue(this.lineItemQuantity + 1);
    this.lineItemChange.emit(this.lineItem);
  }

  downButtonClicked() {
    this.formControls.quantity.setValue(this.lineItemQuantity - 1);
    this.lineItemChange.emit(this.lineItem);
  }
}
