<form class="example-form">
  <mat-form-field #formField class="example-full-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input #input [value]="selectedCustomer?.name" type="text"
           placeholder="Pick one"
           aria-label="label"
           matInput
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="customerSelected($event)">
      <mat-option *ngFor="let customer of filteredCustomers.value" [value]="customer">
        <div *ngIf="customer.number" class="Flexbox" [ngClass]="!customer.isActive ? 'InactiveCustomerOption' : ''">
          <span *ngIf="!customer.isActive" class="InactiveCallout Callout mat-critical">INACTIVE</span>
          <span class="CustomerName">{{ customer.number }} {{ customer.name }}</span>
        </div>
        <ng-container *ngIf="!customer.number">
          <div class="Flexbox Flexbox--align-center NewCustomerOption">
            <span class="NewCallout Callout mat-success"><b>NEW</b></span>
            <span class="CustomerName">{{ customer.name }}</span>
            <span *ngIf="customer.jbtNumber" class="NewIdentifier">
              <span class="NewIdentifierLabel">JBT Number </span>
              <span>{{ customer.jbtNumber }}</span>
            </span>
            <span *ngIf="!customer.jbtNumber && getCustomerBuyer(customer)" class="NewIdentifier">
              <span class="NewIdentifierLabel">Buyer Email </span>
              <span>{{ getCustomerBuyer(customer)?.email }}</span>
            </span>
          </div>
        </ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
