import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LineItem } from 'src/app/shared/interfaces/line-item';

@Injectable({
  providedIn: 'root'
})
export class LineItemsService<T extends LineItem> {
  private lineItems = new BehaviorSubject<T[]>([]);

  constructor() { }

  get(key: string) {
    const lineItem = this.lineItems.value.find(item => item.key === key);
    return lineItem;
  }

  getAll() {
    const lineItems = this.lineItems.value;
    return lineItems;
  }

  remove(key: string): void {
    const newLineItems = this.lineItems.value.filter(lineItem => lineItem.key !== key);
    this.lineItems.next(newLineItems);
  }

  add(lineItem: T): void {
    const newLineItems = [lineItem].concat(this.lineItems.value);
    this.lineItems.next(newLineItems);
  }

  set(lineItems: T[]): void {
    this.lineItems.next(lineItems);
  }

  refresh() {
    this.lineItems.next(this.lineItems.value);
  }

  subscribe(next?: (value: T[]) => void) {
    return this.lineItems.subscribe({ next: next });
  }
}
