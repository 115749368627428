import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from 'src/app/shared/interfaces/customer';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { assert } from 'src/app/shared/utils/assert.util';
import { getLineItemPricingObject } from 'src/app/shared/utils/pricing.util';
import { CustomerSummaryBarService } from '../../../customer-summary-bar/services/customer-summary-bar.service';

@Component({
  selector: 'app-edit-proforma-display-item-properties[lineItem]',
  templateUrl: './edit-proforma-display-item-properties.component.html',
  styleUrls: ['./edit-proforma-display-item-properties.component.scss']
})
export class EditProformaDisplayItemPropertiesComponent implements OnInit {
  @Input() lineItem!: ProformaLineItem;
  @Output() lineItemChange = new EventEmitter<LineItem>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  upButtonClicked() {
    this.quantityChanged(this.lineItem.quantity + 1);
    this.lineItemChange.emit(this.lineItem);
  }

  downButtonClicked() {
    this.quantityChanged(this.lineItem.quantity - 1);
    this.lineItemChange.emit(this.lineItem);
  }

  quantityChanged(quantity: number) {
    this.lineItem.quantity = quantity;
  }

  quantityEditEnded() {
    if (this.lineItem.quantity === null) {
      this.lineItem.quantity = 0;
    }
    this.lineItemChange.emit(this.lineItem);
  }

  poChanged(po: string | null) {
    this.lineItem.poNumber = po;
    this.lineItemChange.emit(this.lineItem);
  }
}
