<div class="PhoneInputContainer" role="group" [formGroup]="parts" [attr.aria-labelledby]="_formField.getLabelId()" (focusin)="onFocusIn($event)"
(focusout)="onFocusOut($event)">
  <input
        #countryCode
        type="text"
        placeholder="Country Code"
        matInput
        formControlName="countryCode"
        [matAutocomplete]="autoComplete"
        class="CountryCodeInput"
        (keydown)="countryCodeInputKeyDown($event)">
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="countryCodeSelected($event)" (closed)="onCountryCodePanelClosed()" panelWidth="200">
    <mat-option *ngFor="let countryCode of filteredCountryCodes.value" [value]="'+' + countryCode.value">{{ countryCode.displayValue }}</mat-option>
  </mat-autocomplete>
  <span>&ndash;</span>
  <input matInput #phone class="Phone" formControlName="phone" placeholder="Phone">
</div>