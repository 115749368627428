<app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
<ng-container *ngIf="user && !isLoading">
  <h2>{{ user.fullName }}</h2>
  <div class="Flexbox Flexbox--column MainContent">
    <div class="Flexbox Flexbox--column Values">
      <div class="Flexbox ValueRow">
        <span>Email</span>
        <span><b>{{ user.email }}</b></span>
      </div>
      <div class="Flexbox ValueRow">
        <span>Role</span>
        <span><b>{{ user.role.toUpperCase() }}</b></span>
      </div>
      <div class="Flexbox ValueRow">
        <span>Reps</span>
        <ul>
          <li *ngFor="let rep of user.salesReps" class="RepListItem"><b>{{rep.number}}-{{ rep.name }}</b></li>
          <li *ngIf="user.salesReps.length === 0"><b>None</b></li>
        </ul>
      </div>
      <div class="Flexbox ValueRow">
        <span>Last Active</span>
        <span>
          <b>
            <ng-container *ngIf="lastActive">{{ lastActive | shortDate: { suffixZuluTime: true, withTime: true } }}</ng-container>
            <ng-container *ngIf="!lastActive">Never</ng-container>
          </b>
        </span>
      </div>
    </div>
    <div class="Flexbox ButtonContainer">
      <button mat-raised-button color="accent" (click)="editButtonClicked()">Edit</button>
      <button mat-raised-button *ngIf="!user.isDeleted" color="warn" (click)="inactivateButtonClicked()">Inactivate</button>
      <button mat-raised-button *ngIf="user.isDeleted" (click)="activateButtonClicked()">Activate</button>
      <button mat-raised-button color="warn" (click)="changePasswordButtonClicked()">Change Password</button>
    </div>
  </div>
</ng-container>
