import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { NavigationItem } from '../../interfaces/navigation-item';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {
  navigationItems: NavigationItem[] = [];

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.navigationItems = [
      {
        title: 'Order History',
        icon: 'history',
        url: '/history'
      }
    ];

    if (!this.userService.isAR()) {
      this.navigationItems.unshift({
        title: 'Enter Order',
        icon: 'add_shopping_cart',
        url: '/order'
      });
    }


    if (!this.userService.isAR()) {
      this.navigationItems.push({
        title: 'New Customer',
        icon: 'person_add',
        url: '/newcustomer'
      });
    }

    if (this.userService.isAdmin()) {
      this.navigationItems.push({
        title: 'Users',
        icon: 'group',
        url: '/users'
      });
    }
  }
}
