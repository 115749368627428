import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderEntryRootComponent } from './components/order-entry-root/order-entry-root.component';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { StockBalanceComponent } from './components/stock-balance/stock-balance.component';
import { AutoCompleteModule } from 'src/app/shared/modules/auto-complete/auto-complete.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProformaComponent } from './components/proforma/proforma.component';
import { RouterModule } from '@angular/router';
import { EditItemModule } from 'src/app/shared/modules/edit-item/edit-item.module';
import { CollectionSummaryModule } from 'src/app/shared/modules/collection-summary/collection-summary.module';

@NgModule({
  declarations: [
    OrderEntryRootComponent,
    StockBalanceComponent,
    ProformaComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    AutoCompleteModule,
    FormsModule,
    SharedModule,
    RouterModule,
    EditItemModule,
    CollectionSummaryModule
  ]
})
export class OrderEntryModule { }
