import { Injectable } from '@angular/core';
import { Customer } from 'src/app/shared/interfaces/customer';
import { SubscribableService } from 'src/app/shared/services/subscribable.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSummaryBarService extends SubscribableService<Customer> {

  constructor() {
    super();
  }
}
