import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSelectRootComponent } from './components/customer-select-root/customer-select-root.component';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'src/app/shared/modules/auto-complete/auto-complete.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CustomerSelectRootComponent,
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    SharedModule
  ]
})

export class CustomerSelectModule { }
