import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressSelectDialogComponent } from '../address-select-dialog/address-select-dialog.component';
import { ConfirmDialogOptions } from './interfaces/confirm-dialog-options';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogOptions>,
    @Inject(MAT_DIALOG_DATA) public options: ConfirmDialogOptions
  ) { }

  ngOnInit(): void {
  }

}
