<form class="example-form">
  <mat-form-field #formField class="example-full-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input #input [value]="selectedOption?.value" (ngModelChange)="inputTextChanged($event)" (keydown)="inputKeyDown($event)" type="text"
           placeholder="Pick one"
           aria-label="label"
           matInput
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-option disabled *ngIf="isLoading">
        <div class="LoadingOption Flexbox Flexbox--row">
          <span>Loading...</span>
          <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let option of filteredOptions.value" [value]="option">
          {{option.value}}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>