import { Collection } from '../interfaces/collection';
import { LineItem } from '../interfaces/line-item';
import { ProformaLineItem } from '../interfaces/proforma-line-item';
import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';
import { SummaryCollection } from '../interfaces/summary-collection';
import { getTotal, getTotalQuantity } from './order.util';
import { formatMoney } from './text-formatters.util';

export function buildCollections<T extends LineItem>(lineItems: T[]) {
  const newCollections = lineItems.reduce((collectionArray, lineItem) => {
    const collection = collectionArray.find(collection => collection.name === lineItem.itemCollection);
    if(!collection) {
      collectionArray.push({
        name: lineItem.itemCollection,
        items: [lineItem]
      });
      return collectionArray
    }
    collection.items.push(lineItem);
    return collectionArray;
  }, [] as Collection<T>[]);
  return newCollections;
}

export function buildSummaryCollections(proformaLineItems: ProformaLineItem[], stockBalanceLineItems: StockBalanceLineItem[]) {
  const proformaCollections = getUniqueCollections(proformaLineItems);
  const stockBalanceCollections = getUniqueCollections(stockBalanceLineItems);
  const allUniqueCollections = Array.from(new Set(proformaCollections.concat(stockBalanceCollections)));
  const summaryCollections: SummaryCollection[] = allUniqueCollections.map(collectionName => {
    return ({
    name: collectionName,
    quantityMinimum: getQuantityMinimum(collectionName, proformaLineItems, stockBalanceLineItems),
    stockBalanceRatio: getStockBalanceRatio(collectionName, proformaLineItems, stockBalanceLineItems),
    proformaItems: proformaLineItems.filter(lineItem => lineItem.itemCollection === collectionName),
    stockBalanceItems: stockBalanceLineItems.filter(lineItem => lineItem.itemCollection === collectionName),
  })
});
  return summaryCollections;
}

function getQuantityMinimum(collectionName: string, proformaLineItems: ProformaLineItem[], stockBalanceLineItems: StockBalanceLineItem[]) {
  const lineItem = findFirstCollectionItem(collectionName, proformaLineItems, stockBalanceLineItems);
  return lineItem?.item?.class?.quantityMinimum ?? 0
}

function getStockBalanceRatio(collectionName: string, proformaLineItems: ProformaLineItem[], stockBalanceLineItems: StockBalanceLineItem[]) {
  const lineItem = findFirstCollectionItem(collectionName, proformaLineItems, stockBalanceLineItems);
  return lineItem?.item?.class?.stockBalanceRatio ?? 0
}

function findFirstCollectionItem(collectionName: string, proformaLineItems: ProformaLineItem[], stockBalanceLineItems: StockBalanceLineItem[]) {
  const firstProformaItem =  proformaLineItems.find(lineItem => lineItem.itemCollection === collectionName)
  const firstStockBalanceItem = stockBalanceLineItems.find(lineItem => lineItem.itemCollection === collectionName);
  return firstProformaItem ?? firstStockBalanceItem ?? null
}

function getUniqueCollections(lineItems: LineItem[]) {
  const uniqueCollections = Array.from(new Set(lineItems.map(lineItem => lineItem.itemCollection)));
  return uniqueCollections;
}

export function getCollectionRestrictionReason(collection: SummaryCollection, restrictedCollections: string[], longDescription = false) {
  const proformaReason = getProformaCollectionRestrictionReason(collection, restrictedCollections, longDescription ? 'needed to meet order requirements' : 'needed');

  if (proformaReason) {
    return proformaReason;
  }

  return getStockBalanceCollectionRestrictionReason(collection, longDescription ? 'needed to meet stock balance requirements' : 'needed');
}

export function getStockBalanceCollectionRestrictionReason(collection: SummaryCollection, description = 'needed') {
  const remainingTotal = collection.stockBalanceRatio * getTotal(collection.stockBalanceItems) - getTotal(collection.proformaItems);
  if (remainingTotal > 0) {
    return `${formatMoney(remainingTotal)} ${description}`;
  }

  return null;
}

export function getProformaCollectionRestrictionReason(collection: SummaryCollection, restrictedCollections: string[], description = 'needed') {
  if (restrictedCollections.includes(collection.name)) {
    const proformaQuantity = getTotalQuantity(collection.proformaItems);
    const remainingProducts = collection.quantityMinimum - proformaQuantity;

    if(remainingProducts > 0 && proformaQuantity > 0) {
      return `${remainingProducts} product(s) ${description}`;
    }
    return null;
  }
  return null;
}
