<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <mat-select [errorStateMatcher]="buildErrorStateMatcher()" [required]="required" #selectElement [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="valueChange.emit($event)" (openedChange)="openChange.emit($event)" [compareWith]="optionComparator">
    <ng-container *ngIf="options.length === 0 then loadingOptions;else loadedOptions"></ng-container>
    <ng-template #loadingOptions>
      <mat-option [value]="value">{{value?.value}}</mat-option>
      <mat-option disabled>
        <div class="LoadingOption Flexbox Flexbox--row">
          <span>Loading...</span>
          <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
      </mat-option>
    </ng-template>
    <ng-template #loadedOptions>
      <mat-option *ngFor="let option of options" [value]="option">
        <img class="OptionImage" *ngIf="type === 'image' && option.rowImage" [src]="option.rowImage" [alt]="option.value">
        <div class="OptionImage" *ngIf="type === 'image' && !option.rowImage"></div>
        <span>{{option.value}}</span>
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
