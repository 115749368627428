import { Component, Inject, OnInit } from '@angular/core';
import { Customer } from '../../interfaces/customer';
import { Address } from '../../interfaces/address';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerSelectDialogResult } from '../../interfaces/customer-select-dialog-result';

@Component({
  selector: 'app-customer-select-dialog',
  templateUrl: './customer-select-dialog.component.html',
  styleUrls: ['./customer-select-dialog.component.scss']
})
export class CustomerSelectDialogComponent implements OnInit {
  isLoading = false;
  selectedCustomer: Customer | null = null;

  constructor(
    public dialogRef: MatDialogRef<CustomerSelectDialogComponent, CustomerSelectDialogResult | undefined>,
    @Inject(MAT_DIALOG_DATA) public customers: Customer[]
  ) { }

    ngOnInit(): void {
    }

  changed(customer: Customer | null) {
    this.selectedCustomer = customer;
    if (this.selectedCustomer && this.selectedCustomer.addresses.length === 1) {
      const result = {
        customer: this.selectedCustomer,
        address: this.selectedCustomer.addresses[0]
      };
      this.dialogRef.close(result);
    }
  }

  addressSelected(address: Address) {
    if (this.selectedCustomer) {
      const result = {
        customer: this.selectedCustomer,
        address: address
      };
      this.dialogRef.close(result);
    }
  }
}
