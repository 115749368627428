import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebProviderService {
  constructor(private webClient: HttpClient) { }

  async get<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    return this.makeRequest('GET', url, null, options)
  }

  async post<T>(url: string, body?: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    return this.makeRequest('POST', url, body, options);
  }

  async put<T>(url: string, body?: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    return this.makeRequest('PUT', url, body, options);
  }

  async patch<T>(url: string, body?: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    return this.makeRequest('PATCH', url, body, options);
  }

  async delete<T>(url: string, body?: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    return this.makeRequest('DELETE', url, body, options);
  }

  public async makeRequest<T>(method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', url: string, body?: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
      params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Promise<T | null> {
    const result = this.webClient.request<T>(method, url, { observe: 'response', body: body, headers: options?.headers, params: options?.params, withCredentials: true });
    const response = await lastValueFrom(result);
    if (response.ok) {
      return response.body;
    } else {
      throw `Request ${method} ${url} returned ${response.statusText}`;
    }
  }
}
