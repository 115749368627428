import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';
import { StockBalanceLineItemAddRequest } from '../interfaces/stock-balance-line-item-add-request';

export function buildStockBalanceLineItemRequest(lineItem: StockBalanceLineItem): StockBalanceLineItemAddRequest {
  const lineItemAddRequest: StockBalanceLineItemAddRequest = {
    key: lineItem.key,
    itemID: lineItem.item?.id ?? null,
    quantity: lineItem.quantity,
    parentLineItemKey: lineItem.parentLineItem?.key ?? null,
    poNumber: lineItem.poNumber,
    itemType: lineItem.item?.type ?? null,
    freeFormSKU: lineItem.freeformSKU,
    isEligible: lineItem.isEligible,
    isApproved: lineItem.isApproved ?? null,
    discount: lineItem.discountRate ?? null,
    returnReason: lineItem.returnReason ?? null
  }
  return lineItemAddRequest;
}