import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { getThumbnailImageURL } from 'src/app/shared/utils/item.util';
import { getMetalString } from 'src/app/shared/utils/metal.util';

@Component({
  selector: 'app-view-display-item',
  templateUrl: './view-display-item.component.html',
  styleUrls: ['./view-display-item.component.scss']
})
export class ViewDisplayItemComponent implements OnInit {
  @Input() lineItem!: LineItem;
  @Input() lineNumber: number | null = null;

  // get lineItemKarat(): string {
  //   return getMetalString(this.lineItem.item.metals);
  // }


  constructor() { }

  ngOnInit(): void {
  }

}
