import { Injectable } from '@angular/core';
import { SubmitOrder } from '../interfaces/submit-order';
import { SubscribableService } from './subscribable.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitOrderService extends SubscribableService<SubmitOrder> {

  constructor() {
    super();
   }
}
