import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { Customer } from 'src/app/shared/interfaces/customer';
import { UserService } from 'src/app/shared/services/user.service';
import { SROEValidators } from 'src/app/shared/validators/sales-rep-order-entry-validators';
import { OrderInfo } from '../../interfaces/order-info';
import { formatPhoneUS } from 'src/app/shared/utils/text-formatters.util';
import { getISO2 } from 'src/app/shared/utils/address.util';
import { getCountryCodeForRegionCode } from 'awesome-phonenumber';

@Component({
  selector: 'app-order-info[customer]',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit {
  @Input() customer!: Customer;
  @Input() orderInfo: OrderInfo | null = null
  @Output() orderInfoChange = new EventEmitter<OrderInfo>();
  @Output() isValidChange = new EventEmitter<boolean>();

  private isFormValid = false;

  formGroup!: FormGroup<{
    buyerName: FormControl<string | null>;
    buyerEmail: FormControl<string | null>;
    buyerConfirmEmail: FormControl<string | null>;
    buyerPhone: FormControl<string | null>;
    arName: FormControl<string | null>;
    arEmail: FormControl<string | null>;
    arConfirmEmail: FormControl<string | null>;
    arPhone: FormControl<string | null>;
    terms: FormControl<string | null>;
    po: FormControl<string | null>
  }>;

  get availableTerms() {
    return this.customer.availableTerms?.map(term => term === this.customer.defaultTerms && this.customer.number && this.customer.isActive ? `${term} (DEFAULT)` : term) ?? [];
  }

  get formControls() {
    return this.formGroup.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      buyerName: [this.orderInfo?.buyerName ?? null, [Validators.required]],
      buyerEmail: [this.orderInfo?.buyerEmail ?? null, [Validators.required, Validators.email]],
      buyerConfirmEmail: [this.orderInfo?.buyerConfirmEmail ?? null, [Validators.required, Validators.email, SROEValidators.matchesField('buyerEmail', { errorMessage: 'The value entered does not match the Buyer Email' })]],
      buyerPhone: [this.orderInfo?.buyerPhone ?? null, [Validators.required]],
      arName: [this.orderInfo?.arName ?? null, [Validators.required]],
      arEmail: [this.orderInfo?.arEmail ?? null, [Validators.required, Validators.email]],
      arConfirmEmail: [this.orderInfo?.arConfirmEmail ?? null, [Validators.required, Validators.email, SROEValidators.matchesField('arEmail', { errorMessage: 'The value entered does not match the AR Email' })]],
      arPhone: [this.orderInfo?.arPhone ?? null, [Validators.required]],
      terms: [this.orderInfo?.terms ?? this.customer.defaultTerms ?? null, [Validators.required]],
      po: [this.orderInfo?.po ?? null]
    });

    this.formGroup.valueChanges.subscribe(() => {
      const fields = this.formGroup.getRawValue();
      this.orderInfoChange.emit({
        buyerName: fields.buyerName ?? null,
        buyerEmail: fields.buyerEmail ?? null,
        buyerConfirmEmail: fields.buyerConfirmEmail ?? null,
        buyerPhone: fields.buyerPhone ?? null,
        arName: fields.arName ?? null,
        arEmail: fields.arEmail ?? null,
        arConfirmEmail: fields.arConfirmEmail ?? null,
        arPhone: fields.arPhone ?? null,
        terms: fields.terms?.replace(' (DEFAULT)', '') ?? null,
        po: fields.po ?? null
      });

      if(this.isFormValid !== this.formGroup.valid) {
        this.isFormValid = this.formGroup.valid;
        this.isValidChange.emit(this.isFormValid);
      }
    });

    this.formControls.arPhone.valueChanges.subscribe(this.formatPhone(this.formControls.arPhone));

    if (['Charge Before Ship', 'Prepay'].includes(this.customer.defaultTerms) && (!this.userService.isAR() && !this.userService.isAdmin())) {
      this.formGroup.controls.terms.disable({emitEvent: true});
    }

    if (this.customer.isActive) {
      this.formGroup.controls.arEmail.disable({ emitEvent: false })
      this.formGroup.controls.arConfirmEmail.disable({ emitEvent: false })
      this.formGroup.controls.arName.disable({ emitEvent: false })
      this.formGroup.controls.arPhone.disable({ emitEvent: false })
    }

    if (!this.orderInfo || !this.isAnyBuyerContactFieldComplete()) {
      const buyer = this.customer.contacts.find(contact => contact.type === 'Buyer');

      if (buyer) {
        const sanitizedBuyerPhone = buyer.phone?.replace(/^011|\s+/, '').trim();
        const buyerCountryCode = getISO2(buyer.country ?? '');
        if (buyerCountryCode && sanitizedBuyerPhone?.startsWith(getCountryCodeForRegionCode(buyerCountryCode).toString())) {
          this.formControls.buyerPhone.setValue(sanitizedBuyerPhone);
        } else if (buyerCountryCode) {
          this.formControls.buyerPhone.setValue(`${getCountryCodeForRegionCode(buyerCountryCode)}${sanitizedBuyerPhone}`);
        } else {
          this.formControls.buyerPhone.setValue(`1${sanitizedBuyerPhone}`);
        }

        this.formControls.buyerName.setValue(buyer.name);
        this.formControls.buyerEmail.setValue(buyer.email);
        this.formControls.buyerConfirmEmail.setValue(buyer.email);
      }
    }

    if (!this.orderInfo || !this.isAnyARContactFieldComplete()) {
      const ar = this.customer.contacts.find(contact => contact.type === 'Accounts Receivable');

      if (ar) {
        this.formControls.arName.setValue(ar.name);
        this.formControls.arPhone.setValue(ar.phone);
        this.formControls.arEmail.setValue(ar.email);
        this.formControls.arConfirmEmail.setValue(ar.email);
      }
    }

    this.isFormValid = this.formGroup.valid;
    this.isValidChange.emit(this.isFormValid);
  }

  private formatPhone(formControl: FormControl<string | null>) {
    return (value: string | null) => {
      const formattedPhone = formatPhoneUS(value);
      if (typeof formattedPhone !== 'undefined' && formattedPhone !== null) {
        formControl.setValue(formattedPhone, { emitEvent: false });
      }
    };
  }

  private isAnyBuyerContactFieldComplete() {
    if (!this.orderInfo) {
      return false;
    }

    const {buyerName, buyerPhone, buyerEmail, buyerConfirmEmail} = this.orderInfo;
    return [buyerName, buyerPhone, buyerEmail, buyerConfirmEmail].some(value => !!value);
  }

  private isAnyARContactFieldComplete() {
    if (!this.orderInfo) {
      return false;
    }

    const {buyerName, buyerPhone, buyerEmail, buyerConfirmEmail} = this.orderInfo;
    return [buyerName, buyerPhone, buyerEmail, buyerConfirmEmail].some(value => !!value);
  }

  getError(formControl: AbstractControl) {
    if (formControl.hasError('required')) {
      return 'Please enter a value for this field'
    }

    if (formControl.hasError('email')) {
      return 'The email entered is not valid'
    }

    if (formControl.hasError('pattern')) {
      return 'The value entered is not valid';
    }

    const otherErrors = Object.keys(formControl.errors ?? {})

    if (otherErrors.length > 0) {
      const error = formControl.errors?.[otherErrors[0]];
      return error;
    }

    return '';
  }

  optionComparator(option: string | null, value: string | null) {
    return option === value;
  }

  termsOptionComparator(option: string | null, value: string | null) {
    return option?.replace(' (DEFAULT)', '') === value?.replace(' (DEFAULT)', '');
  }

  showFormErrors() {
    Object.keys(this.formGroup.controls).forEach(controlKey => {
      this.formGroup.get(controlKey)?.markAsTouched();
    });
  }
}
