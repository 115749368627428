import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionSummaryComponent } from './components/collection-summary/collection-summary.component';
import { CollectionSummaryCardComponent } from './components/collection-summary-card/collection-summary-card.component';
import { SharedModule } from '../../shared.module';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';



@NgModule({
  declarations: [
    CollectionSummaryComponent,
    CollectionSummaryCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule
  ],
  exports: [
    CollectionSummaryComponent
  ]
})
export class CollectionSummaryModule { }
