import { Component, Input, OnInit } from '@angular/core';
import { ProformaLineItem } from '../../interfaces/proforma-line-item';
import { StockBalanceLineItem } from '../../interfaces/stock-balance-line-item';
import { Term } from '../../interfaces/term';
import { getEligibleLineItems, getTotal, getTotalDiscount, getTotalQuantity } from '../../utils/order.util';

@Component({
  selector: 'app-order-review-summary[stockBalanceLineItems][proformaLineItems]',
  templateUrl: './order-review-summary.component.html',
  styleUrls: ['./order-review-summary.component.scss']
})
export class OrderReviewSummaryComponent implements OnInit {
  @Input() stockBalanceLineItems!: StockBalanceLineItem[];
  @Input() proformaLineItems!: ProformaLineItem[];
  @Input() shippingCost: number | null = null;
  @Input() orderTerms: string | null = null;

  get orderQuantity(): number {
    return getTotalQuantity(this.proformaLineItems);
  }

  get approvedStockBalanceLineItems(): StockBalanceLineItem[] {
    return this.stockBalanceLineItems.filter(lineItem => lineItem.isApproved !== false);
  }

  get orderTotal(): number {
    return getTotal(this.proformaLineItems);
  }

  get stockBalanceTotal(): number {
    return getTotal(this.approvedStockBalanceLineItems);
  }

  get stockBalanceQuantity(): number {
    return getTotalQuantity(this.approvedStockBalanceLineItems);
  }

  get discountTotal(): number {
    return getTotalDiscount(this.proformaLineItems);
  }

  get prediscountTotal(): number {
    return getTotal(this.proformaLineItems) - getTotalDiscount(this.proformaLineItems);
  }

  get afterDiscountTotal(): number {
    return getTotal(this.proformaLineItems);
  }

  get netOrderTotal(): number {
    return this.orderTotal - this.stockBalanceTotal + (this.shippingCost ?? 0);
  }

  get hasStockBalanceLines(): boolean {
    return this.stockBalanceLineItems.length > 0;
  }

  get stockBalanceColor() {
    if (this.stockBalanceLineItems.some(lineItem => !lineItem.isApproved && !lineItem.isEligible)) {
      return 'Red';
    }
    return 'Green';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
