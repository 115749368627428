<ng-container *ngIf="address then Address; else Empty"></ng-container>

<ng-template #Empty>
  <div>None</div>
</ng-template>

<ng-template #Address>
  <div *ngIf="address.company">{{ address.company }}</div>
  <div *ngIf="address.addressee">{{ address.addressee }}</div>
  <div *ngIf="!address.addressee && address.attention">{{ address.attention }}</div>
  <div *ngIf="address.street1">{{ address.street1 }}</div>
  <div *ngIf="address.street2" >{{ address.street2 }}</div>
  <div class="Flexbox CityStateZip"><span *ngIf="address.city">{{ address.city }},</span> <span *ngIf="address.stateCode">{{ address.stateCode }}</span> <span *ngIf="address.postalCode">{{ address.postalCode }}</span></div>
  <div *ngIf="address.country">{{ address.country }}</div>
</ng-template>
