import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from '../../interfaces/address';

@Component({
  selector: 'app-address-select-dialog',
  templateUrl: './address-select-dialog.component.html',
  styleUrls: ['./address-select-dialog.component.scss']
})
export class AddressSelectDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddressSelectDialogComponent, Address>,
    @Inject(MAT_DIALOG_DATA) public addresses: Address[]
  ) {}

  ngOnInit(): void {
  }

  closeDialog(address: Address) {
    this.dialogRef.close(address);
  }
}
