<div [formGroup]="formGroup" class="Flexbox Flexbox--column OrderInfo">
  <div class="Flexbox OrderInfoRow">
    <div class="Flexbox Flexbox--column OrderInfoColumn">
      <mat-form-field appearance="outline">
        <mat-label>Order Terms</mat-label>
        <mat-select formControlName="terms" [compareWith]="termsOptionComparator" required>
          <mat-option *ngFor="let term of availableTerms" [value]="term">{{ term }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formControls.terms.invalid">{{ getError(formControls.terms) }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Order PO #</mat-label>
        <input matInput formControlName="po" type="text">
      </mat-form-field>
    </div>
    <div class="Flexbox Flexbox--column OrderInfoColumn">
      <mat-form-field required appearance="outline">
        <mat-label>Buyer Name</mat-label>
        <input matInput formControlName="buyerName" type="text">
        <mat-error *ngIf="formControls.buyerName.invalid">{{getError(formControls.buyerName)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Buyer Phone</mat-label>
        <app-phone-input formControlName="buyerPhone" defaultCountryCode="1"></app-phone-input>
        <mat-error *ngIf="formControls.buyerPhone.invalid">{{ getError(formControls.buyerPhone) }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field required appearance="outline">
        <mat-label>Buyer Phone</mat-label>
        <input matInput formControlName="buyerPhone" type="tel">
        <mat-error *ngIf="formControls.buyerPhone.invalid">{{getError(formControls.buyerPhone)}}</mat-error>
      </mat-form-field> -->
    </div>
    <div class="Flexbox Flexbox--column OrderInfoColumn">
      <mat-form-field required appearance="outline">
        <mat-label>Buyer Email</mat-label>
        <input matInput formControlName="buyerEmail" type="email">
        <mat-error *ngIf="formControls.buyerEmail.invalid">{{getError(formControls.buyerEmail)}}</mat-error>
      </mat-form-field>
      <mat-form-field required #confirmEmailField appearance="outline">
        <mat-label>Confirm Buyer Email</mat-label>
        <input matInput formControlName="buyerConfirmEmail" type="email" appPreventPaste>
        <mat-error *ngIf="formControls.buyerConfirmEmail.invalid">{{getError(formControls.buyerConfirmEmail)}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="Flexbox OrderInfoRow" *ngIf="!customer.isActive">
    <div class="Flexbox Flexbox--column OrderInfoColumn">
      <mat-form-field required appearance="outline">
        <mat-label>AR Name</mat-label>
        <input matInput formControlName="arName" type="text">
        <mat-error *ngIf="formControls.arName.invalid">{{getError(formControls.arName)}}</mat-error>
      </mat-form-field>
      <mat-form-field required appearance="outline">
        <mat-label>AR Phone</mat-label>
        <input matInput formControlName="arPhone" type="tel">
        <mat-error *ngIf="formControls.arPhone.invalid">{{getError(formControls.arPhone)}}</mat-error>
      </mat-form-field>
    </div>
    <div class="Flexbox Flexbox--column OrderInfoColumn">
      <mat-form-field required appearance="outline">
        <mat-label>AR Email</mat-label>
        <input matInput formControlName="arEmail" type="email">
        <mat-error *ngIf="formControls.arEmail.invalid">{{getError(formControls.arEmail)}}</mat-error>
      </mat-form-field>
      <mat-form-field required #confirmEmailField appearance="outline">
        <mat-label>Confirm AR Email</mat-label>
        <input matInput formControlName="arConfirmEmail" type="email" appPreventPaste>
        <mat-error *ngIf="formControls.arConfirmEmail.invalid">{{getError(formControls.arConfirmEmail)}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>