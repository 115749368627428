import { Injectable } from '@angular/core';
import { Customer } from '../interfaces/customer';
import { Item } from '../interfaces/item';
import { LineItem } from '../interfaces/line-item';
import { UserService } from '../services/user.service';
import { getDiscountRate } from '../utils/pricing.util';


@Injectable({
  providedIn: 'root'
})

export class LineItemFactory<T extends LineItem> {
  constructor(
    private userService: UserService
  ) {
  }

  create(item: Item, customer: Customer | null = null): T {
    const lineItem: LineItem = {
      id: 0,
      key: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}`,
      parentLineItem: null,
      item: item,
      poNumber: null,
      quantity: 1,
      unitRate: item.wholesalePrice,
      discountRate: getDiscountRate(item, customer),
      extendedDiscount: 0,
      extendedTotal: item.wholesalePrice,
      isEligible: true,
      restrictionReason: null,
      selectedWidth: item.width,
      selectedFingerSize: item.fingerSize,
      selectedEdge: item.edge,
      itemFamily: item.family,
      selectedMetals: item.metals,
      itemCollection: item.collection,
      itemType: item.type,
      priceClassPercentage: null,
      buyingGroupPercentage: null
    };
    return lineItem as T;
  }
}
