import { Component, OnInit, ViewChild } from '@angular/core';
import { assert } from 'src/app/shared/utils/assert.util';
import { TermsProviderService } from 'src/app/shared/providers/terms.provider.service';
import { AddressProviderService } from 'src/app/shared/providers/address.provider.service';
import { CustomerProviderService } from 'src/app/shared/providers/customer.provider.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { newCustomerFromFormValues } from 'src/app/shared/utils/new-customer.util';
import { UserService } from 'src/app/shared/services/user.service';
import { SalesRep } from 'src/app/shared/interfaces/sales-rep';
import { SalesRepProvidersService } from 'src/app/shared/providers/sales-rep.provider.service';
import { Router } from '@angular/router';
import { NewCustomerFormValues } from 'src/app/shared/interfaces/new-customer-form-values';
import { NewCustomerFormComponent } from 'src/app/shared/components/new-customer-form/new-customer-form.component';
import { Term } from 'src/app/shared/interfaces/term';

@Component({
  selector: 'app-new-customer-entry-root',
  templateUrl: './new-customer-entry-root.component.html',
  styleUrls: ['./new-customer-entry-root.component.scss']
})

export class NewCustomerEntryRootComponent implements OnInit {
  isLoading: boolean = true;
  availableTerms: Term[] = [];
  availableCountries: string[] = [];
  availableReps: SalesRep[] | null = null;
  newCustomerFormValues: NewCustomerFormValues | null = null;
  isCustomerFormValid: boolean = false;

  @ViewChild(NewCustomerFormComponent) newCustomerForm!: NewCustomerFormComponent;

   get shouldShowSalesRepSelect() {
    return this.userService.isAdmin() || this.userService.isManager() || (this.userService.getRepNumbers().length > 1 && this.userService.isRep());
   }

  constructor(
    private termsProvider: TermsProviderService,
    private addressProvider: AddressProviderService,
    private customerProvider: CustomerProviderService,
    private userService: UserService,
    private salesRepProvider: SalesRepProvidersService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const termsPromise = this.termsProvider.getNewCustomer().then((terms) => {
      this.availableTerms = terms;
    });

    const countriesPromise = this.addressProvider.getAvailableCountriesSorted().then((countries) => {
      this.availableCountries = countries;
    });

    const repsPromise = this.shouldShowSalesRepSelect ? this.salesRepProvider.getReps(this.userService.getRepNumbers()).then((reps) => {
      this.availableReps = reps;
    }) : null;

    Promise.allSettled([termsPromise, countriesPromise, repsPromise]).then(() => this.isLoading = false);
  }

  async createCustomerClicked() {
    if (this.isCustomerFormValid) {
      assert(this.newCustomerFormValues);
      const generalInfo = this.newCustomerFormValues.generalInfo;
      assert(generalInfo.requestedTerms && generalInfo.name);

      const shouldSubmit = await new Promise<boolean>((resolve) => {
        if (!generalInfo.jbtNumber) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: 500, data: { body: 'No JBT number has been provided. Are you sure you want to create this customer without a JBT number?', confirmButtonText: 'Create Customer Anyway', cancelButtonText: 'Continue Editing' } })
          dialogRef.afterClosed().subscribe((didConfirmSubmission) => {
            resolve(didConfirmSubmission);
          })
        } else {
          resolve(true);
        }
      });

      if (shouldSubmit) {
        const newCustomer = this.buildNewCustomerFromForm();
        this.isLoading = true;
        try {
          const createdCustomer = await this.customerProvider.createProspect(newCustomer);
          const dialogRef = this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { icon: 'check', iconColor: 'success',  title: 'NEW CUSTOMER SUBMITTED', body: 'A new customer has been created. You can find the customer with the JBT number or store name you have entered.' } });
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigateByUrl(`/order?customerID=${createdCustomer.id}`);
          });

          setTimeout(() => {
            dialogRef.close();
          }, 5000);
        } catch (e) {
          console.error(e);
          this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { cancelButtonText: 'OK', icon: 'error', iconColor: 'critical',  title: 'ERROR', body: 'Failed to create new customer. Please try again.' } });
        } finally {
          this.isLoading = false;
        }
      }
    } else {
      this.newCustomerForm.generalInfoFormGroup.markAllAsTouched();
      this.newCustomerForm.buyerFormGroup.markAllAsTouched();
      this.newCustomerForm.arFormGroup.markAllAsTouched();
      this.newCustomerForm.addressFormGroup.markAllAsTouched();
      console.error('Form was invalid');
    }
  }

  async jbtChanged(jbtNumber: string | null) {
    if (jbtNumber) {
      this.isLoading = true;
      const customers = await this.customerProvider.getAllWithJBT(jbtNumber);
      this.isLoading = false;

      if (customers.length > 0) {
        const repReps = this.userService.getRepNumbers().concat(1);
        const firstAuthorizedCustomer = customers.find(customer => repReps.includes(customer.salesRepNumber));
        if (firstAuthorizedCustomer) {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { title: 'Customer Exists', body: 'The customer with the given JBT number already exists. Would you like to start an order with this customer?', cancelButtonText: 'Start Over', confirmButtonText: 'Begin New Order' } }).afterClosed().subscribe((shouldStartOrder) => {
            if (shouldStartOrder) {
              this.router.navigateByUrl(`/order?customerID=${customers[0].id}`);
            } else {
              window.location.reload();
            }
          });
        } else {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { title: 'Unauthorized', body: 'A account with the given JBT number already exists; however, you are not the rep associated with this account. Please contact your manager.', cancelButtonText: 'OK' } }).afterClosed().subscribe(() => {
            window.location.reload();
          });
        }
      }
    }
  }

  async contactInfoChanged(contactInfo: string | null) {
    if (contactInfo) {
      this.isLoading = true;
      const customers = await this.customerProvider.getAllWithContactInfo(contactInfo);
      this.isLoading = false;

      if (customers.length > 0) {
        const repReps = this.userService.getRepNumbers().concat(1);
        const firstAuthorizedCustomer = customers.find(customer => repReps.includes(customer.salesRepNumber));
        if (firstAuthorizedCustomer) {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { title: 'Customer Exists', body: 'A customer with the given contact info already exists. Would you like to start an order with this customer?', cancelButtonText: 'Start Over', confirmButtonText: 'Begin New Order' } }).afterClosed().subscribe((shouldStartOrder) => {
            if (shouldStartOrder) {
              this.router.navigateByUrl(`/order?customerID=${customers[0].id}`);
            } else {
              window.location.reload();
            }
          });
        } else {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { title: 'Unauthorized', body: 'An account with the given contact info already exists; however, you are not the rep associated with this account. Please contact your manager.', cancelButtonText: 'OK' } }).afterClosed().subscribe(() => {
            window.location.reload();
          });
        }
      }
    }
  }

  isValidChanged(isValid: boolean) {
    this.isCustomerFormValid = isValid;
  }

  valuesChanged(newCustomerFormValues: NewCustomerFormValues) {
    this.newCustomerFormValues = newCustomerFormValues;
  }

  private buildNewCustomerFromForm() {
    const repNumber = this.getRepNumber();
    assert(this.newCustomerFormValues, 'newCustomerFormValues was null')
    const newCustomer = newCustomerFromFormValues(this.newCustomerFormValues, repNumber ?? '');
    return newCustomer;
  }

  private getRepNumber() {
    if (this.userService.isAdmin() || this.userService.isManager()) {
      const rep = this.newCustomerForm.generalInfoFormGroup.controls.salesRep.getRawValue();
      return rep?.number;
    }
    return this.userService.getRepNumber();
  }
}
