import { Injectable } from '@angular/core';
import { WebProviderService } from './web.provider.service';
import { USERS } from '../constants/users';
import { assert } from '../utils/assert.util';
import { ManagedUser } from '../interfaces/managed-user';
import { UpdateUserRequest } from '../interfaces/update-user-request';
import { CreateUserRequest } from '../interfaces/create-user-request';

@Injectable({
  providedIn: 'root'
})
export class UsersProviderService {

  constructor(private webProvider: WebProviderService) { }

  async getAll() {
    const users = await this.webProvider.get<ManagedUser[]>(`${USERS.API_URL}`);
    assert(users, 'Users response was null.');
    return users;
  }

  async get(id: number) {
    const user = await this.webProvider.get<ManagedUser>(`${USERS.API_URL}/${id}`);
    assert(user, 'User response was null.');
    return user;
  }

  async update(id: number, userUpdate: UpdateUserRequest) {
    await this.webProvider.put(`${USERS.API_URL}/${id}`, userUpdate);
  }

  async inactivate(id: number) {
    await this.webProvider.put(`${USERS.API_URL}/${id}/inactivate`);
  }

  async activate(id: number) {
    await this.webProvider.put(`${USERS.API_URL}/${id}/activate`);
  }

  async getLastActiveDate(id: number) {
    const lastActiveDate = await this.webProvider.get<string>(`${USERS.API_URL}/${id}/lastActive`);
    return lastActiveDate;
  }

  async changePassword(id: number, password: string) {
    await this.webProvider.put(`${USERS.API_URL}/${id}/password`, { password });
  }

  async create(newUser: CreateUserRequest) {
    await this.webProvider.post(`${USERS.API_URL}`, newUser);
  }
}
