import { Order } from '../interfaces/order';

export function buildSubmitOrderFromOrder(order: Order) {
  const arContact = order.customer.contacts.find(contact => contact.type === 'Accounts Receivable');

  return {
    customer: order.customer,
    address: order.shippingAddress,
    proformaKey: order.proforma.key,
    stockBalanceKey: order.stockBalance?.key ?? null,
    orderKey: order.key,
    orderDetails: {
      buyerName: order.buyerName ?? null,
      buyerEmail: order.buyerEmail ?? null,
      buyerConfirmEmail: order.buyerEmail ?? null,
      buyerPhone: order.buyerPhone ?? null,
      arName: arContact?.name ?? null,
      arEmail: arContact?.email ?? null,
      arConfirmEmail: arContact?.email ?? null,
      arPhone: arContact?.phone ?? null,
      terms: order.terms,
      po: order.proforma.poNumber
    },
    proformaLineItems: order.proforma.lineItems,
    stockBalanceLineItems: order.stockBalance?.lineItems ?? []
  }
}