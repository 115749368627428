import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRootComponent } from './components/dashboard-root/dashboard-root.component';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { NavigationModule } from 'src/app/shared/modules/navigation/navigation.module';



@NgModule({
  declarations: [
    DashboardRootComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    NavigationModule
  ]
})
export class DashboardModule { }
