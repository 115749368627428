import { Injectable } from '@angular/core';
import { PROFORMA_LINES } from '../constants/proforma-lines';
import { ProformaLineItem } from '../interfaces/proforma-line-item';
import { ProformaLineItemAddRequest } from '../interfaces/proforma-line-item-add-request';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProformaLineItemProviderService {

  constructor(private webProvider: WebProviderService) { }

  async get(proformaKey: string) {
    const results = await this.webProvider.get<ProformaLineItem[]>(`${PROFORMA_LINES.API_URL}/${proformaKey}/lines`);
    assert(results, 'No proforma lines for the given proforma');
    return results;
  }

  async add(proformaKey: string, proformaLineItem: ProformaLineItemAddRequest) {
    const results = await this.webProvider.post(`${PROFORMA_LINES.API_URL}/${proformaKey}/lines`, proformaLineItem);
    return results;
  }

  async set(proformaKey: string, proformaLineItems: ProformaLineItemAddRequest[]) {
    await this.webProvider.put<ProformaLineItem[]>(`${PROFORMA_LINES.API_URL}/${proformaKey}/lines/set`, proformaLineItems);
  }

  async remove(proformaKey: number, proformaLineItemKey: string) {
    const results = await this.webProvider.delete(`${PROFORMA_LINES.API_URL}/${proformaKey}/lines/${proformaLineItemKey}`);
    return results;
  }
}
