import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Customer } from 'src/app/shared/interfaces/customer';

@Component({
  selector: 'app-customer-auto-complete',
  templateUrl: './customer-auto-complete.component.html',
  styleUrls: ['./customer-auto-complete.component.scss']
})
export class CustomerAutoCompleteComponent implements OnInit {

  @Input() customers: Customer[] = [];
  @Input() label?: string;
  @Input() itemLimit: number = 15;
  @Output() valueChanged = new EventEmitter<Customer | null>();
  @Output() focusChanged = new EventEmitter<boolean>();
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  control = new FormControl<Customer | string>('');
  filteredCustomers = new BehaviorSubject<Customer[]>([]);
  selectedCustomer: Customer | null = null;
  filteredCustomersSubscription?: Subscription

  private get actualItemLimit() {
    if (this.itemLimit < 0) {
      return null;
    }
    return this.itemLimit;
  }

  constructor() { }

  ngOnInit(): void {
    this.setupSelectOptions(this.customers);
  }

  setupSelectOptions(customers: Customer[]) {
    this.control.valueChanges.subscribe(v => {
      if (typeof v === 'string' && v.length > 2) {
        const startsWithResults = customers.filter(o => o.number?.toString().startsWith(v) || o.name.toLowerCase().startsWith(v.toLowerCase()));
        const remainingIncludesResults = customers.filter(o => (o.number?.toString().includes(v) || o.name.toLowerCase().includes(v.toLowerCase())) && !startsWithResults.some(startsWithResult => startsWithResult.id === o.id));
        const matchingResults = startsWithResults.concat(remainingIncludesResults);
        this.filteredCustomers.next(matchingResults.slice(0, this.actualItemLimit ?? matchingResults.length));
      } else if (v && typeof v === 'object') {
        this.filteredCustomers.next([v]);
      } else {
        this.filteredCustomers.next([]);
      }
    });
  }

  ngOnChanges(changes: { customers: { currentValue: Customer[] } }) {
    this.autocomplete?.updatePosition();
    this.setupSelectOptions(changes.customers.currentValue);
  }

  customerSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedCustomer = event.option.value
    this.valueChanged.emit(this.selectedCustomer);
  }

  clear() {
    this.control.setValue('');
    this.selectedCustomer = null;
    this.autocomplete.writeValue('');
  }

  resetPanelSize() {
    this.autocomplete.closePanel();
    this.autocomplete.openPanel();
  }

  getCustomerBuyer(customer: Customer) {
    return customer.contacts.find(contact => contact.type === 'Buyer');
  }
}
