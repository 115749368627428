<div class="Content Flexbox Flexbox--column Flexbox--justify-center Flexbox--align-center">
  <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
  <div class="LoginContainer">
    <mat-card>
      <mat-card-title>Login</mat-card-title>
      <mat-card-content [formGroup]="formGroup">
        <div class="Flexbox Flexbox--column">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input #input matInput type="text" formControlName="username" (keydown)="onKeyDown($event)">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input #input matInput type="password" formControlName="password" (keydown)="onKeyDown($event)">
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="Flexbox Flexbox--justify-right">
          <button mat-raised-button color="accent" (click)="nextClicked()">Next</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
