import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';

export function getLineItemOrderStatsRestrictionReason(lineItem: StockBalanceLineItem): string |  null {
  if (lineItem.areOrderStatsLoaded) {
    if (!lineItem.lastStockOrdered) {
      return 'No stock orders within past 4 years';
    }

    if (lineItem.recentStockOrderedCount && lineItem.recentStockOrderedCount < 1) {
      return 'Recent stock order(s)';
    }

    if (lineItem.recentCustomerOrderedCount && lineItem.recentCustomerOrderedCount > 0) {
      return 'Recent special order(s)';
    }
  }
  return null;
}
