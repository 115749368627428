<mat-dialog-content>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Deleted"></app-status>
    <div>
      Drafts that have been deleted automatically after 14 days or manually removed
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Expired"></app-status>
    <div>
      Submitted orders expire after 7 days
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Canceled"></app-status>
    <div>
      Canceled in HALO
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Draft"></app-status>
    <div>
      Unsubmitted and not viewable by the retailer
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Pending Retailer"></app-status>
    <div>
      Awaiting retailer approval
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Pending AR"></app-status>
    <div>
      Awaiting AR approval
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="AR Hold"></app-status>
    <div>
      Prerequisites exist and have not yet been met
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Processing"></app-status>
    <div>
      Preparing order
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="Invoiced"></app-status>
    <div>
      An invoice has been created for the order
    </div>
  </div>
  <div class="Flexbox Flexbox--column StatusInfoLine">
    <app-status status="AR Denied"></app-status>
    <div>
      The order will not be processed
    </div>
  </div>
</mat-dialog-content>
