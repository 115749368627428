import { Injectable } from '@angular/core';
import { CUSTOMER } from '../constants/customer';
import { Customer } from '../interfaces/customer';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';
import { NewCustomer } from '../interfaces/new-customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerProviderService {
  constructor(private webClient: WebProviderService) { }

  async getWithSearch(search: string): Promise<Customer[]> {
    const result = await this.webClient.get<Customer[]>(`${CUSTOMER.API_URL}?search=${search}`);
    assert(result, 'Customers search response was null.');
    return result;
  }

  async getAll(): Promise<Customer[]> {
    const result = await this.webClient.get<Customer[]>(`${CUSTOMER.API_URL}`);
    assert(result, 'Customers response was null.');
    return result;
  }

  async get(id: number): Promise<Customer> {
    const result = await this.webClient.get<Customer>(`${CUSTOMER.API_URL}/${id}`);
    assert(result, 'Customer was null for the given id.');
    return result;
  }

  async getAllWithJBT(jbtNumber: string) {
    const result = await this.webClient.get<Customer[]>(`${CUSTOMER.API_URL}/jbt/${jbtNumber}`);
    assert(result, 'No customers found for the given jbt number.');
    return result;
  }

  async getAllWithContactInfo(query: string) {
    const result = await this.webClient.get<Customer[]>(`${CUSTOMER.API_URL}/contactInfo/${query}`);
    assert(result, 'No customers found for the given contact query.');
    return result;
  }

  async createProspect(prospect: NewCustomer) {
    const createdCustomer = await this.webClient.post<Customer>(`${CUSTOMER.API_URL}`, prospect);
    assert(createdCustomer, 'No customer returned');
    return createdCustomer;
  }

  async updateNetsuiteNumber(id: number, netsuiteNumber: string) {
    await this.webClient.patch<Customer>(`${CUSTOMER.API_URL}/${id}/NetsuiteNumber`, { netsuiteNumber });
  }
}
