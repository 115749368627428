<ng-container *ngIf="order && order.stockBalance && !isLoading then orderTemplate;else loadingTemplate"></ng-container>
<ng-template #loadingTemplate>
  <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
</ng-template>
<ng-template #orderTemplate>
  <div class="ContentWrapper Flexbox">
    <div class="Content Flexbox Flexbox--column" *ngIf="order && order.stockBalance !== null">
      <img class="Logo" src="/assets/BenchmarkLogo_Black.png" alt="Benchmark">
      <h3 class="Title">Return</h3>
      <div class="ButtonContainer Flexbox">
        <button mat-raised-button color="accent" (click)="showPrintDialog()">Print</button>
      </div>
      <div class="OrderInfoContainer">
        <div>
          <p>CUST #</p>
          <p>{{ order.customer.number }}</p>
        </div>
        <div>
          <p>Web Order #</p>
          <p>{{ order.webNumber }}</p>
        </div>
        <div>
          <p>RA #</p>
          <p *ngIf="doesStockBalanceHaveLineItems">RA-{{ order.webNumber }}</p>
        </div>
        <div>
          <p>Sales Rep</p>
          <p>{{ order.salesRep.number }}</p>
        </div>
      </div>
      <div class="OrderInfoContainer">
        <div>
          <p>UPS/FEDEX Ship To</p>
          <app-address [address]="benchmarkFedexReturnAddress"></app-address>
        </div>
        <div>
          <p>USPS Ship To</p>
          <app-address [address]="benchmarkUSPSReturnAddress"></app-address>
        </div>
      </div>
      <div class="Flexbox Flexbox--column LineItems">
        <app-view-stock-balance-item *ngFor="let lineItem of order?.stockBalance?.lineItems ?? []" [lineItem]="lineItem" [stockBalanceKey]="order.stockBalance.key" type="return" [showBarcode]="true"></app-view-stock-balance-item>
      </div>
      <div *ngIf="!doesStockBalanceHaveLineItems">
        No returnable items found.
      </div>
    </div>
  </div>
</ng-template>