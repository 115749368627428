import { Contact } from '../interfaces/contact';

export const compareContacts = (contact1?: Contact, contact2?: Contact) => {
  if (!contact1 || !contact2) {
    return false;
  }

  const uniqueKeysFromContacts = Array.from(new Set(Object.keys(contact1).concat(Object.keys(contact2)))) as (keyof Contact)[];
  const areContactsTheSame = uniqueKeysFromContacts.reduce((flag, key) => flag && contact1[key] === contact2[key], true);
  return areContactsTheSame;
};
