import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, map, Observable, startWith, Subscription, tap } from 'rxjs';
import { AutoCompleteOption } from '../../interfaces/auto-complete-option';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})

export class AutoCompleteComponent implements OnInit {
  @Input() options: AutoCompleteOption[] = [];
  @Input() label?: string;
  @Input() itemLimit: number = 15;
  @Input() dynamic: boolean = false;
  @Output() valueChanged = new EventEmitter<AutoCompleteOption | null>();
  @Output() focusChanged = new EventEmitter<boolean>();
  @Output() textChange = new EventEmitter<string | null>();
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  control = new FormControl<AutoCompleteOption | string>('');
  filteredOptions = new BehaviorSubject<AutoCompleteOption[]>([]);
  selectedOption: AutoCompleteOption | null = null;
  filteredOptionsSubscription?: Subscription

  private get actualItemLimit() {
    if (this.itemLimit < 0) {
      return null;
    }
    return this.itemLimit;
  }

  constructor() { }

  ngOnInit(): void {
    this.setupSelectOptions(this.options);
  }

  setupSelectOptions(options: AutoCompleteOption[]) {
    if (!this.dynamic) {
      this.control.valueChanges.subscribe(v => {
        if (typeof v === 'string' && v.length > 2) {
          const startsWithResult = options.filter(o => o.value.toLowerCase().startsWith(v.toLowerCase()));
          const remainingIncludesResults = options.filter(o => o.value.toLowerCase().includes(v.toLowerCase()) && !startsWithResult.includes(o));
          const matchingResults = startsWithResult.concat(remainingIncludesResults);
          this.filteredOptions.next(matchingResults.slice(0, this.actualItemLimit ?? matchingResults.length));
        } else if (v && typeof v === 'object') {
          this.filteredOptions.next([v]);
        } else {
          this.filteredOptions.next([]);
        }
      });
    } else {
      this.filteredOptions.next(options.slice(0, this.actualItemLimit ?? options.length));
    }
  }

  ngOnChanges(changes: { options: { currentValue: AutoCompleteOption[] } }) {
    this.autocomplete?.updatePosition();
    this.setupSelectOptions(changes.options.currentValue);
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedOption = event.option.value
    this.valueChanged.emit(this.selectedOption);
  }

  inputKeyDown(e: KeyboardEvent) {
    if (this.control.value && e.key === 'Enter' && !this.selectedOption) {
      this.selectedOption = this.filteredOptions.value[0];
      this.autocomplete.closePanel();
      this.valueChanged.emit(this.selectedOption);
    }
  }

  clear() {
    this.control.setValue('');
    this.selectedOption = null;
    this.autocomplete.writeValue('');
  }

  resetPanelSize() {
    this.autocomplete.closePanel();
    this.autocomplete.openPanel();
  }

  inputTextChanged(value: string | AutoCompleteOption | null) {
    if (typeof value === 'object') {
      this.textChange.emit(value?.value)
    } else {
      this.textChange.emit(value);
    }
  }
}
