import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  isLoading = false;
  isPasswordVisible = false;
  formGroup = this.formBuilder.group({
    password: [null as string | null, [Validators.required]]
  })

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent, string | null>,
    @Inject(MAT_DIALOG_DATA) public userName: string | null,
  ) { }

  ngOnInit(): void {
  }

  getError(formControl: AbstractControl) {
    if (formControl.hasError('required')) {
      return 'Please enter a value for this field'
    }

    if (formControl.hasError('email')) {
      return 'The email entered is not valid'
    }

    if (formControl.hasError('pattern')) {
      return 'The value entered is not valid';
    }

    const otherErrors = Object.keys(formControl.errors ?? {})

    if (otherErrors.length > 0) {
      const error = formControl.errors?.[otherErrors[0]];
      return error;
    }

    return '';
  }
}
