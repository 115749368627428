import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SalesRep } from '../../interfaces/sales-rep';
import { NewCustomerFormValues } from '../../interfaces/new-customer-form-values';
import { NewCustomerFormComponent } from '../new-customer-form/new-customer-form.component';
import { TermsProviderService } from '../../providers/terms.provider.service';
import { AddressProviderService } from '../../providers/address.provider.service';
import { CustomerProviderService } from '../../providers/customer.provider.service';
import { UserService } from '../../services/user.service';
import { SalesRepProvidersService } from '../../providers/sales-rep.provider.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { newCustomerFromFormValues } from '../../utils/new-customer.util';
import { Customer } from '../../interfaces/customer';
import { NewCustomer } from '../../interfaces/new-customer';
import { assert } from '../../utils/assert.util';
import { Term } from '../../interfaces/term';
import { Contact } from '../../interfaces/contact';
import { formatPhoneUS } from '../../utils/text-formatters.util';

@Component({
  selector: 'app-approve-customer-dialog',
  templateUrl: './approve-customer-dialog.component.html',
  styleUrls: ['./approve-customer-dialog.component.scss']
})
export class ApproveCustomerDialogComponent  {

  isLoading: boolean = true;
  availableTerms: Term[] = [];
  availableCountries: string[] = [];
  availableReps: SalesRep[] | null = null;
  newCustomerFormValues: NewCustomerFormValues | null = null;
  isCustomerFormValid: boolean = false;
  disabledFields: string[] = [];

  @ViewChild(NewCustomerFormComponent) newCustomerForm!: NewCustomerFormComponent;

   get shouldShowSalesRepSelect() {
    return this.userService.isAdmin() || this.userService.isManager() || this.userService.isAR();
   }

  constructor(
    public dialogRef: MatDialogRef<ApproveCustomerDialogComponent, NewCustomerFormValues | null>,
    @Inject(MAT_DIALOG_DATA) public  inputData: { customer: Customer, orderBuyer: Contact },
    private termsProvider: TermsProviderService,
    private addressProvider: AddressProviderService,
    private userService: UserService,
    private salesRepProvider: SalesRepProvidersService
  ) { }
  ngOnInit(): void {
    this.isLoading = true;
    const termsPromise = this.termsProvider.get(false).then((terms) => {
      this.availableTerms = terms;
    });

    const countriesPromise = this.addressProvider.getAvailableCountriesSorted().then((countries) => {
      this.availableCountries = countries;
    });

    const repsPromise = this.shouldShowSalesRepSelect ? this.salesRepProvider.getReps(this.userService.getRepNumbers()).then((reps) => {
      this.availableReps = reps;
    }) : null;

    Promise.allSettled([termsPromise, countriesPromise, repsPromise]).then(() => {
      this.newCustomerFormValues = this.buildNewCustomerFormValuesWithCustomer(this.inputData.customer, this.inputData.orderBuyer);
      this.isLoading = false
    });

    if (this.inputData.customer.netsuiteNumber) {
      this.disabledFields = ['generalInfo.name', 'address'];
    }
  }

  public buildNewCustomerFormValuesWithCustomer(customer: Customer, orderBuyer: Contact) {
    const matchingRep = this.availableReps?.find(availableRep => availableRep.number == customer.salesRepNumber)

    const ar = customer.contacts.find(contact => contact.type === 'Accounts Receivable');
    const buyer = orderBuyer;

    const newCustomerFormValues: NewCustomerFormValues = {
      generalInfo: {
        jbtNumber: customer.jbtNumber,
        name: customer.name,
        requestedTerms: { id: customer.defaultTermsID, name: customer.defaultTerms, externalID: customer.defaultTermsExternalID },
        salesRep: matchingRep ?? null
      },
      ar: {
        email: ar?.email ?? null,
        name: ar?.name ?? null,
        phone: formatPhoneUS(ar?.phone ?? null)
      },
      buyer: {
        email: buyer?.email ?? null,
        name: buyer?.name ?? null,
        phone: formatPhoneUS(buyer?.phone ?? null)
      },
      address: {
        street1: customer.billingAddress?.street1 ?? null,
        street2: customer.billingAddress?.street2 ?? null,
        city: customer.billingAddress?.city ?? null,
        stateCode: customer.billingAddress?.stateCode ?? null,
        postalCode: customer.billingAddress?.postalCode ?? null,
        country: customer.billingAddress?.country ?? null
      }
    };

    return newCustomerFormValues;
  }

  async approveCustomer() {
    if (this.isCustomerFormValid) {
      assert(this.newCustomerFormValues);
      const generalInfo = this.newCustomerFormValues.generalInfo;
      assert(generalInfo.requestedTerms && generalInfo.name);
      this.dialogRef.close(this.newCustomerFormValues);
    } else {
      this.newCustomerForm.generalInfoFormGroup.markAllAsTouched();
      this.newCustomerForm.buyerFormGroup.markAllAsTouched();
      this.newCustomerForm.arFormGroup.markAllAsTouched();
      this.newCustomerForm.addressFormGroup.markAllAsTouched();
      console.error('Form was invalid');
    }
  }

  isValidChanged(isValid: boolean) {
    this.isCustomerFormValid = isValid;
  }

  valuesChanged(newCustomerFormValues: NewCustomerFormValues) {
    this.newCustomerFormValues = newCustomerFormValues;
  }

  private buildNewCustomerFromForm() {
    const repNumber = this.getRepNumber();
    assert(this.newCustomerFormValues, 'newCustomerFormValues was null')
    const newCustomer = newCustomerFromFormValues(this.newCustomerFormValues, repNumber ?? '');
    return newCustomer;
  }

  private getRepNumber() {
    if (this.userService.isAdmin() || this.userService.isManager() || this.userService.isAR()) {
      const rep = this.newCustomerForm.generalInfoFormGroup.controls.salesRep.getRawValue();
      return rep?.number;
    }
    return this.userService.getRepNumber();
  }
}
