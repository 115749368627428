import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LastOrderedPerSkuResponseItem } from 'src/app/shared/interfaces/last-ordered-per-sku-response';
import { Order } from 'src/app/shared/interfaces/order';
import { QuantityPerStyleResponseItem } from 'src/app/shared/interfaces/quantity-per-style-response';
import { SubmitOrder } from 'src/app/shared/interfaces/submit-order';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { OrderStatsProviderService } from 'src/app/shared/providers/order-stats.provider.service';
import { OrderProviderService } from 'src/app/shared/providers/order.provider.service';
import { ProformaLineItemProviderService } from 'src/app/shared/providers/proforma-line-item.provider.service';
import { StockBalanceLineItemsProviderService } from 'src/app/shared/providers/stock-balance-line-items.provider.service';
import { OrderSaveService } from 'src/app/shared/services/order-save.service';
import { OrderStatsService } from 'src/app/shared/services/order-stats.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProformaLineItemsService } from 'src/app/shared/services/proforma-line-items.service';
import { StockBalanceLineItemsService } from 'src/app/shared/services/stock-balance-line-items.service';
import { SubmitOrderService } from 'src/app/shared/services/submit-order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { buildSummaryCollections, getCollectionRestrictionReason } from 'src/app/shared/utils/collection.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { buildSubmitOrderFromOrder } from 'src/app/shared/utils/submit-order.util';
import { ProformaComponent } from '../proforma/proforma.component';
import { StockBalanceComponent } from '../stock-balance/stock-balance.component';

@Component({
  selector: 'app-order-entry-root',
  templateUrl: './order-entry-root.component.html',
  styleUrls: ['./order-entry-root.component.scss']
})
export class OrderEntryRootComponent implements OnInit {
  private submitOrder!: SubmitOrder;
  private order: Order | null = null;
  private proformaLineSubscription!: Subscription | null;
  private stockBalanceLineSubscription!: Subscription | null;
  private Last15MonthsCustomerOrderPerStyle: Promise<void> | null = null;
  private Last15MonthsStockOrderPerStyle: Promise<void> | null = null;
  private LastStockOrderedPerSKU: Promise<void | LastOrderedPerSkuResponseItem[]> | null = null;
  selectedTab = 0;
  isProformaLoading = false;
  isLoadingItems = false;
  isAutoSaving = false;
  shouldUnscheduleAutoSave = true;
  hasProformaChanges = false;
  hasStockBalanceChanges = false;
  stockBalanceCount: number | null = null;
  proformaCount: number | null = null;

  @ViewChild(ProformaComponent) proformaComponent!: ProformaComponent;
  @ViewChild(StockBalanceComponent) stockBalanceComponent!: StockBalanceComponent;

  get hasOrderChanges() {
    return this.orderSaveService.hasChanges;
  }

  get saveButtonText() {
    if (this.order?.status !== 'Draft') {
      return 'Save';
    }
    return 'Save as draft';
  }

  get lastSavedAt() {
    return this.order?.dateUpdated ?? null;
  }

  get isLoading() {
    return this.isLoadingItems || this.isProformaLoading;
  }

  get canSaveAsDraft() {
    const user = this.userService.get();
    return (this.order?.status === 'Draft' && this.order.ownerUsername === user?.email) || this.order?.status === 'Pending Retailer' || ((this.order?.status === 'Pending AR' || this.order?.status === 'AR Hold') && (this.userService.isAR() || this.userService.isAdmin()));
  }

  get canSaveAndClose() {
    return (this.userService.isAR() || this.userService.isAdmin()) && this.canSaveAsDraft;
  }

  get isOrderOwner() {
    return this.order && this.userService.get()?.reps.includes(this.order.salesRep.number);
  }

  get canDelete() {
    return this.order && this.isOrderOwner && this.order.status === 'Draft';
  }

  get shouldShowNextButton() {
    return this.order;
  }

  get summaryCollections() {
    const proformaLines = this.proformaLineItemService.getAll();
    const stockBalanceLines = this.stockBalanceLineItemService.getAll();
    const collections = buildSummaryCollections(proformaLines, stockBalanceLines);
    collections.sort((a, b) => a.name.localeCompare(b.name));
    return collections;
  }

  get StockBalanceRelatedSummaryCollections() {
    const relatedCollections = this.summaryCollections.filter(collection => collection.stockBalanceItems.length > 0);
    return relatedCollections;
  }

  get restrictedCollections() {
    return this.customerSummaryBarService.get()?.itemRestrictions.map(restriction => restriction.fullName) ?? [];
  }

  get canEnterStockBalance() {
    const customer = this.customerSummaryBarService.get();
    return customer?.isActive && customer?.number;
  }

  constructor(
    private router: Router,
    private orderProvider: OrderProviderService,
    private route: ActivatedRoute,
    private location: Location,
    private proformaLineItemService: ProformaLineItemsService,
    private stockBalanceLineItemService: StockBalanceLineItemsService,
    private orderService: OrderService,
    private customerSummaryBarService: CustomerSummaryBarService,
    private proformaLineItemProvider: ProformaLineItemProviderService,
    private stockBalanceLineItemProvider: StockBalanceLineItemsProviderService,
    private submitOrderService: SubmitOrderService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private orderSaveService: OrderSaveService,
    private orderStatsService: OrderStatsService,
    private orderStatsProvider: OrderStatsProviderService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoadingItems = true;
    const submitOrder = this.submitOrderService.get();
    if (!submitOrder && this.route.snapshot.paramMap.has('orderID') || (submitOrder?.orderKey && submitOrder.orderKey !== this.route.snapshot.paramMap.get('orderID'))) {
      const orderKey = this.route.snapshot.paramMap.get('orderID')!;
      const order = await this.orderProvider.get(orderKey);
      order.proforma.lineItems = await this.proformaLineItemProvider.get(order.proforma.key);
      assert(order, 'No order for the given key.');
      this.order = order;
      this.orderService.set(order);

      this.submitOrder = buildSubmitOrderFromOrder(order);
      this.submitOrderService.set(this.submitOrder);

      assert(this.submitOrder.customer, 'No customer selected');
      this.customerSummaryBarService.set(this.submitOrder.customer);

      assert(this.submitOrder.proformaKey, 'No proforma key');
      const lineItems = await this.proformaLineItemProvider.get(this.submitOrder.proformaKey);
      this.submitOrder.proformaLineItems = lineItems;
      this.proformaLineItemService.set(lineItems);
      this.proformaCount = this.submitOrder.proformaLineItems.length

      if (this.order.stockBalance) {
        this.submitOrder.stockBalanceLineItems = await this.stockBalanceLineItemProvider.get(this.order.stockBalance.key);
        this.stockBalanceLineItemService.set(this.submitOrder.stockBalanceLineItems);
        this.stockBalanceCount = this.submitOrder.stockBalanceLineItems.length
      } else {
        this.stockBalanceLineItemService.set([]);
      }
    } else {
      if (!submitOrder) {
        this.router.navigateByUrl('/order');
      } else {
        assert(submitOrder, 'No SubmitOrder object');
        this.submitOrder = submitOrder;
        this.order = this.orderService.get();
        this.proformaLineItemService.set(this.submitOrder.proformaLineItems);
        this.stockBalanceLineItemService.set(this.submitOrder.stockBalanceLineItems);
      }
    }

    if (!this.orderStatsService.getLast15MonthsCustomer() || !this.orderStatsService.getLast15MonthsStock() || !this.orderStatsService.getOrderedDatesPerSKU()) {
      const customer = this.submitOrder.customer;
      assert(customer, 'Customer was null');
      this.Last15MonthsCustomerOrderPerStyle = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(customer.number, 'customer').then(response => this.orderStatsService.setLast15MonthsCustomer(response));
      this.Last15MonthsStockOrderPerStyle = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(customer.number, 'stock').then(response => this.orderStatsService.setLast15MonthsStock(response));
      this.LastStockOrderedPerSKU = this.orderStatsProvider.GetLastStockOrderedPerSKU(customer.number).then(response => this.orderStatsService.setOrderedDatesPerSKU(response));
      this.orderStatsService.orderedDatesPerSKUPromise = this.orderStatsProvider.GetLastOrderedPerSKU(customer.number);
    } else {
      this.Last15MonthsCustomerOrderPerStyle = new Promise(res => res());
      this.Last15MonthsStockOrderPerStyle = new Promise(res => res());
      this.LastStockOrderedPerSKU = new Promise(res => res());
      this.orderStatsService.orderedDatesPerSKUPromise = null;
    }

    this.proformaLineSubscription = this.proformaLineItemService.subscribe(async lineItems => {
      this.hasProformaChanges = lineItems.some(line => line.hasChanges) || this.proformaCount != null && this.proformaCount != lineItems.length;
      this.proformaCount = lineItems.length;

      if (!this.order && lineItems.length > 0) {
        this.isLoadingItems = true;
        this.order = await this.createOrder();
        this.order.proforma.lineItems = await this.proformaLineItemProvider.get(this.order.proforma.key);
        this.submitOrder.orderKey = this.order.key;
        this.submitOrder.proformaKey = this.order.proforma.key;
        this.submitOrderService.set(this.submitOrder);
        this.hasProformaChanges = false;
        this.isLoadingItems = false;
      }

      if (this.order && this.submitOrder) {
        this.submitOrder.proformaLineItems = lineItems;
        this.submitOrderService.set(this.submitOrder);
        this.setStockBalanceLineItemEligibility();

        const user = this.userService.get();
        if (this.hasProformaChanges) {
          this.orderSaveService.hasChanges = true;
          if (this.order?.status === 'Draft' && this.order.ownerUsername === user?.email) {
            this.scheduleSave();
          }
        }
      }
    });

    this.stockBalanceLineSubscription = this.stockBalanceLineItemService.subscribe(async lineItems => {
      this.isLoadingItems = true;
      this.hasStockBalanceChanges = lineItems.some(line => line.hasChanges) || this.stockBalanceCount != null && this.stockBalanceCount != lineItems.length;
      this.stockBalanceCount = lineItems.length

      if (lineItems.length > 0) {
        this.setStockBalanceLineItemEligibility();

        Promise.all([this.Last15MonthsCustomerOrderPerStyle, this.Last15MonthsStockOrderPerStyle, this.LastStockOrderedPerSKU]).then(_ => lineItems.forEach(lineItem => {
          lineItem.areOrderStatsLoaded = true

          const recentStockOrderedCount = this.orderStatsService.getLast15MonthsStock()?.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
          const recentCustomerOrderedCount = this.orderStatsService.getLast15MonthsCustomer()?.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
          const lastStockOrdered = this.orderStatsService.getOrderedDatesPerSKU()?.find(stat => stat.sku === lineItem.item?.sku) ?? null;

          lineItem.lastStockOrdered = lastStockOrdered ? new Date(lastStockOrdered.date) : null;
          lineItem.recentStockOrderedCount = recentStockOrderedCount?.quantity ?? null;
          lineItem.recentCustomerOrderedCount = recentCustomerOrderedCount?.quantity ?? null;

          this.setStockBalanceLineItemEligibility();
        }));

        if (!this.order) {
          this.order = await this.createOrder();
          this.submitOrder.orderKey = this.order.key;
          this.submitOrder.proformaKey = this.order.proforma.key;
          this.submitOrderService.set(this.submitOrder);
          this.hasStockBalanceChanges = false;
        }

        if (!this.order.stockBalance) {
          this.order.stockBalance = {
            orderID: 0,
            key: '',
            lineItems: [],
            invoiceNumber: null,
            total: 0,
            buyingGroup: null,
            buyingGroupPercentage: null,
            priceClass: this.order.proforma.priceClass,
            priceClassPercentage: null,
           };
          this.submitOrderService.set(this.submitOrder);
        }
      }

      if (this.order && this.submitOrder && this.order.stockBalance) {
        this.submitOrder.stockBalanceLineItems = lineItems;
        this.submitOrderService.set(this.submitOrder);
        const user = this.userService.get();
        if (this.hasStockBalanceChanges) {
          this.orderSaveService.hasChanges = true;
          if (this.order?.status === 'Draft' && this.order.ownerUsername === user?.email) {
            this.scheduleSave();
          }
        }
      }

      this.isLoadingItems = false;
    });

    this.isLoadingItems = false;
  }

  private setStockBalanceLineItemEligibility() {
    const stockBalanceLines = this.stockBalanceLineItemService.getAll();
    stockBalanceLines.forEach(lineItem => {
      const collection = this.summaryCollections.find(collection => collection.name === lineItem.itemCollection);
      if (collection) {
        const reasons = [getCollectionRestrictionReason(collection, this.restrictedCollections), getLineItemOrderStatsRestrictionReason(lineItem)].filter(reason => !!reason).map(reason => `• ${reason}`);
        lineItem.restrictionReason = reasons.join('\n');
        lineItem.isEligible = !lineItem.restrictionReason;
        lineItem.isApproved = !lineItem.restrictionReason || null;
      }
    });
  }

  ngOnDestroy() {
    this.proformaLineSubscription?.unsubscribe();
    this.stockBalanceLineSubscription?.unsubscribe();
    if (this.shouldUnscheduleAutoSave) {
      this.orderSaveService.reset();
    }
  }

  deleteButtonClicked() {
    this.orderSaveService.unschedule();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Remove draft?', body: 'Are you sure you want to remove this draft?', cancelButtonText: 'Cancel', confirmButtonText: 'Remove' } });
    dialogRef.afterClosed().subscribe(async (didConfirm) => {
      if (didConfirm) {
        try {
          assert(this.order, 'Order was null');
          await this.orderProvider.delete(this.order.key);
          this.router.navigateByUrl('/');
        } catch (e) {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { icon: 'error', iconColor: 'warn', body: 'An error has occurred. If the issue persists, please contact support.', confirmButtonText: 'Ok' } });
          console.error(e);
        }
      } else {
        this.scheduleSave();
      }
    });
  }

  async saveAndCloseClicked() {
    const couldSave = await this.saveAsDraftClicked();
    if (couldSave) {
      this.router.navigateByUrl(`/history`).then(() => window.location.reload());
    }
  }

  async saveAsDraftClicked() {
    const order = this.orderService.get();

    if (order?.status !== 'Draft') {
      const areProformaLinesValid = await this.areProformaLinesValid();
      if (!areProformaLinesValid) {
        return false;
      }
    }

    if (order?.status === 'Pending Retailer') {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: `Are you sure you want to update this order? An email will be sent to the buyer (${order.buyerEmail}) for approval.`, confirmButtonText: 'Save', cancelButtonText: 'Cancel' } });
      await new Promise<void>((res) => {
        dialogRef.afterClosed().subscribe(async (didConfirm) => {
          if (didConfirm) {
            await this.save();
            res();
          }
        });
      });
    } else {
      await this.save();
    }
    return true;
  }

  private async save() {
    this.isLoadingItems = true;
    if (!this.order) {
      this.order = await this.createOrder();
    } else {
      this.orderSaveService.unschedule();
      this.submitOrder.proformaLineItems = this.proformaLineItemService.getAll();
      this.submitOrder.stockBalanceLineItems = this.stockBalanceLineItemService.getAll();
      this.order = await this.orderSaveService.save(this.submitOrder);
      this.orderService.set(this.order);
    }
    this.submitOrderService.set(this.submitOrder);
    this.hasProformaChanges = false;
    this.hasStockBalanceChanges = false;
    this.isLoadingItems = false;
    this.snackBar.open('Saved!', undefined, { duration: 3000 });
  }

  async createOrder() {
    this.submitOrder.proformaLineItems = this.proformaLineItemService.getAll();
    this.submitOrder.stockBalanceLineItems = this.stockBalanceLineItemService.getAll();
    const order = await this.orderSaveService.createOrder(this.submitOrder)
    this.orderService.set(order);
    const submitOrder = buildSubmitOrderFromOrder(order);
    this.submitOrderService.set(submitOrder);
    this.location.replaceState(`/order/edit/${order.key}`);
    return order;
  }

  scheduleSave() {
    this.submitOrder.proformaLineItems = this.proformaLineItemService.getAll();
    this.submitOrder.stockBalanceLineItems = this.stockBalanceLineItemService.getAll();
    const scheduleResult = this.orderSaveService.scheduleSave(this.submitOrder, 15, 30);

    scheduleResult.beforeSave.subscribe(() => {
      this.isAutoSaving = true;
    });

    scheduleResult.afterSave.subscribe(({ submitOrder, order }) => {
      this.submitOrderService.set(submitOrder);
      this.order = order;
      this.orderService.set(order);
      this.hasProformaChanges = false;
      this.hasStockBalanceChanges = false;
      this.isAutoSaving = false;
    });
  }

  async nextClicked() {
    const areProformaLinesValid = await this.areProformaLinesValid();
    if (areProformaLinesValid) {
      this.shouldUnscheduleAutoSave = false;
      this.router.navigateByUrl(`/order/approval/${this.submitOrder.orderKey ?? ''}`);
    }
  }

  private async areProformaLinesValid() {
    try {
      const proformaLineItems = this.proformaLineItemService.getAll();
      if (proformaLineItems.length === 0) {
        throw this.buildInvalidOrderError('Please select at least 1 order line item before proceeding.');
      }

      this.proformaComponent.showDuplicates();
      this.stockBalanceComponent.showDuplicates();

      if (this.proformaComponent.hasIncompleteFields && this.proformaComponent.hasDuplicates) {
        throw this.buildInvalidOrderError('This order contains duplicates and incomplete line items. Please correct the highlighted issues.')
      }

      if (this.stockBalanceComponent.hasIncompleteFields && this.stockBalanceComponent.hasDuplicates) {
        throw this.buildInvalidOrderError('This stock balance contains duplicates and incomplete line items. Please correct the highlighted issues.')
      }

      if (this.proformaComponent.hasIncompleteFields) {
        throw this.buildInvalidOrderError('This order contains incomplete line items. Please complete the highlighted fields.')
      }

      if (this.stockBalanceComponent.hasIncompleteFields) {
        throw this.buildInvalidOrderError('This stock balance contains incomplete line items. Please complete the highlighted fields.')
      }

      if (this.proformaComponent.hasDuplicates) {
        throw this.buildInvalidOrderError('This order contains duplicates. Please remove the duplicated line items.')
      }

      if (this.stockBalanceComponent.hasDuplicates) {
        throw this.buildInvalidOrderError('This stock balance contains duplicates. Please remove the duplicated line items.')
      }

      const shouldAddMoreDisplays = this.proformaComponent.shouldPromptForDisplay && await new Promise((resolve) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: 'You have not added any displays. Would you like to continue adding items?', confirmButtonText: 'No, proceed anyway', cancelButtonText: 'Yes, add more'} })
        dialogRef.beforeClosed().subscribe(didConfirm => {
          resolve(!didConfirm);
        });
      });

      if (shouldAddMoreDisplays) {
        return false;
      }

      const shouldNotAddMoreClasses = this.order?.isOpportunity && this.summaryCollections.filter(collection => !['materials', 'printed materials'].includes(collection.name.toLowerCase())).length < 2 && !this.summaryCollections.some(collection => collection.name.toLowerCase() === 'programs') && await new Promise((resolve) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: 'At least 2 item classes are required to open an account. Would you like to add more items?', confirmButtonText: 'No, proceed anyway', cancelButtonText: 'Yes, add more'} })
        dialogRef.beforeClosed().subscribe(didConfirm => {
          resolve(!didConfirm);
        });
      });

      if (!shouldAddMoreDisplays && !shouldNotAddMoreClasses) {
        return true;
      }

      return false;
    } catch(e) {
      if (typeof e === 'string') {
        this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: e, confirmButtonText: 'Ok' } })
      } else if (e instanceof Error && e.name === 'Invalid Order') {
        this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: {  title: 'Invalid Order', body: e.message, confirmButtonText: 'Ok' } })
      } else {
        this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { icon: 'error', iconColor: 'warn', body: 'An error has occurred. If the issue persists, please contact support.', confirmButtonText: 'Ok' } });
        console.error(e);
      }
      return false
    }
  }

  private buildInvalidOrderError(message: string) {
    const error = new Error(message);
    error.name = 'Invalid Order'
    return error;
  }

  isProformaLoadingChanged(isLoading: boolean) {
    this.isProformaLoading = isLoading;
  }

  tabButtonClicked(tab: number) {
    this.selectedTab = tab;
  }

  setHasChanges() {
    const proformaLines = this.proformaLineItemService.getAll();
    const stockBalanceLines = this.stockBalanceLineItemService.getAll();
    return proformaLines.map(line => line.hasChanges).concat(stockBalanceLines.map(line => line.hasChanges)).some(hasChanges => hasChanges);
  }
}
