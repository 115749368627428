import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureBoxComponent } from './components/signature-box/signature-box.component';

@NgModule({
  declarations: [
    SignatureBoxComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SignatureBoxComponent
  ]
})
export class SignatureBoxModule { }
