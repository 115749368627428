import { Injectable } from '@angular/core';
import { ORDER } from '../constants/order';
import { StockBalance } from '../interfaces/stock-balance';
import { StockBalanceCreateRequest } from '../interfaces/stock-balance-create-request';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class StockBalanceProviderService {

  constructor(
    private webClient: WebProviderService
  ) { }

  async get(orderKey: string) {
    const result = await this.webClient.get<StockBalance>(`${ORDER.API_URL}/${orderKey}/stockBalance`);
    assert(result, 'Stock balance was null for the given order.');
    return result;
  }

  async create(orderKey: string, createRequest: StockBalanceCreateRequest) {
    const result = await this.webClient.post<StockBalance>(`${ORDER.API_URL}/${orderKey}/stockBalance`, createRequest);
    assert(result, 'Created stock balance response was null');
    return result;
  }

}
