import { Injectable } from '@angular/core';
import { Order } from '../interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private order: Order | null = null;

  constructor() { }

  set(order: Order | null): void {
    this.order = order;
  }

  get() {
    return this.order;
  }
}
