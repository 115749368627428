import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EditUserDialogComponent } from 'src/app/shared/components/edit-user-dialog/edit-user-dialog.component';
import { CreateUserRequest } from 'src/app/shared/interfaces/create-user-request';
import { EditUserDialogResult } from 'src/app/shared/interfaces/edit-user-dialog-result';
import { ManagedUser } from 'src/app/shared/interfaces/managed-user';
import { UsersProviderService } from 'src/app/shared/providers/users.provider.service';
import { assert } from 'src/app/shared/utils/assert.util';

@Component({
  selector: 'app-user-list-root',
  templateUrl: './user-list-root.component.html',
  styleUrls: ['./user-list-root.component.scss']
})
export class UserListRootComponent implements OnInit {
  users: ManagedUser[] = [];
  tableColumns = ['email', 'fullName', 'role', 'dateUpdated', 'dateCreated'];
  dataSource!: MatTableDataSource<ManagedUser>;
  search: string | null = null;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isLoading = true;

  constructor(
    private router: Router,
    private usersProvider: UsersProviderService,
    private dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.users = await this.usersProvider.getAll();
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.filterDataSource();
    this.isLoading = false;
  }

  searchChanged(value: string) {
    this.search = value;
    this.filterDataSource();
  }

  filterDataSource() {
    this.dataSource = new MatTableDataSource(this.users.filter(user => {
      if (!this.search) {
        return true;
      }
      const startsWithEmail = user.email.toLowerCase().startsWith(this.search.toLowerCase());
      const starwtsWithName = user.fullName.toLowerCase().startsWith(this.search.toLowerCase());
      return startsWithEmail || starwtsWithName;
    }));

    this.dataSource.data.sort((a, b) => (new Date(`${a.dateCreated}z`) as any) - (new Date(`${b.dateCreated}z`) as any));
    this.dataSource.paginator = this.paginator;
  }

  tableRowClicked(user: ManagedUser) {
    this.router.navigateByUrl(`users/${user.id}`);
  }

  newUserButtonClicked() {
    this.dialog.open(EditUserDialogComponent).afterClosed().subscribe(async (userResult: EditUserDialogResult | undefined) => {
      if (userResult) {
        const userRequest: CreateUserRequest = {
          email: userResult.email,
          plainTextPassword: userResult.password,
          role: userResult.role,
          salesReps: userResult.salesReps.map(rep => rep.number),
          fullName: userResult.fullName
        };
        this.isLoading = true;
        await this.usersProvider.create(userRequest);
        window.location.reload();
      }
    });
  }
}
