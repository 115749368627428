import { CountryCodeInfo } from '../interfaces/country-code-info';
import { assert } from './assert.util';

const stateCodeMap: { [key: string]: string } = {
  'alabama': 'AL',
  'alaska': 'AK',
  'arizona': 'AZ',
  'arkansas': 'AR',
  'american samoa': 'AS',
  'california': 'CA',
  'colorado': 'CO',
  'connecticut': 'CT',
  'delaware': 'DE',
  'district of columbia': 'DC',
  'florida': 'FL',
  'georgia': 'GA',
  'guam': 'GU',
  'hawaii': 'HI',
  'idaho': 'ID',
  'illinois': 'IL',
  'indiana': 'IN',
  'iowa': 'IA',
  'kansas': 'KS',
  'kentucky': 'KY',
  'louisiana': 'LA',
  'maine': 'ME',
  'maryland': 'MD',
  'massachusetts': 'MA',
  'michigan': 'MI',
  'minnesota': 'MN',
  'mississippi': 'MS',
  'missouri': 'MO',
  'montana': 'MT',
  'nebraska': 'NE',
  'nevada': 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  'northern mariana islands': 'MP',
  'ohio': 'OH',
  'oklahoma': 'OK',
  'oregon': 'OR',
  'pennsylvania': 'PA',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  'tennessee': 'TN',
  'texas': 'TX',
  'trust territories': 'TT',
  'utah': 'UT',
  'vermont': 'VT',
  'virginia': 'VA',
  'washington': 'WA',
  'west virginia': 'WV',
  'wisconsin': 'WI',
  'wyoming': 'WY',
  'newfoundland and labrador': 'NL',
  'prince edward island': 'PE',
  'nova scotia': 'NS',
  'new brunswick': 'NB',
  'quebec': 'QC',
  'ontario': 'ON',
  'manitoba': 'MB',
  'saskatchewan': 'SK',
  'alberta': 'AB',
  'british columbia': 'BC',
  'yukon': 'YT',
  'northwest territories': 'NT',
  'nunavut': 'NU',
}

export function getStateCode(state: string) {
  return stateCodeMap[state.toLowerCase()] ?? null;
}

const countryProvinceMap: { [key: string]: string[] } = {
  'canada': [
    'newfoundland and labrador',
    'prince edward island',
    'nova scotia',
    'new brunswick',
    'quebec',
    'ontario',
    'manitoba',
    'saskatchewan',
    'alberta',
    'british columbia',
    'yukon',
    'northwest territories',
    'nunavut',
  ],
  'united states': [
    'alabama',
    'alaska',
    'arizona',
    'arkansas',
    'american samoa',
    'california',
    'colorado',
    'connecticut',
    'delaware',
    'district of columbia',
    'florida',
    'georgia',
    'guam',
    'hawaii',
    'idaho',
    'illinois',
    'indiana',
    'iowa',
    'kansas',
    'kentucky',
    'louisiana',
    'maine',
    'maryland',
    'massachusetts',
    'michigan',
    'minnesota',
    'mississippi',
    'missouri',
    'montana',
    'nebraska',
    'nevada',
    'new hampshire',
    'new jersey',
    'new mexico',
    'new york',
    'north carolina',
    'north dakota',
    'northern mariana islands',
    'ohio',
    'oklahoma',
    'oregon',
    'pennsylvania',
    'puerto rico',
    'rhode island',
    'south carolina',
    'south dakota',
    'tennessee',
    'texas',
    'trust territories',
    'utah',
    'vermont',
    'virginia',
    'washington',
    'west virginia',
    'wisconsin',
    'wyoming',
  ]
};

export function getProvincesWithCountry(country: string) {
  return countryProvinceMap[country.toLowerCase()] ?? null;
}

const phoneCountryCodeInfo: CountryCodeInfo[] = [
  {
    country: 'Afghanistan',
    countryISO: 'AFG',
    countryCode: 93
  },
  {
    country: 'Albania',
    countryISO: 'ALB',
    countryCode: 355
  },
  {
    country: 'Algeria',
    countryISO: 'DZA',
    countryCode: 213
  },
  {
    country: 'Andorra',
    countryISO: 'AND',
    countryCode: 376
  },
  {
    country: 'Angola',
    countryISO: 'AGO',
    countryCode: 244
  },
  {
    country: 'Antarctica',
    countryISO: 'ATA',
    countryCode: 672
  },
  {
    country: 'Argentina',
    countryISO: 'ARG',
    countryCode: 54
  },
  {
    country: 'Armenia',
    countryISO: 'ARM',
    countryCode: 374
  },
  {
    country: 'Aruba',
    countryISO: 'ABW',
    countryCode: 297
  },
  {
    country: 'Australia',
    countryISO: 'AUS',
    countryCode: 61
  },
  {
    country: 'Austria',
    countryISO: 'AUT',
    countryCode: 43
  },
  {
    country: 'Azerbaijan',
    countryISO: 'AZE',
    countryCode: 994
  },
  {
    country: 'Bahrain',
    countryISO: 'BHR',
    countryCode: 973
  },
  {
    country: 'Bangladesh',
    countryISO: 'BGD',
    countryCode: 880
  },
  {
    country: 'Belarus',
    countryISO: 'BLR',
    countryCode: 375
  },
  {
    country: 'Belgium',
    countryISO: 'BEL',
    countryCode: 32
  },
  {
    country: 'Belize',
    countryISO: 'BLZ',
    countryCode: 501
  },
  {
    country: 'Benin',
    countryISO: 'BEN',
    countryCode: 229
  },
  {
    country: 'Bhutan',
    countryISO: 'BTN',
    countryCode: 975
  },
  {
    country: 'Bolivia',
    countryISO: 'BOL',
    countryCode: 591
  },
  {
    country: 'Bosnia and Herzegovina',
    countryISO: 'BIH',
    countryCode: 387
  },
  {
    country: 'Botswana',
    countryISO: 'BWA',
    countryCode: 267
  },
  {
    country: 'Brazil',
    countryISO: 'BRA',
    countryCode: 55
  },
  {
    country: 'British Indian Ocean Territory',
    countryISO: 'IOT',
    countryCode: 246
  },
  {
    country: 'Brunei',
    countryISO: 'BRN',
    countryCode: 673
  },
  {
    country: 'Bulgaria',
    countryISO: 'BGR',
    countryCode: 359
  },
  {
    country: 'Burkina Faso',
    countryISO: 'BFA',
    countryCode: 226
  },
  {
    country: 'Burundi',
    countryISO: 'BDI',
    countryCode: 257
  },
  {
    country: 'Cambodia',
    countryISO: 'KHM',
    countryCode: 855
  },
  {
    country: 'Cameroon',
    countryISO: 'CMR',
    countryCode: 237
  },
  {
    country: 'Cape Verde',
    countryISO: 'CPV',
    countryCode: 238
  },
  {
    country: 'Central African Republic',
    countryISO: 'CAF',
    countryCode: 236
  },
  {
    country: 'Chad',
    countryISO: 'TCD',
    countryCode: 235
  },
  {
    country: 'Chile',
    countryISO: 'CHL',
    countryCode: 56
  },
  {
    country: 'China',
    countryISO: 'CHN',
    countryCode: 86
  },
  {
    country: 'Colombia',
    countryISO: 'COL',
    countryCode: 57
  },
  {
    country: 'Comoros',
    countryISO: 'COM',
    countryCode: 269
  },
  {
    country: 'Cook Islands',
    countryISO: 'COK',
    countryCode: 682
  },
  {
    country: 'Costa Rica',
    countryISO: 'CRI',
    countryCode: 506
  },
  {
    country: 'Croatia',
    countryISO: 'HRV',
    countryCode: 385
  },
  {
    country: 'Cuba',
    countryISO: 'CUB',
    countryCode: 53
  },
  {
    country: 'Cyprus',
    countryISO: 'CYP',
    countryCode: 357
  },
  {
    country: 'Czech Republic',
    countryISO: 'CZE',
    countryCode: 420
  },
  {
    country: 'Democratic Republic of the Congo',
    countryISO: 'COD',
    countryCode: 243
  },
  {
    country: 'Denmark',
    countryISO: 'DNK',
    countryCode: 45
  },
  {
    country: 'Djibouti',
    countryISO: 'DJI',
    countryCode: 253
  },
  {
    country: 'East Timor',
    countryISO: 'TLS',
    countryCode: 670
  },
  {
    country: 'Ecuador',
    countryISO: 'ECU',
    countryCode: 593
  },
  {
    country: 'Egypt',
    countryISO: 'EGY',
    countryCode: 20
  },
  {
    country: 'El Salvador',
    countryISO: 'SLV',
    countryCode: 503
  },
  {
    country: 'Equatorial Guinea',
    countryISO: 'GNQ',
    countryCode: 240
  },
  {
    country: 'Eritrea',
    countryISO: 'ERI',
    countryCode: 291
  },
  {
    country: 'Estonia',
    countryISO: 'EST',
    countryCode: 372
  },
  {
    country: 'Ethiopia',
    countryISO: 'ETH',
    countryCode: 251
  },
  {
    country: 'Falkland Islands',
    countryISO: 'FLK',
    countryCode: 500
  },
  {
    country: 'Faroe Islands',
    countryISO: 'FRO',
    countryCode: 298
  },
  {
    country: 'Fiji',
    countryISO: 'FJI',
    countryCode: 679
  },
  {
    country: 'Finland',
    countryISO: 'FIN',
    countryCode: 358
  },
  {
    country: 'France',
    countryISO: 'FRA',
    countryCode: 33
  },
  {
    country: 'French Polynesia',
    countryISO: 'PYF',
    countryCode: 689
  },
  {
    country: 'Gabon',
    countryISO: 'GAB',
    countryCode: 241
  },
  {
    country: 'Gambia',
    countryISO: 'GMB',
    countryCode: 220
  },
  {
    country: 'Georgia',
    countryISO: 'GEO',
    countryCode: 995
  },
  {
    country: 'Germany',
    countryISO: 'DEU',
    countryCode: 49
  },
  {
    country: 'Ghana',
    countryISO: 'GHA',
    countryCode: 233
  },
  {
    country: 'Gibraltar',
    countryISO: 'GIB',
    countryCode: 350
  },
  {
    country: 'Greece',
    countryISO: 'GRC',
    countryCode: 30
  },
  {
    country: 'Greenland',
    countryISO: 'GRL',
    countryCode: 299
  },
  {
    country: 'Guatemala',
    countryISO: 'GTM',
    countryCode: 502
  },
  {
    country: 'Guinea',
    countryISO: 'GIN',
    countryCode: 224
  },
  {
    country: 'Guinea-Bissau',
    countryISO: 'GNB',
    countryCode: 245
  },
  {
    country: 'Guyana',
    countryISO: 'GUY',
    countryCode: 592
  },
  {
    country: 'Haiti',
    countryISO: 'HTI',
    countryCode: 509
  },
  {
    country: 'Honduras',
    countryISO: 'HND',
    countryCode: 504
  },
  {
    country: 'Hong Kong',
    countryISO: 'HKG',
    countryCode: 852
  },
  {
    country: 'Hungary',
    countryISO: 'HUN',
    countryCode: 36
  },
  {
    country: 'Iceland',
    countryISO: 'ISL',
    countryCode: 354
  },
  {
    country: 'India',
    countryISO: 'IND',
    countryCode: 91
  },
  {
    country: 'Indonesia',
    countryISO: 'IDN',
    countryCode: 62
  },
  {
    country: 'Iran',
    countryISO: 'IRN',
    countryCode: 98
  },
  {
    country: 'Iraq',
    countryISO: 'IRQ',
    countryCode: 964
  },
  {
    country: 'Ireland',
    countryISO: 'IRL',
    countryCode: 353
  },
  {
    country: 'Israel',
    countryISO: 'ISR',
    countryCode: 972
  },
  {
    country: 'Italy',
    countryISO: 'ITA',
    countryCode: 39
  },
  {
    country: 'Ivory Coast',
    countryISO: 'CIV',
    countryCode: 225
  },
  {
    country: 'Japan',
    countryISO: 'JPN',
    countryCode: 81
  },
  {
    country: 'Jordan',
    countryISO: 'JOR',
    countryCode: 962
  },
  {
    country: 'Russia / Kazakhstan',
    countryISO: 'RUS / KAZ',
    countryCode: 7
  },
  {
    country: 'Kenya',
    countryISO: 'KEN',
    countryCode: 254
  },
  {
    country: 'Kiribati',
    countryISO: 'KIR',
    countryCode: 686
  },
  {
    country: 'Kosovo',
    countryISO: 'XKX',
    countryCode: 383
  },
  {
    country: 'Kuwait',
    countryISO: 'KWT',
    countryCode: 965
  },
  {
    country: 'Kyrgyzstan',
    countryISO: 'KGZ',
    countryCode: 996
  },
  {
    country: 'Laos',
    countryISO: 'LAO',
    countryCode: 856
  },
  {
    country: 'Latvia',
    countryISO: 'LVA',
    countryCode: 371
  },
  {
    country: 'Lebanon',
    countryISO: 'LBN',
    countryCode: 961
  },
  {
    country: 'Lesotho',
    countryISO: 'LSO',
    countryCode: 266
  },
  {
    country: 'Liberia',
    countryISO: 'LBR',
    countryCode: 231
  },
  {
    country: 'Libya',
    countryISO: 'LBY',
    countryCode: 218
  },
  {
    country: 'Liechtenstein',
    countryISO: 'LIE',
    countryCode: 423
  },
  {
    country: 'Lithuania',
    countryISO: 'LTU',
    countryCode: 370
  },
  {
    country: 'Luxembourg',
    countryISO: 'LUX',
    countryCode: 352
  },
  {
    country: 'Macau',
    countryISO: 'MAC',
    countryCode: 853
  },
  {
    country: 'Macedonia',
    countryISO: 'MKD',
    countryCode: 389
  },
  {
    country: 'Madagascar',
    countryISO: 'MDG',
    countryCode: 261
  },
  {
    country: 'Malawi',
    countryISO: 'MWI',
    countryCode: 265
  },
  {
    country: 'Malaysia',
    countryISO: 'MYS',
    countryCode: 60
  },
  {
    country: 'Maldives',
    countryISO: 'MDV',
    countryCode: 960
  },
  {
    country: 'Mali',
    countryISO: 'MLI',
    countryCode: 223
  },
  {
    country: 'Malta',
    countryISO: 'MLT',
    countryCode: 356
  },
  {
    country: 'Marshall Islands',
    countryISO: 'MHL',
    countryCode: 692
  },
  {
    country: 'Mauritania',
    countryISO: 'MRT',
    countryCode: 222
  },
  {
    country: 'Mauritius',
    countryISO: 'MUS',
    countryCode: 230
  },
  {
    country: 'Mexico',
    countryISO: 'MEX',
    countryCode: 52
  },
  {
    country: 'Micronesia',
    countryISO: 'FSM',
    countryCode: 691
  },
  {
    country: 'Moldova',
    countryISO: 'MDA',
    countryCode: 373
  },
  {
    country: 'Monaco',
    countryISO: 'MCO',
    countryCode: 377
  },
  {
    country: 'Mongolia',
    countryISO: 'MNG',
    countryCode: 976
  },
  {
    country: 'Montenegro',
    countryISO: 'MNE',
    countryCode: 382
  },
  {
    country: 'Morocco / Western Sahara',
    countryISO: 'MAR / ESH',
    countryCode: 212
  },
  {
    country: 'Mozambique',
    countryISO: 'MOZ',
    countryCode: 258
  },
  {
    country: 'Myanmar',
    countryISO: 'MMR',
    countryCode: 95
  },
  {
    country: 'Namibia',
    countryISO: 'NAM',
    countryCode: 264
  },
  {
    country: 'Nauru',
    countryISO: 'NRU',
    countryCode: 674
  },
  {
    country: 'Nepal',
    countryISO: 'NPL',
    countryCode: 977
  },
  {
    country: 'Netherlands',
    countryISO: 'NLD',
    countryCode: 31
  },
  {
    country: 'Curacao / Netherlands Antilles',
    countryISO: 'CUW / ANT',
    countryCode: 599
  },
  {
    country: 'New Caledonia',
    countryISO: 'NCL',
    countryCode: 687
  },
  {
    country: 'New Zealand / Pitcairn',
    countryISO: 'NZL / PCN',
    countryCode: 64
  },
  {
    country: 'Nicaragua',
    countryISO: 'NIC',
    countryCode: 505
  },
  {
    country: 'Niger',
    countryISO: 'NER',
    countryCode: 227
  },
  {
    country: 'Nigeria',
    countryISO: 'NGA',
    countryCode: 234
  },
  {
    country: 'Niue',
    countryISO: 'NIU',
    countryCode: 683
  },
  {
    country: 'North Korea',
    countryISO: 'PRK',
    countryCode: 850
  },
  {
    country: 'Norway',
    countryISO: 'NOR',
    countryCode: 47
  },
  {
    country: 'Oman',
    countryISO: 'OMN',
    countryCode: 968
  },
  {
    country: 'Pakistan',
    countryISO: 'PAK',
    countryCode: 92
  },
  {
    country: 'Palau',
    countryISO: 'PLW',
    countryCode: 680
  },
  {
    country: 'Palestine',
    countryISO: 'PSE',
    countryCode: 970
  },
  {
    country: 'Panama',
    countryISO: 'PAN',
    countryCode: 507
  },
  {
    country: 'Papua New Guinea',
    countryISO: 'PNG',
    countryCode: 675
  },
  {
    country: 'Paraguay',
    countryISO: 'PRY',
    countryCode: 595
  },
  {
    country: 'Peru',
    countryISO: 'PER',
    countryCode: 51
  },
  {
    country: 'Philippines',
    countryISO: 'PHL',
    countryCode: 63
  },
  {
    country: 'Poland',
    countryISO: 'POL',
    countryCode: 48
  },
  {
    country: 'Portugal',
    countryISO: 'PRT',
    countryCode: 351
  },
  {
    country: 'Qatar',
    countryISO: 'QAT',
    countryCode: 974
  },
  {
    country: 'Republic of the Congo',
    countryISO: 'COG',
    countryCode: 242
  },
  {
    country: 'Reunion / Mayotte',
    countryISO: 'REU / MYT',
    countryCode: 262
  },
  {
    country: 'Romania',
    countryISO: 'ROU',
    countryCode: 40
  },
  {
    country: 'Rwanda',
    countryISO: 'RWA',
    countryCode: 250
  },
  {
    country: 'ST Barthelemy / ST Martin',
    countryISO: 'BLM / MAF',
    countryCode: 590
  },
  {
    country: 'Saint Helena',
    countryISO: 'SHN',
    countryCode: 290
  },
  {
    country: 'Saint Pierre and Miquelon',
    countryISO: 'SPM',
    countryCode: 508
  },
  {
    country: 'Samoa',
    countryISO: 'WSM',
    countryCode: 685
  },
  {
    country: 'San Marino',
    countryISO: 'SMR',
    countryCode: 378
  },
  {
    country: 'Sao Tome and Principe',
    countryISO: 'STP',
    countryCode: 239
  },
  {
    country: 'Saudi Arabia',
    countryISO: 'SAU',
    countryCode: 966
  },
  {
    country: 'Senegal',
    countryISO: 'SEN',
    countryCode: 221
  },
  {
    country: 'Serbia',
    countryISO: 'SRB',
    countryCode: 381
  },
  {
    country: 'Seychelles',
    countryISO: 'SYC',
    countryCode: 248
  },
  {
    country: 'Sierra Leone',
    countryISO: 'SLE',
    countryCode: 232
  },
  {
    country: 'Singapore',
    countryISO: 'SGP',
    countryCode: 65
  },
  {
    country: 'Slovakia',
    countryISO: 'SVK',
    countryCode: 421
  },
  {
    country: 'Slovenia',
    countryISO: 'SVN',
    countryCode: 386
  },
  {
    country: 'Solomon Islands',
    countryISO: 'SLB',
    countryCode: 677
  },
  {
    country: 'Somalia',
    countryISO: 'SOM',
    countryCode: 252
  },
  {
    country: 'South Africa',
    countryISO: 'ZAF',
    countryCode: 27
  },
  {
    country: 'South Korea',
    countryISO: 'KOR',
    countryCode: 82
  },
  {
    country: 'South Sudan',
    countryISO: 'SSD',
    countryCode: 211
  },
  {
    country: 'Spain',
    countryISO: 'ESP',
    countryCode: 34
  },
  {
    country: 'Sri Lanka',
    countryISO: 'LKA',
    countryCode: 94
  },
  {
    country: 'Sudan',
    countryISO: 'SDN',
    countryCode: 249
  },
  {
    country: 'Suriname',
    countryISO: 'SUR',
    countryCode: 597
  },
  {
    country: 'Swaziland',
    countryISO: 'SWZ',
    countryCode: 268
  },
  {
    country: 'Sweden',
    countryISO: 'SWE',
    countryCode: 46
  },
  {
    country: 'Switzerland',
    countryISO: 'CHE',
    countryCode: 41
  },
  {
    country: 'Syria',
    countryISO: 'SYR',
    countryCode: 963
  },
  {
    country: 'Taiwan',
    countryISO: 'TWN',
    countryCode: 886
  },
  {
    country: 'Tajikistan',
    countryISO: 'TJK',
    countryCode: 992
  },
  {
    country: 'Tanzania',
    countryISO: 'TZA',
    countryCode: 255
  },
  {
    country: 'Thailand',
    countryISO: 'THA',
    countryCode: 66
  },
  {
    country: 'Togo',
    countryISO: 'TGO',
    countryCode: 228
  },
  {
    country: 'Tokelau',
    countryISO: 'TKL',
    countryCode: 690
  },
  {
    country: 'Tonga',
    countryISO: 'TON',
    countryCode: 676
  },
  {
    country: 'Tunisia',
    countryISO: 'TUN',
    countryCode: 216
  },
  {
    country: 'Turkey',
    countryISO: 'TUR',
    countryCode: 90
  },
  {
    country: 'Turkmenistan',
    countryISO: 'TKM',
    countryCode: 993
  },
  {
    country: 'Tuvalu',
    countryISO: 'TUV',
    countryCode: 688
  },
  {
    country: 'Uganda',
    countryISO: 'UGA',
    countryCode: 256
  },
  {
    country: 'Ukraine',
    countryISO: 'UKR',
    countryCode: 380
  },
  {
    country: 'United Arab Emirates',
    countryISO: 'ARE',
    countryCode: 971
  },
  {
    country: 'United Kingdom / Outlying Islands',
    countryISO: 'GBR / Other',
    countryCode: 44
  },
  {
    country: 'Uruguay',
    countryISO: 'URY',
    countryCode: 598
  },
  {
    country: 'Uzbekistan',
    countryISO: 'UZB',
    countryCode: 998
  },
  {
    country: 'Vanuatu',
    countryISO: 'VUT',
    countryCode: 678
  },
  {
    country: 'Vatican',
    countryISO: 'VAT',
    countryCode: 379
  },
  {
    country: 'Venezuela',
    countryISO: 'VEN',
    countryCode: 58
  },
  {
    country: 'Vietnam',
    countryISO: 'VNM',
    countryCode: 84
  },
  {
    country: 'Wallis and Futuna',
    countryISO: 'WLF',
    countryCode: 681
  },
  {
    country: 'Yemen',
    countryISO: 'YEM',
    countryCode: 967
  },
  {
    country: 'Zambia',
    countryISO: 'ZMB',
    countryCode: 260
  },
  {
    country: 'Zimbabwe',
    countryISO: 'ZWE',
    countryCode: 263
  },
  {
    country: 'North American / Caribbean',
    countryISO: 'NA / CAR',
    countryCode: 1
  }
];

export function getAllPhoneCountryCodeInfo() {
  return phoneCountryCodeInfo;
}

export function isCountryCodeValid(countryCode: number) {
  try {
    return !!getPhoneCountryCodeInfoWithCountryCode(countryCode);
  } catch {
    return false;
  }
}

export function getPhoneCountryCodeInfoWithCountry(country: string) {
  const matchingInfo = phoneCountryCodeInfo.find(codeInfo => codeInfo.country === country);
  assert(matchingInfo, 'No country code info matching the given country.')
  return matchingInfo;
}

export function getPhoneCountryCodeInfoWithCountryISO(countryISO: string) {
  const matchingInfo = phoneCountryCodeInfo.find(codeInfo => codeInfo.countryISO === countryISO);
  assert(matchingInfo, 'No country code info matching the given country ISO.')
  return matchingInfo;
}

export function getPhoneCountryCodeInfoWithCountryCode(countryCode: number) {
  const matchingInfo = phoneCountryCodeInfo.find(codeInfo => codeInfo.countryCode === countryCode);
  assert(matchingInfo, 'No country code info matching the given country code.')
  return matchingInfo;
}

const countryISO2Map = [
  {
    country: "Afghanistan",
    iso2: "AF"
  },
  {
    country: "Åland Islands",
    iso2: "AX"
  },
  {
    country: "Albania",
    iso2: "AL"
  },
  {
    country: "Algeria",
    iso2: "DZ"
  },
  {
    country: "American Samoa",
    iso2: "AS"
  },
  {
    country: "Andorra",
    iso2: "AD"
  },
  {
    country: "Angola",
    iso2: "AO"
  },
  {
    country: "Anguilla",
    iso2: "AI"
  },
  {
    country: "Antarctica",
    iso2: "AQ"
  },
  {
    country: "Antigua and Barbuda",
    iso2: "AG"
  },
  {
    country: "Argentina",
    iso2: "AR"
  },
  {
    country: "Armenia",
    iso2: "AM"
  },
  {
    country: "Aruba",
    iso2: "AW"
  },
  {
    country: "Australia",
    iso2: "AU"
  },
  {
    country: "Austria",
    iso2: "AT"
  },
  {
    country: "Azerbaijan",
    iso2: "AZ"
  },
  {
    country: "Bahamas",
    iso2: "BS"
  },
  {
    country: "Bahrain",
    iso2: "BH"
  },
  {
    country: "Bangladesh",
    iso2: "BD"
  },
  {
    country: "Barbados",
    iso2: "BB"
  },
  {
    country: "Belarus",
    iso2: "BY"
  },
  {
    country: "Belgium",
    iso2: "BE"
  },
  {
    country: "Belize",
    iso2: "BZ"
  },
  {
    country: "Benin",
    iso2: "BJ"
  },
  {
    country: "Bermuda",
    iso2: "BM"
  },
  {
    country: "Bhutan",
    iso2: "BT"
  },
  {
    country: "Bolivia (Plurinational State of)",
    iso2: "BO"
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    iso2: "BQ"
  },
  {
    country: "Bosnia and Herzegovina",
    iso2: "BA"
  },
  {
    country: "Botswana",
    iso2: "BW"
  },
  {
    country: "Bouvet Island",
    iso2: "BV"
  },
  {
    country: "Brazil",
    iso2: "BR"
  },
  {
    country: "British Indian Ocean Territory",
    iso2: "IO"
  },
  {
    country: "Brunei Darussalam",
    iso2: "BN"
  },
  {
    country: "Bulgaria",
    iso2: "BG"
  },
  {
    country: "Burkina Faso",
    iso2: "BF"
  },
  {
    country: "Burundi",
    iso2: "BI"
  },
  {
    country: "Cabo Verde",
    iso2: "CV"
  },
  {
    country: "Cambodia",
    iso2: "KH"
  },
  {
    country: "Cameroon",
    iso2: "CM"
  },
  {
    country: "Canada",
    iso2: "CA"
  },
  {
    country: "Cayman Islands",
    iso2: "KY"
  },
  {
    country: "Central African Republic",
    iso2: "CF"
  },
  {
    country: "Chad",
    iso2: "TD"
  },
  {
    country: "Chile",
    iso2: "CL"
  },
  {
    country: "China",
    iso2: "CN"
  },
  {
    country: "Christmas Island",
    iso2: "CX"
  },
  {
    country: "Cocos (Keeling) Islands",
    iso2: "CC"
  },
  {
    country: "Colombia",
    iso2: "CO"
  },
  {
    country: "Comoros",
    iso2: "KM"
  },
  {
    country: "Congo",
    iso2: "CG"
  },
  {
    country: "Congo, Democratic Republic of the",
    iso2: "CD"
  },
  {
    country: "Cook Islands",
    iso2: "CK"
  },
  {
    country: "Costa Rica",
    iso2: "CR"
  },
  {
    country: "Côte d'Ivoire",
    iso2: "CI"
  },
  {
    country: "Croatia",
    iso2: "HR"
  },
  {
    country: "Cuba",
    iso2: "CU"
  },
  {
    country: "Curaçao",
    iso2: "CW"
  },
  {
    country: "Cyprus",
    iso2: "CY"
  },
  {
    country: "Czechia",
    iso2: "CZ"
  },
  {
    country: "Denmark",
    iso2: "DK"
  },
  {
    country: "Djibouti",
    iso2: "DJ"
  },
  {
    country: "Dominica",
    iso2: "DM"
  },
  {
    country: "Dominican Republic",
    iso2: "DO"
  },
  {
    country: "Ecuador",
    iso2: "EC"
  },
  {
    country: "Egypt",
    iso2: "EG"
  },
  {
    country: "El Salvador",
    iso2: "SV"
  },
  {
    country: "Equatorial Guinea",
    iso2: "GQ"
  },
  {
    country: "Eritrea",
    iso2: "ER"
  },
  {
    country: "Estonia",
    iso2: "EE"
  },
  {
    country: "Eswatini",
    iso2: "SZ"
  },
  {
    country: "Ethiopia",
    iso2: "ET"
  },
  {
    country: "Falkland Islands (Malvinas)",
    iso2: "FK"
  },
  {
    country: "Faroe Islands",
    iso2: "FO"
  },
  {
    country: "Fiji",
    iso2: "FJ"
  },
  {
    country: "Finland",
    iso2: "FI"
  },
  {
    country: "France",
    iso2: "FR"
  },
  {
    country: "French Guiana",
    iso2: "GF"
  },
  {
    country: "French Polynesia",
    iso2: "PF"
  },
  {
    country: "French Southern Territories",
    iso2: "TF"
  },
  {
    country: "Gabon",
    iso2: "GA"
  },
  {
    country: "Gambia",
    iso2: "GM"
  },
  {
    country: "Georgia",
    iso2: "GE"
  },
  {
    country: "Germany",
    iso2: "DE"
  },
  {
    country: "Ghana",
    iso2: "GH"
  },
  {
    country: "Gibraltar",
    iso2: "GI"
  },
  {
    country: "Greece",
    iso2: "GR"
  },
  {
    country: "Greenland",
    iso2: "GL"
  },
  {
    country: "Grenada",
    iso2: "GD"
  },
  {
    country: "Guadeloupe",
    iso2: "GP"
  },
  {
    country: "Guam",
    iso2: "GU"
  },
  {
    country: "Guatemala",
    iso2: "GT"
  },
  {
    country: "Guernsey",
    iso2: "GG"
  },
  {
    country: "Guinea",
    iso2: "GN"
  },
  {
    country: "Guinea-Bissau",
    iso2: "GW"
  },
  {
    country: "Guyana",
    iso2: "GY"
  },
  {
    country: "Haiti",
    iso2: "HT"
  },
  {
    country: "Heard Island and McDonald Islands",
    iso2: "HM"
  },
  {
    country: "Holy See",
    iso2: "VA"
  },
  {
    country: "Honduras",
    iso2: "HN"
  },
  {
    country: "Hong Kong",
    iso2: "HK"
  },
  {
    country: "Hungary",
    iso2: "HU"
  },
  {
    country: "Iceland",
    iso2: "IS"
  },
  {
    country: "India",
    iso2: "IN"
  },
  {
    country: "Indonesia",
    iso2: "ID"
  },
  {
    country: "Iran (Islamic Republic of)",
    iso2: "IR"
  },
  {
    country: "Iraq",
    iso2: "IQ"
  },
  {
    country: "Ireland",
    iso2: "IE"
  },
  {
    country: "Isle of Man",
    iso2: "IM"
  },
  {
    country: "Israel",
    iso2: "IL"
  },
  {
    country: "Italy",
    iso2: "IT"
  },
  {
    country: "Jamaica",
    iso2: "JM"
  },
  {
    country: "Japan",
    iso2: "JP"
  },
  {
    country: "Jersey",
    iso2: "JE"
  },
  {
    country: "Jordan",
    iso2: "JO"
  },
  {
    country: "Kazakhstan",
    iso2: "KZ"
  },
  {
    country: "Kenya",
    iso2: "KE"
  },
  {
    country: "Kiribati",
    iso2: "KI"
  },
  {
    country: "Korea (Democratic People's Republic of)",
    iso2: "KP"
  },
  {
    country: "Korea, Republic of",
    iso2: "KR"
  },
  {
    country: "Kuwait",
    iso2: "KW"
  },
  {
    country: "Kyrgyzstan",
    iso2: "KG"
  },
  {
    country: "Lao People's Democratic Republic",
    iso2: "LA"
  },
  {
    country: "Latvia",
    iso2: "LV"
  },
  {
    country: "Lebanon",
    iso2: "LB"
  },
  {
    country: "Lesotho",
    iso2: "LS"
  },
  {
    country: "Liberia",
    iso2: "LR"
  },
  {
    country: "Libya",
    iso2: "LY"
  },
  {
    country: "Liechtenstein",
    iso2: "LI"
  },
  {
    country: "Lithuania",
    iso2: "LT"
  },
  {
    country: "Luxembourg",
    iso2: "LU"
  },
  {
    country: "Macao",
    iso2: "MO"
  },
  {
    country: "Madagascar",
    iso2: "MG"
  },
  {
    country: "Malawi",
    iso2: "MW"
  },
  {
    country: "Malaysia",
    iso2: "MY"
  },
  {
    country: "Maldives",
    iso2: "MV"
  },
  {
    country: "Mali",
    iso2: "ML"
  },
  {
    country: "Malta",
    iso2: "MT"
  },
  {
    country: "Marshall Islands",
    iso2: "MH"
  },
  {
    country: "Martinique",
    iso2: "MQ"
  },
  {
    country: "Mauritania",
    iso2: "MR"
  },
  {
    country: "Mauritius",
    iso2: "MU"
  },
  {
    country: "Mayotte",
    iso2: "YT"
  },
  {
    country: "Mexico",
    iso2: "MX"
  },
  {
    country: "Micronesia (Federated States of)",
    iso2: "FM"
  },
  {
    country: "Moldova, Republic of",
    iso2: "MD"
  },
  {
    country: "Monaco",
    iso2: "MC"
  },
  {
    country: "Mongolia",
    iso2: "MN"
  },
  {
    country: "Montenegro",
    iso2: "ME"
  },
  {
    country: "Montserrat",
    iso2: "MS"
  },
  {
    country: "Morocco",
    iso2: "MA"
  },
  {
    country: "Mozambique",
    iso2: "MZ"
  },
  {
    country: "Myanmar",
    iso2: "MM"
  },
  {
    country: "Namibia",
    iso2: "NA"
  },
  {
    country: "Nauru",
    iso2: "NR"
  },
  {
    country: "Nepal",
    iso2: "NP"
  },
  {
    country: "Netherlands",
    iso2: "NL"
  },
  {
    country: "New Caledonia",
    iso2: "NC"
  },
  {
    country: "New Zealand",
    iso2: "NZ"
  },
  {
    country: "Nicaragua",
    iso2: "NI"
  },
  {
    country: "Niger",
    iso2: "NE"
  },
  {
    country: "Nigeria",
    iso2: "NG"
  },
  {
    country: "Niue",
    iso2: "NU"
  },
  {
    country: "Norfolk Island",
    iso2: "NF"
  },
  {
    country: "North Macedonia",
    iso2: "MK"
  },
  {
    country: "Northern Mariana Islands",
    iso2: "MP"
  },
  {
    country: "Norway",
    iso2: "NO"
  },
  {
    country: "Oman",
    iso2: "OM"
  },
  {
    country: "Pakistan",
    iso2: "PK"
  },
  {
    country: "Palau",
    iso2: "PW"
  },
  {
    country: "Palestine, State of",
    iso2: "PS"
  },
  {
    country: "Panama",
    iso2: "PA"
  },
  {
    country: "Papua New Guinea",
    iso2: "PG"
  },
  {
    country: "Paraguay",
    iso2: "PY"
  },
  {
    country: "Peru",
    iso2: "PE"
  },
  {
    country: "Philippines",
    iso2: "PH"
  },
  {
    country: "Pitcairn",
    iso2: "PN"
  },
  {
    country: "Poland",
    iso2: "PL"
  },
  {
    country: "Portugal",
    iso2: "PT"
  },
  {
    country: "Puerto Rico",
    iso2: "PR"
  },
  {
    country: "Qatar",
    iso2: "QA"
  },
  {
    country: "Réunion",
    iso2: "RE"
  },
  {
    country: "Romania",
    iso2: "RO"
  },
  {
    country: "Russian Federation",
    iso2: "RU"
  },
  {
    country: "Rwanda",
    iso2: "RW"
  },
  {
    country: "Saint Barthélemy",
    iso2: "BL"
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    iso2: "SH"
  },
  {
    country: "Saint Kitts and Nevis",
    iso2: "KN"
  },
  {
    country: "Saint Lucia",
    iso2: "LC"
  },
  {
    country: "Saint Martin (French part)",
    iso2: "MF"
  },
  {
    country: "Saint Pierre and Miquelon",
    iso2: "PM"
  },
  {
    country: "Saint Vincent and the Grenadines",
    iso2: "VC"
  },
  {
    country: "Samoa",
    iso2: "WS"
  },
  {
    country: "San Marino",
    iso2: "SM"
  },
  {
    country: "Sao Tome and Principe",
    iso2: "ST"
  },
  {
    country: "Saudi Arabia",
    iso2: "SA"
  },
  {
    country: "Senegal",
    iso2: "SN"
  },
  {
    country: "Serbia",
    iso2: "RS"
  },
  {
    country: "Seychelles",
    iso2: "SC"
  },
  {
    country: "Sierra Leone",
    iso2: "SL"
  },
  {
    country: "Singapore",
    iso2: "SG"
  },
  {
    country: "Sint Maarten (Dutch part)",
    iso2: "SX"
  },
  {
    country: "Slovakia",
    iso2: "SK"
  },
  {
    country: "Slovenia",
    iso2: "SI"
  },
  {
    country: "Solomon Islands",
    iso2: "SB"
  },
  {
    country: "Somalia",
    iso2: "SO"
  },
  {
    country: "South Africa",
    iso2: "ZA"
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    iso2: "GS"
  },
  {
    country: "South Sudan",
    iso2: "SS"
  },
  {
    country: "Spain",
    iso2: "ES"
  },
  {
    country: 'United States',
    iso2: 'US'
  }
];

export function getISO2(country: string) {
  const matchingPair = countryISO2Map.find(pair => pair.country.toLowerCase() === country.toLowerCase());
  return matchingPair?.iso2 ?? null;
}

export function getCountryFromISO2(iso2: string) {
  const matchingPair = countryISO2Map.find(pair => pair.iso2.toLowerCase() === iso2.toLowerCase());
  return matchingPair?.country ?? null;
}
