import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { AsyncAutoCompleteComponent } from './components/async-auto-complete/async-auto-complete.component';



@NgModule({
  declarations: [
    AutoCompleteComponent,
    AsyncAutoCompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedAngularMaterialModule
  ],
  exports: [
    AutoCompleteComponent,
    AsyncAutoCompleteComponent
  ]
})
export class AutoCompleteModule { }
