import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer } from '../../interfaces/customer';
import { NewCustomer } from '../../interfaces/new-customer';
import { Address } from '../../interfaces/address';
import { NewCustomerAddress } from '../../interfaces/new-customer-address';
import { doesNewCustomerHaveConflictingAddress, doesNewCustomerHaveConflictingRequestedTerms, doesNewCustomerHaveConflictingStoreName } from '../../utils/new-customer.util';

@Component({
  selector: 'app-new-customer-diff-dialog',
  templateUrl: './new-customer-diff-dialog.component.html',
  styleUrls: ['./new-customer-diff-dialog.component.scss']
})
export class NewCustomerDiffDialogComponent implements OnInit {
  get existingCustomer() {
    return this.customers.existing;
  }

  get replacingCustomer() {
    return this.customers.replacing;
  }

  get shouldShowNameDiff() {
    return doesNewCustomerHaveConflictingStoreName(this.existingCustomer, this.replacingCustomer);
  }

  get shouldShowTermsDiff() {
    return doesNewCustomerHaveConflictingRequestedTerms(this.existingCustomer, this.replacingCustomer);
  }

  get shouldShowAddressDiff() {
    return doesNewCustomerHaveConflictingAddress(this.existingCustomer, this.replacingCustomer);
  }

  constructor(
    public dialogRef: MatDialogRef<NewCustomerDiffDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public customers: { existing: NewCustomer, replacing: Customer }
  ) { }

  ngOnInit(): void {
  }

  buildAddressToShow(address: NewCustomerAddress | Address) {
    const addressToShow: Address = {
      id: null,
      company: '',
      addressee: '',
      attention: '',
      street1: address.street1 ?? '',
      street2: address.street2 ?? '',
      city: address.city ?? '',
      stateCode: address.stateCode ?? '',
      postalCode: address.postalCode ?? '',
      country: address.country ?? ''
    };
    return addressToShow;
  }
}
