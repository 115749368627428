<mat-card>
  <mat-card-content>
    <table class="SummaryTable">
      <tr>
        <td>Order Qty</td>
        <td>{{ orderQuantity }}</td>
      </tr>
      <tr>
        <td>Order Total</td>
        <td>{{ afterDiscountTotal | money }}</td>
      </tr>
      <tr *ngIf="hasStockBalanceLines">
        <td>Stock Bal. Qty</td>
        <td>{{ stockBalanceQuantity }}</td>
      </tr>
      <tr *ngIf="hasStockBalanceLines" [ngClass]="stockBalanceColor" class="StockBalanceTotal">
        <td>Stock Bal. Total</td>
        <td class="Value">{{ stockBalanceTotal | money }}</td>
      </tr>
      <tr>
        <td>Shipping</td>
        <td *ngIf="shippingCost">{{ shippingCost | money }}</td>
        <td *ngIf="!shippingCost">TBD</td>
      </tr>
      <tr>
        <td>Net Total</td>
        <td>{{ netOrderTotal | money }}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
