import { Component, Input, OnInit } from '@angular/core';
import { SummaryCollection } from '../../interfaces/summary-collection';
import { getEligibleLineItems, getIneligibleLineItems, getTotal, getTotalQuantity } from '../../utils/order.util';

@Component({
  selector: 'app-collection-summary[summaryCollection]',
  templateUrl: './collection-summary.component.html',
  styleUrls: ['./collection-summary.component.scss']
})
export class CollectionSummaryComponent implements OnInit {
  @Input() summaryCollection!: SummaryCollection;
  @Input() restrictionReason: string | null = null;

  get proformaQuantity(): number {
    return getTotalQuantity(this.summaryCollection.proformaItems);
  }

  get proformaTotal(): number {
    return getTotal(this.summaryCollection.proformaItems);
  }

  get stockBalanceEligibleQuantity(): number {
    return getTotalQuantity(getEligibleLineItems(this.summaryCollection.stockBalanceItems));
  }

  get stockBalanceEligibleTotal(): number {
    return getTotal(getEligibleLineItems(this.summaryCollection.stockBalanceItems));
  }

  get stockBalanceIneligibleQuantity(): number {
    return getTotalQuantity(getIneligibleLineItems(this.summaryCollection.stockBalanceItems));
  }

  get stockBalanceIneligibleTotal(): number {
    return getTotal(getIneligibleLineItems(this.summaryCollection.stockBalanceItems));
  }

  constructor() { }

  ngOnInit(): void {
  }

}
