<div class="Flexbox Flexbox--column FormGroup" [formGroup]="generalInfoFormGroup">
  <h4 class="GroupTitle">General Info</h4>
  <div class="Flexbox FormRow">
    <mat-form-field appearance="outline" class="JBT">
      <mat-label>JBT Number</mat-label>
      <input #jbtInput matInput formControlName="jbtNumber" type="text" maxlength="8" (change)="jbtChanged()">
      <mat-error
        *ngIf="generalInfoFormGroup.controls.jbtNumber.invalid">{{getError(generalInfoFormGroup.controls.jbtNumber)}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="StoreName">
      <mat-label>Store Name</mat-label>
      <input matInput formControlName="name" type="text">
      <mat-error
        *ngIf="generalInfoFormGroup.controls.name.invalid">{{getError(generalInfoFormGroup.controls.name)}}</mat-error>
    </mat-form-field>
  </div>
  <div class="Flexbox FormRow">
    <mat-form-field appearance="outline" class="SalesRep" *ngIf="availableReps">
      <mat-label>Sales Rep</mat-label>
      <mat-select formControlName="salesRep" [compareWith]="repOptionComparator">
        <mat-option *ngFor="let rep of availableReps" [value]="rep">{{rep.number}} - {{ rep.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="generalInfoFormGroup.controls.requestedTerms.invalid">{{
        getError(generalInfoFormGroup.controls.requestedTerms) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="RequestedTerms">
      <mat-label>Requested Account Terms</mat-label>
      <mat-select formControlName="requestedTerms" [compareWith]="termsOptionComparator">
        <mat-option *ngFor="let term of availableTerms" [value]="term">{{ term.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="generalInfoFormGroup.controls.requestedTerms.invalid">{{
        getError(generalInfoFormGroup.controls.requestedTerms) }}</mat-error>
    </mat-form-field>
  </div>
</div>
<div class="Flexbobx Flexbox--column">
  <h4 class="GroupTitle">Buyer Contact</h4>
  <div class="Flexbox Flexbox--column FormGroup" [formGroup]="buyerFormGroup">
    <div class="Flexbox FormRow">
      <mat-form-field appearance="outline" class="BuyerField">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="name" type="text">
        <mat-error *ngIf="buyerFormGroup.controls.name.invalid">{{getError(buyerFormGroup.controls.name)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="BuyerField">
        <mat-label>Phone</mat-label>
        <app-phone-input formControlName="phone" (change)="contactInfoChanged(buyerFormGroup.controls.phone)" defaultCountryCode="1"></app-phone-input>
        <mat-error *ngIf="buyerFormGroup.controls.phone.invalid">{{ getError(buyerFormGroup.controls.phone) }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="BuyerField">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" type="tel" (change)="contactInfoChanged(buyerFormGroup.controls.phone)">
        <mat-error *ngIf="buyerFormGroup.controls.phone.invalid">{{getError(buyerFormGroup.controls.phone)}}</mat-error>
      </mat-form-field> -->
    </div>
    <div class="Flexbox FormRow">
      <mat-form-field appearance="outline" class="BuyerField">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email">
        <mat-error *ngIf="buyerFormGroup.controls.email.invalid">{{getError(buyerFormGroup.controls.email)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="BuyerField">
        <mat-label>Confirm Email</mat-label>
        <input matInput formControlName="confirmEmail" type="email" (change)="contactInfoChanged(buyerFormGroup.controls.confirmEmail)">
        <mat-error *ngIf="buyerFormGroup.controls.confirmEmail.invalid">{{getError(buyerFormGroup.controls.confirmEmail)}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="Flexbobx Flexbox--column">
  <h4 class="GroupTitle">AR Contact</h4>
  <div class="Flexbox Flexbox--column FormGroup" [formGroup]="arFormGroup">
    <div class="Flexbox FormRow">
      <mat-form-field appearance="outline" class="ARField">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="name" type="text">
        <mat-error *ngIf="arFormGroup.controls.name.invalid">{{getError(arFormGroup.controls.name)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="ARField">
        <mat-label>Phone</mat-label>
        <app-phone-input formControlName="phone" (change)="contactInfoChanged(arFormGroup.controls.phone)" defaultCountryCode="1"></app-phone-input>
        <mat-error *ngIf="arFormGroup.controls.phone.invalid">{{ getError(arFormGroup.controls.phone) }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="ARField">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" type="tel" (change)="contactInfoChanged(arFormGroup.controls.phone)">
        <mat-error *ngIf="arFormGroup.controls.phone.invalid">{{getError(arFormGroup.controls.phone)}}</mat-error>
      </mat-form-field> -->
    </div>
    <div class="Flexbox FormRow">
      <mat-form-field appearance="outline" class="ARField">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email">
        <mat-error *ngIf="arFormGroup.controls.email.invalid">{{getError(arFormGroup.controls.email)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="ARField">
        <mat-label>Confirm Email</mat-label>
        <input matInput formControlName="confirmEmail" type="email" (change)="contactInfoChanged(arFormGroup.controls.confirmEmail)">
        <mat-error *ngIf="arFormGroup.controls.confirmEmail.invalid">{{getError(arFormGroup.controls.confirmEmail)}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="Flexbox Flexbox--column FormGroup AddressGroup" [formGroup]="addressFormGroup">
  <h4 class="GroupTitle">Address</h4>
  <mat-form-field appearance="outline" class="Country">
    <mat-label>Country</mat-label>
    <mat-select formControlName="country" [compareWith]="selectOptionComparator">
      <mat-option *ngFor="let country of availableCountries" [value]="country">{{ country }}</mat-option>
    </mat-select>
    <mat-error *ngIf="addressFormGroup.controls.country.invalid">{{ getError(addressFormGroup.controls.country) }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="Address1">
    <mat-label>Address Line 1</mat-label>
    <input matInput formControlName="street1" type="text">
    <mat-error
      *ngIf="addressFormGroup.controls.street1.invalid">{{getError(addressFormGroup.controls.street1)}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="Address2">
    <mat-label>Address Line 2</mat-label>
    <input matInput formControlName="street2" type="text">
    <mat-error *ngIf="addressFormGroup.controls.street2">{{getError(addressFormGroup.controls.street2)}}</mat-error>
  </mat-form-field>
  <div class="Flexbox FormRow">
    <mat-form-field appearance="outline" class="City">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" type="text">
      <mat-error *ngIf="addressFormGroup.controls.city">{{getError(addressFormGroup.controls.city)}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="State" *ngIf="!shouldHideStateCode">
      <mat-label>State / Province</mat-label>
      <mat-select formControlName="stateCode" [compareWith]="selectOptionComparator">
        <mat-option *ngFor="let province of availableProvinces" [value]="getProvinceAbbreviation(province) | uppercase"><span>{{ province | titlecase }}</span></mat-option>
      </mat-select>
      <mat-error *ngIf="addressFormGroup.controls.stateCode.invalid">{{ getError(addressFormGroup.controls.stateCode) }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="Zip">
      <mat-label>Postal Code</mat-label>
      <input matInput formControlName="postalCode" type="text">
      <mat-error
        *ngIf="addressFormGroup.controls.postalCode">{{getError(addressFormGroup.controls.postalCode)}}</mat-error>
    </mat-form-field>
  </div>
</div>