<div class="EditItemContainer Flexbox Flexbox--row">
  <div class="ItemDetailsContainer Flexbox Flexbox--row">
    <app-item-image [item]="lineItem.item" [isEligible]="lineItem.isEligible"></app-item-image>
    <div class="ItemActions Flexbox Flexbox--column">
        <span class="SKU">
          <ng-container *ngIf="lineItem.item">{{ lineItem.item.sku }}</ng-container>
          <ng-container *ngIf="!lineItem.item">N/A</ng-container>
        </span>
        <div class="CollectionNameContainer Flexbox Flexbox--align-center">
          <span class="ItemName">{{ lineItem.itemCollection }}</span>
          <mat-icon *ngIf="!lineItem.isEligible && canSeeIneligible" color="warn" [title]="lineItem.restrictionReason">error</mat-icon>
        </div>
        <div class="Flexbox ActionContainer">
          <button class="MoreMenuButton" mat-icon-button color="warn" (click)="removeClicked.emit(lineItem)"><mat-icon>delete</mat-icon></button>
          <button *ngIf="isProforma" class="EditButton" mat-icon-button color="accent" (click)="onEditButtonClicked()"><mat-icon><ng-container *ngIf="!isEditing">edit</ng-container> <ng-container *ngIf="isEditing">close</ng-container></mat-icon></button>
          <!-- <button class="EditButton" mat-button color="accent" (click)="editButtonClicked()" [textContent]="!isEditing ? 'Edit' : 'Close Editor'"></button> -->
        </div>
      <!-- <button class="MoreMenuButton" mat-icon-button color="accent" [matMenuTriggerFor]="moreMenu"><mat-icon>more_horiz</mat-icon></button>
      <mat-menu class="MoreMenu" #moreMenu>
        <button class="ActionButton" mat-menu-item (click)="quickCopyClicked.emit(lineItem)">Quick Copy</button>
        <button class="ActionButton" mat-menu-item (click)="copyClicked.emit(lineItem)">Copy</button>
        <button class="ActionButton Delete" mat-menu-item (click)="removeClicked.emit(lineItem)">Delete</button>
        <button class="ActionButton Delete" *ngIf="!isEditing" mat-menu-item (click)="editButtonClicked()">Edit</button>
      </mat-menu> -->
    </div>
  </div>
  <ng-container *ngIf="isStockBalance || isEditing then edit; else view"></ng-container>
  <ng-template #view>
    <div class="ViewItemDetailsContainer Flexbox Flexbox--column">
      <app-view-item-details *ngIf="lineItem.itemType === 'Ring'" [lineItem]="lineItem"></app-view-item-details>
      <span *ngIf="lineItem.poNumber">PO: <b>{{ lineItem.poNumber }}</b></span>
      <span *ngIf="isIncomplete" class="mat-critical">*This line item is not fully configured</span>
    </div>
  </ng-template>
  <ng-template #edit>
    <app-edit-proforma-item-properties *ngIf="type === 'Proforma'" [lineItem]="$any(lineItem)" (lineItemChange)="lineItemChanged($event)"></app-edit-proforma-item-properties>
    <app-edit-proforma-display-item-properties *ngIf="type === 'ProformaDisplay'" [lineItem]="$any(lineItem)" (lineItemChange)="lineItemChanged($event)"></app-edit-proforma-display-item-properties>
    <app-edit-proforma-program-item-properties *ngIf="type === 'ProformaProgram'" [lineItem]="$any(lineItem)" (lineItemChange)="lineItemChanged($event)"></app-edit-proforma-program-item-properties>
  </ng-template>
  <div *ngIf="isStockBalance" class="StockBalancePropertiesContainer Flexbox Flexbox--column">
    <app-edit-stock-balance-item-properties *ngIf="type !== 'StockBalanceDiscontinuedCarvedItem'" [lineItem]="$any(lineItem)" (lineItemChange)="lineItemChanged($event)"></app-edit-stock-balance-item-properties>
    <app-edit-stock-balance-discontinued-carved-item-properties *ngIf="type === 'StockBalanceDiscontinuedCarvedItem'" [lineItem]="$any(lineItem)" (lineItemChange)="lineItemChanged($event)"></app-edit-stock-balance-discontinued-carved-item-properties>
    <app-stock-balance-customer-stats [lineItem]="$any(lineItem)" *ngIf="type !== 'StockBalanceDiscontinuedCarvedItem'"></app-stock-balance-customer-stats>
  </div>
  <ng-container *ngIf="isProforma then proforma; else stockBalance"></ng-container>
  <ng-template #proforma><app-proforma-line-summary [lineItem]="lineItem"></app-proforma-line-summary></ng-template>
  <ng-template #stockBalance><app-stock-balance-line-summary [lineItem]="$any(lineItem)"></app-stock-balance-line-summary></ng-template>
</div>
