import { Customer } from '../interfaces/customer';
import { Item } from '../interfaces/item';
import { LineItem } from '../interfaces/line-item';

export function getLineItemPricingObject(lineItem: LineItem, customer: Customer) {
  const unitRate = lineItem.item ? getUnitRate(lineItem.item, customer) : 0;
  const discountRate = lineItem.item ? getDiscountRate(lineItem.item, customer) : 0
  const extendedDiscount = lineItem.item ? discountRate * lineItem.quantity : 0;
  const pricing = {
                    unitRate,
                    discountRate,
                    extendedDiscount,
                    extendedTotal: lineItem.item ? +(unitRate * +lineItem.quantity + extendedDiscount) : 0
                  }
  return pricing;
}

export function getDiscountRate(item: Item, customer: Customer | null) {
  if (customer) {
    const unitRate = getUnitRate(item, customer)
    const discountRate = (customer?.buyingGroup?.discount ?? 0) * unitRate * -1;
    return discountRate;
  }
  return 0;
}

export function getUnitRate(item: Item, customer: Customer) {
  const priceClassMarkup = item.shouldApplyPriceClass ? 1 + (customer.priceClass?.percentage ?? 0) : 1;
  const unitRate = (item.wholesalePrice ?? 0) * priceClassMarkup;
  return unitRate;
}