import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderViewRootComponent } from './components/order-view-root/order-view-root.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CollectionGroupModule } from 'src/app/shared/modules/collection-group/collection-group.module';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { RouterModule } from '@angular/router';
import { OrderViewCollectionGroupsModule } from 'src/app/shared/modules/order-view-collection-groups/order-view-collection-groups.module';
import { SignatureBoxModule } from 'src/app/shared/modules/signature-box/signature-box.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    OrderViewRootComponent,
  ],
  imports: [
    CommonModule,
    CollectionGroupModule,
    SharedModule,
    SharedAngularMaterialModule,
    RouterModule,
    OrderViewCollectionGroupsModule,
    SignatureBoxModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class OrderViewModule { }
