import { LineItem } from '../interfaces/line-item';

export function getTotalQuantity(lineItems: LineItem[]) {
  return lineItems.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);
}

export function getTotal(lineItems: LineItem[]) {
  return lineItems.reduce((total, item) => total + item.extendedTotal, 0);
}

export function getIneligibleLineItems(lineItems: LineItem[]) {
  return lineItems.filter(item => !item.isEligible);
}

export function getEligibleLineItems(lineItems: LineItem[]) {
  return lineItems.filter(item => item.isEligible);
}

export function getTotalDiscount(lineItems: LineItem[]) {
  return lineItems.reduce((discount, line) => line.extendedDiscount + discount, 0);
}
