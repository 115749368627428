import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer } from '../../interfaces/customer';

@Component({
  selector: 'app-existing-customer-select-dialog',
  templateUrl: './existing-customer-select-dialog.component.html',
  styleUrls: ['./existing-customer-select-dialog.component.scss']
})
export class ExistingCustomerSelectDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExistingCustomerSelectDialogComponent, Customer>,
    @Inject(MAT_DIALOG_DATA) public customers: Customer[]
  ) { }

  ngOnInit(): void {
  }

  closeDialog(customer: Customer) {
    this.dialogRef.close(customer);
  }
}
