<div [formGroup]="formGroup" class="StockBalance ItemPropertiesContainer Flexbox Flexbox--align-center">
  <mat-form-field class="FreeFormSKU" appearance="outline">
    <mat-label>SKU:</mat-label>
    <input matInput type="text" formControlName="freeformSKU">
  </mat-form-field>
  <!-- <div class="Flexbox Flexbox--column">
    <span class="Eligible">Eligible: <b>{{ lineItem.isEligible | yesNo }}</b></span>
    <span *ngIf="!lineItem.isEligible">Reason: {{ eligibilityReason }}</span>
  </div> -->
  <mat-form-field appearance="outline">
    <mat-label>Qty:</mat-label>
    <input matInput type="number" formControlName="quantity" (change)="quantityEditEnded()">
    <div class="UpDownButtons Flexbox Flexbox--column">
      <button mat-icon-button (click)="upButtonClicked()"><mat-icon>expand_less</mat-icon></button>
      <button mat-icon-button (click)="downButtonClicked()"><mat-icon>expand_more</mat-icon></button>
    </div>
  </mat-form-field>
</div>