import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsyncSelectComponent } from './components/async-select/async-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';



@NgModule({
  declarations: [
    AsyncSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedAngularMaterialModule
  ],
  exports: [
    AsyncSelectComponent
  ]
})
export class AsyncSelectModule { }
