import { Injectable } from '@angular/core';
import { WebProviderService } from './web.provider.service';
import { ADDRESS } from '../constants/address';
import { assert } from '../utils/assert.util';

@Injectable({
  providedIn: 'root'
})
export class AddressProviderService {

  constructor(
    private webProvider: WebProviderService
  ) { }

  async getAvailableCountries() {
    const results = await this.webProvider.get<string[]>(`${ADDRESS.API_URL}/countries`);
    assert(results, 'Available countries was null');
    return results;
  }

  async getAvailableCountriesSorted() {
    const results = await this.webProvider.get<string[]>(`${ADDRESS.API_URL}/countries`);
    assert(results, 'Available countries was null');
    results.sort();
    return results;
  }
}
