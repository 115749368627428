<div class="Flexbox TitleGroup">
  <div class="TitleTab">
    <span class="CollectionName">{{ collection.name }}</span>
  </div>
  <div class="Border Flexbox">
    <ng-container *ngIf="shouldShowRestrictionReasons">
      <div *ngIf="areRestrictionReasonsLoading" class="Flexbox Flexbox--align-center RestrictionReasonLoadingContainer">
        <mat-spinner diameter="20"></mat-spinner>
        <span>Loading</span>
      </div>
      <span *ngIf="!areRestrictionReasonsLoading && restrictionReason" class="RestrictionReason">{{ restrictionReason }}</span>
      <span *ngIf="!areRestrictionReasonsLoading && restrictionReason && restrictionReasonUpdatedDate" class="LastCalculated">(Last calulated {{ restrictionReasonUpdatedDate | shortDate }})</span>
    </ng-container>
  </div>
</div>
<div class="GroupContainer Flexbox Flexbox--column">
  <ng-content></ng-content>
</div>
