import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isPhoneValid(minLength?: number, maxlength?: number, options?: { errorMessage?: string}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const sanitizedControlValLength = control.value.replace(/\+| /g, '').length;

    const isAtLeastMinLength = !minLength || sanitizedControlValLength >= minLength;
    const isNotLongerThanMaxLength = !maxlength || sanitizedControlValLength <= maxlength;

    return !isAtLeastMinLength || !isNotLongerThanMaxLength ? { matchesValue: options?.errorMessage ?? 'Invalid phone' } : null;
  }
}
