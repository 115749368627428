<h2 mat-dialog-title>Copy Line Item?</h2>
<mat-dialog-content>
  <div class="Flexbox Flexbox--column">
    <p>How many copies would you like to make?</p>
    <mat-form-field appearance="outline">
      <mat-label>Copy Times</mat-label>
      <input matInput type="number" [(ngModel)]="quantity" (change)="quantityChanged()">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="quantity">Copy</button>
</mat-dialog-actions>
