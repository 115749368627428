import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Item } from '../../interfaces/item';
import { getAssetKey, getThumbnailImageURL } from '../../utils/item.util';
import { ItemImageGalleryDialogComponent } from '../item-image-gallery-dialog/item-image-gallery-dialog.component';

@Component({
  selector: 'app-item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss']
})
export class ItemImageComponent implements OnInit {
  @Input() item: Item | null | undefined
  @Input() isEligible = true;

  shouldShowGalleryButton = true;

  get imageSrc(): string | null {
    if (this.item) {
      return getThumbnailImageURL(this.item);
    }
    return null;
  }

  constructor(
    private matDialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.shouldShowGalleryButton = !!(this.item && getAssetKey(this.item.styleNumber));
  }

  openGallery() {
    this.matDialog.open(ItemImageGalleryDialogComponent, { data: this.item ?? null });
  }
}
