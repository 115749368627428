import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  transform(date: Date | string, args?: { suffixZuluTime?: boolean, withTime?: boolean }): string {
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', year: '2-digit', month: '2-digit' };

    if (args?.withTime) {
      dateOptions.hour = '2-digit';
      dateOptions.minute = '2-digit';
    }

    const dateComponents = [date.toString()];

    if (args?.suffixZuluTime) {
      dateComponents.push('Z');
    }

    const actualDate = new Date(dateComponents.join(''));
    return actualDate.toLocaleDateString('en-us', dateOptions);
  }

}
