<mat-card class="SummaryCard">
  <mat-card-title>
    <div class="Title">
      {{ collection.name }}
    </div>
  </mat-card-title>
  <mat-card-subtitle>
    <ng-container *ngIf="didOrderStatsLoad() then restrictionsTemplate else loadingTemplate"></ng-container>
    <ng-template #loadingTemplate>
      <mat-spinner diameter="20"></mat-spinner>
    </ng-template>
    <ng-template #restrictionsTemplate>
      <div *ngIf="getRestrictionReason()" class="Flexbox Subtitle mat-critical"><mat-icon color="critical">error</mat-icon><span>{{ getRestrictionReason() }}</span></div>
      <div *ngIf="!getRestrictionReason()" class="Flexbox Subtitle mat-success"><mat-icon>check</mat-icon><span>Requirements met</span></div>
    </ng-template>
  </mat-card-subtitle>
  <mat-card-content>
    <div class="CardContentWrapper Flexbox">
      <div class="CardContent Flexbox Flexbox--column Proforma">
        <span><b>Order</b></span>
        <div>Qty: {{ getProformaQuantity() }}</div>
        <div>Total: {{ getProformaTotal() | money: 'whole' }}</div>
      </div>
      <div class="CardContent Flexbox Flexbox--column StockBalance">
        <span><b>Stock Balance</b></span>
        <div>Qty: {{ getStockBalanceQuantity() }}</div>
        <div>Total: {{ getStockBalanceTotal() | money: 'whole' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>