import { Injectable } from '@angular/core';
import { STOCK_BALANCE } from '../constants/stock-balance';
import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';
import { StockBalanceLineItemAddRequest } from '../interfaces/stock-balance-line-item-add-request';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class StockBalanceLineItemsProviderService {

  constructor(
    private webProvider: WebProviderService
  ) { }

  async get(stockBalanceKey: string) {
    const results = await this.webProvider.get<StockBalanceLineItem[]>(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines`);
    assert(results, 'No stock balance lines for the given stock balance.');
    return results;
  }

  async add(stockBalanceKey: string, stockBalanceLineItem: StockBalanceLineItemAddRequest) {
    const results = await this.webProvider.post(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines`, stockBalanceLineItem);
    return results;
  }

  async set(stockBalanceKey: string, stockBalanceLineItems: StockBalanceLineItemAddRequest[]) {
    const results = await this.webProvider.put<StockBalanceLineItem[]>(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines/set`, stockBalanceLineItems);
    assert(results, 'No stock balance line items found');
    return results;
  }

  async remove(stockBalanceKey: string, stockBalanceLineItemKey: string) {
    const results = await this.webProvider.delete(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines/${stockBalanceLineItemKey}`);
    return results;
  }

  async approve(stockBalanceKey: string, stockBalanceLineItemKey: string) {
    await this.webProvider.put(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines/${stockBalanceLineItemKey}/approve`);
  }

  async deny(stockBalanceKey: string, stockBalanceLineItemKey: string, reason: string) {
    await this.webProvider.put(`${STOCK_BALANCE.API_URL}/${stockBalanceKey}/lines/${stockBalanceLineItemKey}/deny?reason=${reason}`);
  }
}
