<h1 mat-dialog-title>Change Password</h1>
<div mat-dialog-content>
  <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
  <div class="Flexbox Flexbox--column MainDialogContent">
    <div class="Flexbox Flexbox--column Form">
      <div>
        <p>
          <ng-container *ngIf="userName">You are currently changing the password for <b>{{userName}}. </b></ng-container>
          <span class="Warning">Changing the password will invalidate all active sessions.</span>
        </p>
      </div>
      <div class="Flexbox Flexbox--column" [formGroup]="formGroup">
        <mat-form-field appearance="outline" *ngIf="formGroup.controls.password.enabled">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="!isPasswordVisible ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="isPasswordVisible = !isPasswordVisible"><mat-icon><ng-container *ngIf="!isPasswordVisible">visibility</ng-container><ng-container *ngIf="isPasswordVisible">visibility_off</ng-container></mat-icon></button>
          <mat-error *ngIf="formGroup.controls.password.invalid">{{getError(formGroup.controls.password)}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions class="Flexbox">
  <button mat-raised-button [mat-dialog-close]>Cancel</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="formGroup.controls.password.value">Change Password</button>
</mat-dialog-actions>
