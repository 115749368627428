import { Component, Input, OnInit } from '@angular/core';
import { Collection } from 'src/app/shared/interfaces/collection';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { SummaryCollection } from 'src/app/shared/interfaces/summary-collection';
import { getCollectionRestrictionReason } from 'src/app/shared/utils/collection.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { getTotal, getTotalQuantity } from 'src/app/shared/utils/order.util';
import { formatMoney } from 'src/app/shared/utils/text-formatters.util';

@Component({
  selector: 'app-collection-summary-card[collection][restrictedCollections]',
  templateUrl: './collection-summary-card.component.html',
  styleUrls: ['./collection-summary-card.component.scss']
})
export class CollectionSummaryCardComponent implements OnInit {
  @Input() collection!: SummaryCollection;
  @Input() restrictedCollections!: string[];

  constructor() { }

  ngOnInit(): void {
  }

  getProformaTotal() {
    return getTotal(this.collection.proformaItems);
  }

  getStockBalanceTotal() {
    return getTotal(this.collection.stockBalanceItems);
  }

  getProformaQuantity() {
    return getTotalQuantity(this.collection.proformaItems);
  }

  getStockBalanceQuantity() {
    return getTotalQuantity(this.collection.stockBalanceItems);
  }

  getRestrictionReason() {
    const collectionReason = getCollectionRestrictionReason(this.collection, this.restrictedCollections);
    const hasOrderStatsReason = this.collection.stockBalanceItems.some(lineItem => !!getLineItemOrderStatsRestrictionReason(lineItem));
    if (collectionReason && hasOrderStatsReason) {
      return `${collectionReason} + more`
    }

    if (collectionReason) {
      return collectionReason;
    }

    if (hasOrderStatsReason) {
      return 'Stock Balance Exception';
    }

    return null;
  }

  didOrderStatsLoad() {
    if (this.collection.stockBalanceItems.length === 0) {
      return true;
    }
    return this.collection.stockBalanceItems.some(lineItem => lineItem.areOrderStatsLoaded);
  }
}
