import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSubmitRootComponent } from './components/order-submit-root/order-submit-root.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { OrderViewCollectionGroupsModule } from 'src/app/shared/modules/order-view-collection-groups/order-view-collection-groups.module';
import { SignatureBoxModule } from 'src/app/shared/modules/signature-box/signature-box.module';


@NgModule({
  declarations: [
    OrderSubmitRootComponent,
    OrderInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OrderViewCollectionGroupsModule,
    SignatureBoxModule
  ]
})
export class OrderSubmitModule { }
