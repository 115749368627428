<mat-toolbar color="primary">
  <div class="TitleContainer Flexbox Flexbox--row">
    <a class="Flexbox Flexbox--row Title" [routerLink]="homeURL"><img class="Logo" src="https://assets.benchmarkrings.com/assets/white_logo_720.png" alt="Benchmark"></a>
  </div>
  <div class="ActionContainer Flexbox Flexbox--row">
    <!-- <mat-slide-toggle>Retail Mode</mat-slide-toggle> -->
    <button mat-icon-button class="HomeButton" [routerLink]="homeURL"><mat-icon>home</mat-icon></button>
    <div class="UserContainer Flexbox Flexbox--row">
      <span>{{ userName }}</span>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;" class="ProfileImage" color="primary">{{ userInitials }}</button>
      <mat-menu #menu="matMenu">
        <div class="MenuContent">
          <app-navigation-menu class="NavigationMenu"></app-navigation-menu>
          <div class="LogoutButtonContainer Flexbox Flexbox--column"><button mat-button class="LogoutButton" (click)="logout()">Logout</button></div>
        </div>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
