import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewDisplayItemComponent } from './components/view-display-item/view-display-item.component';
import { ViewItemComponent } from './components/view-item/view-item.component';
import { OrderViewCollectionGroupsComponent } from './components/order-view-collection-groups/order-view-collection-groups.component';
import { CollectionGroupModule } from '../collection-group/collection-group.module';
import { SharedModule } from '../../shared.module';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { ViewProgramItemComponent } from './components/view-program-item/view-program-item.component';
import { ViewStockBalanceItemComponent } from './components/view-stock-balance-item/view-stock-balance-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Pdf417BarcodeComponent } from './components/barcode/pdf417-barcode.component';

@NgModule({
  declarations: [
    ViewItemComponent,
    ViewDisplayItemComponent,
    OrderViewCollectionGroupsComponent,
    ViewProgramItemComponent,
    ViewStockBalanceItemComponent,
    Pdf417BarcodeComponent
  ],
  imports: [
    CommonModule,
    CollectionGroupModule,
    SharedModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    OrderViewCollectionGroupsComponent,
    ViewStockBalanceItemComponent
  ]
})
export class OrderViewCollectionGroupsModule { }
