import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isCountryCodeValid(availableCountryCodes: number[], options?: { errorMessage?: string}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return !availableCountryCodes.some(countryCode => +control.value.replace(/^\+/, '') === countryCode) ? { matchesValue: options?.errorMessage ?? 'Invalid country code' } : null;
  }
}
