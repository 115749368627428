import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/shared/interfaces/address';
import { Order } from 'src/app/shared/interfaces/order';
import { OrderProviderService } from 'src/app/shared/providers/order.provider.service';
import { StockBalanceLineItemsProviderService } from 'src/app/shared/providers/stock-balance-line-items.provider.service';
import { StockBalanceProviderService } from 'src/app/shared/providers/stock-balance.provider.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SubmitOrderService } from 'src/app/shared/services/submit-order.service';
import { getStatusProgress } from 'src/app/shared/utils/status.util';

@Component({
  selector: 'app-packing-list-root',
  templateUrl: './packing-list-root.component.html',
  styleUrls: ['./packing-list-root.component.scss']
})
export class PackingListRootComponent implements OnInit {
  order: Order | null = null;
  isLoading = false;
  benchmarkFedexReturnAddress: Address = {
    id: null,
    company: 'BENCHMARK',
    attention: null,
    addressee: null,
    street1: '1023 15TH ST',
    street2: null,
    city: 'TUSCALOOSA',
    stateCode: 'AL',
    postalCode: '35403',
    country: 'UNITED STATES'
  };

  benchmarkUSPSReturnAddress: Address = {
    id: null,
    company: 'BENCHMARK',
    attention: null,
    addressee: null,
    street1: 'PO Drawer 2929',
    street2: null,
    city: 'TUSCALOOSA',
    stateCode: 'AL',
    postalCode: '35403',
    country: 'UNITED STATES'
  }

  get doesStockBalanceHaveLineItems() {
    return (this.order?.stockBalance?.lineItems ?? []).length > 0
  }

  constructor(
    private orderService: OrderService,
    private submitOrderService: SubmitOrderService,
    private orderProvider: OrderProviderService,
    private stockBalanceLineItemProvider: StockBalanceLineItemsProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.orderService.set(null);
    this.submitOrderService.delete();

    const orderKey = this.route.snapshot.params['orderID'];
    if (orderKey) {
      this.order = await this.orderProvider.get(orderKey);
    }

    if (!this.order || !this.order.stockBalance || getStatusProgress(this.order.status) < getStatusProgress('Processing')) {
      this.router.navigateByUrl('/404')
      return;
    }

    const lineItems = await this.stockBalanceLineItemProvider.get(this.order.stockBalance.key);
    this.order.stockBalance.lineItems = lineItems.filter(lineItem => lineItem.isApproved !== false);

    this.isLoading = false;
  }

  showPrintDialog() {
    window.print();
  }
}
