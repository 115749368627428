import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { getMetalString } from 'src/app/shared/utils/metal.util';
import { getLineItemPricingObject } from 'src/app/shared/utils/pricing.util';
import { CustomerSummaryBarService } from '../../../customer-summary-bar/services/customer-summary-bar.service';

@Component({
  selector: 'app-edit-item[type][lineItem]',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {
  @Input() type!: 'Proforma' | 'StockBalance' | 'ProformaDisplay' | 'ProformaProgram' | 'StockBalanceDiscontinuedCarvedItem';
  @Input() lineItem!: LineItem;
  @Output() lineItemChange = new EventEmitter<LineItem>();
  @Output() removeClicked = new EventEmitter<LineItem>();
  @Output() copyClicked = new EventEmitter<LineItem>();
  @Output() quickCopyClicked = new EventEmitter<LineItem>();
  @Output() editButtonClicked = new EventEmitter();
  isEditing = true;

  get isIncomplete() {
    return !this.lineItem.item;
  }

  get isProforma() {
    return this.type.toLowerCase().includes('proforma');
  }

  get isStockBalance() {
    return this.type.toLowerCase().includes('stockbalance');
  }

  get karat() {
    if (!this.lineItem.selectedMetals) {
      return '';
    }
    return getMetalString(this.lineItem.selectedMetals);
  }

  get canSeeIneligible() {
    return this.userService.hasRole();
  }

  constructor(
    private userService: UserService,
    private customerService: CustomerSummaryBarService
  ) { }

  ngOnInit(): void {
    this.isEditing = this.isStockBalance || this.isProforma && false;
    this.updatePricing();
   }

  lineItemChanged(lineItem: LineItem) {
    this.updatePricing()
    lineItem.hasChanges = true;
    if (lineItem.quantity !== null && lineItem.quantity < 1) {
      this.removeClicked.emit(lineItem);
    } else {
      this.updateCollection();
      this.lineItemChange.emit(lineItem);
    }
  }

  onEditButtonClicked() {
    this.isEditing = !this.isEditing;
    this.editButtonClicked.emit();
  }

  updateCollection() {
    if (this.lineItem.item?.collection) {
      this.lineItem.itemCollection = this.lineItem.item.collection;
    }
  }

  updatePricing() {
    const customer = this.customerService.get();
    assert(customer, 'Customer was null');
    const { unitRate, discountRate, extendedDiscount, extendedTotal } = getLineItemPricingObject(this.lineItem, customer);
    this.lineItem.unitRate = unitRate;
    this.lineItem.discountRate = discountRate;
    this.lineItem.extendedDiscount = extendedDiscount;
    this.lineItem.extendedTotal = extendedTotal;
  }

  stopEdit() {
    this.isEditing = false;
  }
}
