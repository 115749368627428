import { parsePhoneNumber } from 'awesome-phonenumber';
import { Contact } from '../interfaces/contact';
import { NetsuiteCustomer } from '../interfaces/netsuite-customer';
import { NetsuiteCustomerAddress } from '../interfaces/netsuite-customer-address';
import { NetsuiteCustomerContact } from '../interfaces/netsuite-customer-contact';
import { NewCustomer } from '../interfaces/new-customer';
import { NewCustomerAddress } from '../interfaces/new-customer-address';
import { NewCustomerFormValues } from '../interfaces/new-customer-form-values';
import { getProvincesWithCountry } from './address.util';
import { assert } from './assert.util';
import { buildCustomerContact } from './new-customer.util';

export function buildNetsuiteCustomerFromCustomer(customerValues: NewCustomerFormValues) {
  assert(customerValues?.buyer?.name, 'Buyer was invalid')
  assert(customerValues.generalInfo.name && customerValues.generalInfo.requestedTerms && customerValues.generalInfo.salesRep?.number, 'Invalid store info');
  assert(customerValues.address.country, 'No address country given');
  const ar = buildCustomerContact(customerValues.ar, customerValues.address.country, 'Accounts Receivable');
  const buyer = buildCustomerContact(customerValues.buyer, customerValues.address.country, 'Buyer');

  const netsuiteCustomer: NetsuiteCustomer = {
    jbtNumber: customerValues.generalInfo.jbtNumber ?? undefined,
    name: customerValues.generalInfo.name,
    addresses: [buildNetsuiteAddressFromNewCustomerAddress(customerValues.address, customerValues.buyer.name)],
    termsID: customerValues.generalInfo.requestedTerms.externalID,
    priceClassID: 3,
    buyingGroupID: undefined,
    contacts: [buildNetsuiteContactFromContact(ar), buildNetsuiteContactFromContact(buyer)],
    salesRep: +customerValues.generalInfo.salesRep.number
  }
  return netsuiteCustomer;
}

export function buildNetsuiteAddressFromNewCustomerAddress(address: NewCustomerAddress, buyerName: string) {
  const doesCountryHaveProvinces = (getProvincesWithCountry(address.country ?? '') ?? []).length > 0
  assert(address.country && address.street1 && address.city && ((doesCountryHaveProvinces && address.stateCode) || !doesCountryHaveProvinces) && address.postalCode)
  const netsuiteAddress : NetsuiteCustomerAddress = {
    country: address.country,
    street1: address.street1,
    street2: address.street2 ?? undefined,
    city: address.city,
    state: address.stateCode ?? undefined,
    zip: address.postalCode,
    addressee: buyerName
  }
  return netsuiteAddress;
}

export function buildNetsuiteContactFromContact(contact: Contact) {
  assert(contact.email && contact.phone && contact.name && contact.type, 'Contact missing required fields');
  const parsedPhone = parsePhoneNumber(`+${contact.phone}`);
  const formattedPhone = parsedPhone.regionCode === 'US' && parsedPhone.number ? parsedPhone.number?.national : (parsedPhone.number?.international.replace(/\+/, '') ?? contact.phone);
  const netsuiteContact: NetsuiteCustomerContact = {
    email: contact.email,
    phone: formattedPhone,
    name: contact.name,
    category: contact.type
  }
  return netsuiteContact;
}
