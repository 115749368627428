import { Component, Input, OnInit } from '@angular/core';
import { Collection } from 'src/app/shared/interfaces/collection';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { SummaryCollection } from 'src/app/shared/interfaces/summary-collection';

@Component({
  selector: 'app-collection-summary[collections][restrictedCollections]',
  templateUrl: './collection-summary.component.html',
  styleUrls: ['./collection-summary.component.scss']
})
export class CollectionSummaryComponent implements OnInit {
  @Input() collections!: SummaryCollection[];
  @Input() restrictedCollections!: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
