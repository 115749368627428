import { Injectable } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { Order } from '../interfaces/order';
import { OrderCreateRequest } from '../interfaces/order-create-request';
import { OrderUpdateRequest } from '../interfaces/order-update-request';
import { ProformaLineItem } from '../interfaces/proforma-line-item';
import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';
import { SubmitOrder } from '../interfaces/submit-order';
import { OrderProviderService } from '../providers/order.provider.service';
import { ProformaLineItemProviderService } from '../providers/proforma-line-item.provider.service';
import { StockBalanceLineItemsProviderService } from '../providers/stock-balance-line-items.provider.service';
import { StockBalanceProviderService } from '../providers/stock-balance.provider.service';
import { assert } from '../utils/assert.util';
import { buildProformaLineItemRequest } from '../utils/proforma.util';
import { buildStockBalanceLineItemRequest } from '../utils/stock-balance.util';
import { UserService } from './user.service';
import { buildSubmitOrderFromOrder } from '../utils/submit-order.util';

@Injectable({
  providedIn: 'root'
})
export class OrderSaveService {
  private _lastSavedAt: Date | null = null;
  private saveAt: Date | null = null;
  private subscription: Subscription | null = null;

  public hasChanges = false;

  public get lastSavedAt() {
    return this._lastSavedAt;
  }

  constructor(
    private orderProvider: OrderProviderService,
    private stockBalanceProvider: StockBalanceProviderService,
    private proformaLineItemProvider: ProformaLineItemProviderService,
    private stockBalanceLineItemProvider: StockBalanceLineItemsProviderService,
    private userService: UserService
  ) { }

  reset() {
    this.unschedule();
    this._lastSavedAt = null;
    this.saveAt = null;
    this.hasChanges = false;
  }

  scheduleSave(submitOrder: SubmitOrder, seconds: number, minSeconds: number | null = null) {
    this.unschedule();

    const beforeSave = new Subject<SubmitOrder>();
    const afterSave = new Subject<{ submitOrder: SubmitOrder, order: Order }>();

    const now = new Date();
    if (this.lastSavedAt && minSeconds) {
      assert(minSeconds > seconds, 'minSeconds must be >= seconds');
      const tmpLastSavedAt = new Date(this.lastSavedAt);
      tmpLastSavedAt.setSeconds(this.lastSavedAt.getSeconds() + minSeconds);
      if (now >= tmpLastSavedAt) {
        this.performSaveWithSubjects(submitOrder, beforeSave, afterSave);
        return { beforeSave, afterSave };
      }
    }

    const nextSaveAtDate = new Date();
    nextSaveAtDate.setSeconds(nextSaveAtDate.getSeconds() + seconds);
    this.saveAt = nextSaveAtDate;
    this.subscription = timer(this.saveAt).subscribe(() => {
      this.performSaveWithSubjects(submitOrder, beforeSave, afterSave);
    });
    return { beforeSave, afterSave };
  }

  private async performSaveWithSubjects(submitOrder: SubmitOrder, beforeSave: Subject<SubmitOrder>, afterSave: Subject<{ submitOrder: SubmitOrder, order: Order }>) {
    beforeSave.next(submitOrder);
    const updatedOrder = await this.save(submitOrder);
    const updatedSubmitOrder = buildSubmitOrderFromOrder(updatedOrder);
    afterSave.next({ submitOrder: updatedSubmitOrder, order: updatedOrder });
  }

  async save(submitOrder: SubmitOrder, signature: string | null = null) {
    assert(submitOrder.orderKey, 'Order key was null');

    const updateRequest = this.buildOrderUpdateRequest(submitOrder, signature);

    const order = await this.orderProvider.update(submitOrder.orderKey, updateRequest)
    order.proforma.lineItems = submitOrder.proformaLineItems;

    const lineItemRequests = order.proforma.lineItems.map(buildProformaLineItemRequest);
    await this.proformaLineItemProvider.set(order.proforma.key, lineItemRequests);

    var stockBalanceLineItems = submitOrder.stockBalanceLineItems;
    if (order.stockBalance || stockBalanceLineItems.length > 0) {
      if (!order.stockBalance) {
        order.stockBalance = await this.createStockBalance(order.key);
        submitOrder.stockBalanceKey = order.stockBalance.key;
      }

      order.stockBalance.lineItems = stockBalanceLineItems;
      const stockBalanceLineItemsRequest = stockBalanceLineItems.map(buildStockBalanceLineItemRequest);
      await this.stockBalanceLineItemProvider.set(order.stockBalance.key, stockBalanceLineItemsRequest);
    }

    this._lastSavedAt = new Date();
    this.hasChanges = false;
    return order;
  }

  private buildOrderUpdateRequest(submitOrder: SubmitOrder, signature: string | null) {
    const arInfo = {
      phone: submitOrder.orderDetails?.arPhone ?? '',
      email: submitOrder.orderDetails?.arEmail ?? '',
      name: submitOrder.orderDetails?.arName ?? ''
    };

    const updateRequest: OrderUpdateRequest = {
      shippingAddressID: submitOrder.address.id,
      terms: submitOrder.orderDetails?.terms ?? null,
      po: submitOrder.orderDetails?.po ?? null,
      buyerInfo: {
        phone: submitOrder.orderDetails?.buyerPhone ?? '',
        email: submitOrder.orderDetails?.buyerEmail ?? '',
        name: submitOrder.orderDetails?.buyerName ?? ''
      },
      arInfo: !Object.values(arInfo).some(value => !!value) ? null : arInfo,
      signature: signature
    }
    return updateRequest;
  }

  async createOrder(submitOrder: SubmitOrder) {
    const username = this.userService.get()?.email
    assert(username, 'No user.');
    const createRequest = this.buildOrderCreateRequest(username, submitOrder);
    const order = await this.orderProvider.create(createRequest);
    await this.saveProformaLineItems(order.proforma.key, submitOrder.proformaLineItems);
    if (submitOrder.stockBalanceLineItems.length > 0) {
       order.stockBalance = await this.createStockBalance(order.key);
       this.saveStockBalanceLineItems(order.stockBalance.key, submitOrder.stockBalanceLineItems);
    }

    this._lastSavedAt = new Date();
    this.hasChanges = false;
    return order;
  }

  private buildOrderCreateRequest(currentUserName: string, submitOrder: SubmitOrder) {
    assert(submitOrder.customer, 'No customer selected')
    assert(submitOrder.address, 'No address selected')
    const { buyerName: name, buyerEmail: email, buyerPhone: phone } = submitOrder.orderDetails ?? {};
    const createRequest: OrderCreateRequest = {
      key: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}`,
      customerID: submitOrder.customer.id,
      shippingAddressID: submitOrder.address.id ?? null,
      terms: submitOrder.orderDetails?.terms ?? null,
      buyerInfo: name && email && phone ? { name, email, phone } : null,
      proformaKey: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}`,
      salesRepNumber: submitOrder.customer.salesRepNumber,
      ownerUsername: currentUserName
    }
    return createRequest;
  }

  private async saveProformaLineItems(proformaKey: string, proformaLineItems: ProformaLineItem[]) {
    const lineItemRequests = proformaLineItems.map(buildProformaLineItemRequest);
    await this.proformaLineItemProvider.set(proformaKey, lineItemRequests);
  }

  private async createStockBalance(orderKey: string) {
    const stockBalance = await this.stockBalanceProvider.create(orderKey, { key: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}` });
    return stockBalance;
  }

  private async saveStockBalanceLineItems(stockBalanceKey: string, stockBalanceLineItems: StockBalanceLineItem[]) {
    const stockBalanceLineItemsRequest = stockBalanceLineItems.map(buildStockBalanceLineItemRequest);
    await this.stockBalanceLineItemProvider.set(stockBalanceKey, stockBalanceLineItemsRequest);
  }

  unschedule() {
    this.subscription?.unsubscribe();
    this.saveAt = null;
  }
}
