import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { Item } from '../../interfaces/item';
import { assert } from '../../utils/assert.util';
import { getAssetKey, getImageURL, getMainImage } from '../../utils/item.util';

@Component({
  selector: 'app-item-image-gallery-dialog',
  templateUrl: './item-image-gallery-dialog.component.html',
  styleUrls: ['./item-image-gallery-dialog.component.scss']
})
export class ItemImageGalleryDialogComponent implements OnInit {
  currentTab = 1;
  imageSource: string | null = null;
  iframeURL: SafeResourceUrl | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: Item | null,
    private domSanitizer: DomSanitizer,
    private webProvider: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.item) {
      this.imageSource = getMainImage(this.item!, 'lg');

      if (this.item.videoKey) {
        this.iframeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.item.videoKey}?autoplay=1&loop=1&mute=1&playlist=${this.item.videoKey}`);
      } else if (await this.itemHasOld360()) {
        this.iframeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://benchmark-rings.s3.amazonaws.com/360_new/${getAssetKey(this.item.styleNumber)}/iframe.html`);
      }
    }
  }

  async itemHasOld360() {
    if (this.item) {
      try {
        const requestResult = await this.webProvider.get(`https://benchmark-rings.s3.amazonaws.com/360_new/${getAssetKey(this.item.styleNumber)}/iframe.html`, { observe: 'response', responseType: 'text' });
        const response = await lastValueFrom(requestResult);
        return response.status < 400;
      }
      catch(e) {
        console.error(e)
        return false;
      }
    }
    return false;
  }

  switchTab(index: number) {
    this.currentTab = index;
  }
}
