import { Injectable } from '@angular/core';
import { Customer } from '../interfaces/customer';
import { Item } from '../interfaces/item';
import { StockBalanceLineItem } from '../interfaces/stock-balance-line-item';
import { LineItemFactory } from './line-item.factory';

@Injectable({
  providedIn: 'root'
})

export class StockBalanceLineItemFactory extends LineItemFactory<StockBalanceLineItem> {
  override create(item: Item, customer: Customer | null = null): StockBalanceLineItem {
    const lineItem = super.create(item, customer);
    lineItem.lineItemWholesalePrice = 0;
    lineItem.linePriceAdjustment = 0;
    lineItem.lineExtendedAmount = 0;
    lineItem.orderInvoiceNumber = '';
    return lineItem;
  }
}
