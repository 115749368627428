import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { RouterModule } from '@angular/router';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';



@NgModule({
  declarations: [
    NavigationBarComponent,
    NavigationMenuComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    RouterModule
  ],
  exports: [
    NavigationBarComponent,
    NavigationMenuComponent
  ]
})
export class NavigationModule { }
