import { Injectable } from '@angular/core';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { LineItemsService } from './line-items.service';

@Injectable({
  providedIn: 'root'
})
export class StockBalanceLineItemsService extends LineItemsService<StockBalanceLineItem> {

  constructor() {
    super();
   }
}
