<ng-container *ngIf="this.order && this.order.proforma.lineItems && !isLoading then orderTemplate;else loadingTemplate"></ng-container>
<ng-template #loadingTemplate>
  <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
</ng-template>
<ng-template #orderTemplate>
  <div class="Content Flexbox Flexbox--column">
    <img class="Logo" src="/assets/BenchmarkLogo_Black.png" alt="Benchmark">
    <ng-container *ngIf="order?.status !== 'Draft' then orderTitle;else draftTitle"></ng-container>
    <ng-template #draftTitle><h3 class="Title">Draft</h3></ng-template>
    <ng-template #orderTitle><h3 class="Title">Order</h3></ng-template>
    <div class="ButtonContainer Flexbox">
      <button mat-raised-button color="accent" (click)="showPrintDialog()">Print</button>
      <button *ngIf="canViewPackingListButton" mat-raised-button color="accent" (click)="navigateToPackingList()">Packing List</button>
      <button *ngIf="canEdit" mat-raised-button [routerLink]="editLink" color="accent">Edit</button>
      <button *ngIf="canChangeCustomer" mat-raised-button (click)="changeCustomer()" color="accent">Change Customer</button>
      <button *ngIf="canConvertToW2W" mat-raised-button (click)="convertToW2W()" color="accent">Convert to W2W</button>
      <button *ngIf="canConvertToRegular" mat-raised-button (click)="convertToRegular()" color="accent">Convert to Regular</button>
      <button *ngIf="canDelete" mat-raised-button (click)="deleteDraft()" color="warn">Delete</button>
      <button *ngIf="canRestore" mat-raised-button (click)="restore()">Restore</button>
      <button *ngIf="canHold" mat-raised-button (click)="hold()" class="HoldButton">Hold</button>
      <button *ngIf="canDenyApprove" mat-raised-button (click)="deny()" color="warn">Deny</button>
      <button *ngIf="canDenyApprove" mat-raised-button (click)="approve()" class="ApproveButton">Approve</button>
      <!-- <button mat-raised-button>Download PDF</button> -->
    </div>
    <app-status [status]="order.status"></app-status>
    <div class="HoldDeniedReason" *ngIf="order.deniedReason || (order.holdReason && user)">
      <ng-container *ngIf="order.holdReason && order.status === 'AR Hold'">
        <span><b>Reason: </b></span><span class="mat-serious">{{ order.holdReason }}</span>
        <button *ngIf="canEditHold" mat-button class="EditHoldReason" color="accent" (click)="hold()">Edit</button>
      </ng-container>
      <span *ngIf="order.deniedReason || order.status === 'AR Denied'"><b>Reason: </b></span><span class="mat-critical">{{ order.deniedReason }}</span>
    </div>
    <div *ngIf="order">
      <div class="OrderInfoContainer">
        <div>
          <p>CUST #</p>
          <div class="Flexbox Flexbox--align-center CustomerNumberValue">
            <p *ngIf="!order.customer.number" class="ProspectLabel">NEW</p>
            <p *ngIf="!order.customer.isActive" class="InactiveLabel">INACTIVE</p>
            <p>{{ order.customer.number }}</p>
          </div>
        </div>
        <div>
          <p>Web Order #</p>
          <p>{{ order.webNumber }}</p>
        </div>
        <div *ngIf="canViewNetsuiteNumber">
          <p>Netsuite Cust #</p>
          <p><a target="_blank" [href]="netsuiteCustomerLink">{{ order.customer.netsuiteNumber }}</a></p>
        </div>
        <div>
          <p>System Order #</p>
          <p>{{ order.number }}</p>
        </div>
        <div>
          <p>Invoice #</p>
          <p>{{ order.invoiceNumber }}</p>
        </div>
        <div>
          <p>Order Date</p>
          <p>{{ order.dateCreated | shortDate: { suffixZuluTime: true } }}</p>
        </div>
        <div>
          <p>Terms</p>
          <p>{{ order.terms ?? 'Per Account' }}</p>
        </div>
        <div>
          <p>Sales Rep</p>
          <p>{{ order.salesRep.number }}</p>
        </div>
        <div>
          <p>PO #</p>
          <p>{{ order.proforma.poNumber }}</p>
        </div>
        <div>
          <p>Type</p>
          <p [ngClass]="{'mat-critical': order.type === 'W2W'}">{{ order.type }}</p>
        </div>
      </div>
      <div class="OrderInfoContainer">
        <ng-container *ngIf="order.proforma.promisedShipDate then psdTemplate; else esdTemplate"></ng-container>
        <ng-template #esdTemplate>
          <div>
            <p>Est Ship Date</p>
            <p *ngIf="order.proforma.estimatedShipDate">{{ order.proforma.estimatedShipDate | shortDate }}</p>
            <p *ngIf="!order.proforma.estimatedShipDate">Pending</p>
          </div>
        </ng-template>
        <ng-template #psdTemplate>
          <div>
            <p>Promised Ship Date</p>
            <p *ngIf="order.proforma.promisedShipDate">{{ order.proforma.promisedShipDate | shortDate }}</p>
          </div>
        </ng-template>
        <div>
          <p>Buyer Name</p>
          <p>{{ order.buyerName }}</p>
        </div>
        <div>
          <p>Bill To</p>
          <app-address [address]="order.billingAddress"></app-address>
        </div>
        <div>
          <p>Ship To</p>
          <app-address [address]="order.shippingAddress"></app-address>
        </div>
      </div>
    </div>
    <app-order-view-collection-groups [order]="order" [restrictedCollections]="restrictedCollections"></app-order-view-collection-groups>

    <div class="Flexbox OrderSummaryContainer">
      <div class="Flexbox OrderSummary">
        <p *ngIf="order.number === null" class="Disclaimer">All pricing shown is a close estimate. Final pricing will not be calculated until the actual order is approved and entered into our system.</p>
        <app-order-review-summary [stockBalanceLineItems]="order.stockBalance?.lineItems ?? []" [proformaLineItems]="order.proforma.lineItems" [shippingCost]="order.shippingCost" [orderTerms]="order.terms"></app-order-review-summary>
      </div>
    </div>
    <div *ngIf="order.status === 'Pending Retailer' || (this.order.signature && this.order.signatureDate && this.order.status !== 'Pending Retailer')" class="BigDisclaimerContainer">
      <p *ngIf="(order?.stockBalance?.lineItems ?? []).length > 0" class="BigDisclaimer StockBalanceDisclaimer">
        This order has a stock balance associated with it. The credit amounts of the items listed are estimates based on the current price of the item. Pricing does not take into account any previous discounting, weight to weight buyouts or promotional pricing that may have been given at the time of purchase. Before processing, your stock balance items will be assessed with their actual purchase values. If your returned items have been purchased multiple times, we will assign the lowest cost paid for that item.
      </p>
      <p class="BigDisclaimer">
        In consideration of Benchmark (“Lender”) extending credit now and future credit to the Debtor, and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Debtor and Guarantor personally (if signed by Guarantor below), guarantee the prompt, full and complete performance of any and all present and future duties, obligations and indebtedness (the Debt) due to Lender by the Debtor, under the following terms and conditions: 1. The Debtor promises and the Guarantor guarantees that the Debtor will promptly pay the full amount of principal and interest of the Debt and when the same will in any manner be or become due according to the terms established by the Lender or upon acceleration of the payment by reason of default. 2. The Debtor and Guarantor agree not to pledge, hypothecate, mortgage sell or otherwise transfer any of the it’s/their assets without the prior written consent of the Lender. 3. To the extent permitted by law, the Debtor and Guarantor waive all defenses, counterclaims or offsets that are legally available to the Debtor and Guarantor with respect to the payment of the Debt of the Debtor and guarantee the payment of all costs and reasonable attorney fees whether suit is brought or not and any attorney fees on appeal, which the Lender may incur in the collection of any debts owed to the Lender. 4. If applicable, the Lender is hereby authorized at any time, in its sole discretion and without notice, to take, change, release or in any way deal with any security securing the Debt without in any way impairing the obligation of the Debtor and/or Guarantor. 5. You must do minimum of $3,000 in non-stock related sales annually to maintain an active account with Benchmark. Benchmark reserves the right to close your account (without any obligation to purchase remaining samples) if this minimum is not met.
      </p>
    </div>
    <div *ngIf="this.order.signature && this.order.signatureDate && this.order.status !== 'Pending Retailer'" class="ViewSignatureWrapper Flexbox Flexbox--column">
      <span>Signature:</span>
      <img [src]="getUnsafeImageURL(order.signature)">
      <div>
        <span>Date Signed: </span><span>{{ order.signatureDate | shortDate: { suffixZuluTime: true, withTime: true } }}</span>
      </div>
    </div>
    <div *ngIf="order.status === 'Pending Retailer'" class="SignatureBoxWrapper Flexbox Flexbox--column">
      <app-signature-box [(signature)]="signature"></app-signature-box>
      <div class="Flexbox Flexbox--justify-right">
        <button mat-raised-button class="SubmitSignatureButton" color="accent" (click)="retailerApprove()">Submit Signature</button>
      </div>
    </div>
  </div>
</ng-template>
