<h1 mat-dialog-title>Hold Order</h1>
<mat-dialog-content>
  <mat-form-field class="ReasonsField" appearance="outline">
    <mat-label>Reason(s) for hold</mat-label>
    <textarea matInput [formControl]="reasonFormControl"></textarea>
    <mat-error *ngIf="reasonFormControl.invalid">Please provide a reason for hold.</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Cancel</button>
  <button mat-button (click)="hold()">Hold</button>
</mat-dialog-actions>
