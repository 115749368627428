import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-box',
  templateUrl: './signature-box.component.html',
  styleUrls: ['./signature-box.component.scss']
})
export class SignatureBoxComponent implements OnInit {
  private _signature: string | null = null;
  @Input() set signature(value : string | null) {
    this.signaturePad?.clear();
    this._signature = value;
    if (value) {
      this.signaturePad?.fromDataURL(value, { height: this.signaturePadCanvas.nativeElement.height, width: this.signaturePadCanvas.nativeElement.width });
    }
  }

  get signature() {
    return this._signature;
  }

  @Output() signatureChange = new EventEmitter<string | null>();
  @ViewChild('signaturePadCanvas', {static: true}) signaturePadCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('signaturePadWrapper') signaturePadWrapper!: ElementRef;
  signaturePad: SignaturePad | null = null
  timeout?: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.signaturePadCanvas.nativeElement.width = this.signaturePadWrapper.nativeElement.getBoundingClientRect().width;
    this.signaturePadCanvas.nativeElement.height = this.signaturePadWrapper.nativeElement.getBoundingClientRect().height;
    this.signaturePad = new SignaturePad(this.signaturePadCanvas.nativeElement);

    this.signaturePad.addEventListener('endStroke', (e) => {
      this.signature = this.signaturePad?.toDataURL() ?? null;
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.signaturePadCanvas.nativeElement.width = this.signaturePadWrapper.nativeElement.getBoundingClientRect().width;
    this.signaturePadCanvas.nativeElement.height = this.signaturePadWrapper.nativeElement.getBoundingClientRect().height;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.signature) {
        this.signaturePad?.clear();
        this.signaturePad?.fromDataURL(this.signature, { height: this.signaturePadCanvas.nativeElement.height, width: this.signaturePadCanvas.nativeElement.width });
      }
    }, 400);
  }

  drawComplete() {
    this.savePad();
  }

  savePad() {
    if (!this.signaturePad?.isEmpty()) {
      this.signature = this.signaturePad?.toDataURL() ?? null;
    }
    this.signatureChange.emit(this.signature);
  }

  clearPad(emitChange = true) {
    this.signaturePad?.clear();
    if (emitChange) {
      this.signatureChange.emit(this.signature);
    }
  }
}
