import { Injectable } from '@angular/core';
import { CUSTOMER } from '../constants/customer';
import { TERMS } from '../constants/terms';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';
import { Term } from '../interfaces/term';

@Injectable({
  providedIn: 'root'
})
export class TermsProviderService {

  constructor(private webProvider: WebProviderService) { }

  async get(onlySorted: boolean = false) {
    const terms = await this.webProvider.get<Term[]>(`${TERMS.API_URL}${!onlySorted ? '' : '?onlySorted=true'}`);
    assert(terms, 'Terms response was null.');
    return terms;
  }

  async getNewCustomer() {
    const terms = await this.webProvider.get<Term[]>(`${TERMS.API_URL}/NewCustomer`);
    assert(terms, 'Terms response was null.');
    return terms;
  }

  async getAvailable(customerID: number) {
    const terms = await this.webProvider.get<string[]>(`${CUSTOMER.API_URL}/${customerID}/terms`);
    assert(terms, 'Terms response was null.');
    return terms;
  }
}
