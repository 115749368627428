import { APIBASE } from './api-base';
import { environment } from 'src/environments/environment';

export class USER_ROLES extends APIBASE {
  static override local() {
    return `${environment.apiURL}/UserRoles`;
  }

  static override staging() {
    return `${environment.apiURL}/UserRoles`;
  }

  static override production() {
    return `${environment.apiURL}/UserRoles`;
  }
}
