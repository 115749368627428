import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedAngularMaterialModule } from './shared/modules/shared-angular-material/shared-angular-material.module';
import { CustomerSelectModule } from './pages/customer-select/customer-select.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { NavigationModule } from './shared/modules/navigation/navigation.module';
import { CustomerSummaryBarModule } from './shared/modules/customer-summary-bar/customer-summary-bar.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OrderEntryModule } from './pages/order-entry/order-entry.module';
import { SharedModule } from './shared/shared.module';
import { OrderSubmitModule } from './pages/order-submit/order-submit.module';
import { OrderListModule } from './pages/order-list/order-list.module';
import { MatNativeDateModule, NativeDateModule } from '@angular/material/core';
import { OrderViewModule } from './pages/order-view/order-view.module';
import { SignatureConfirmationModule } from './pages/signature-confirmation/signature-confirmation.module';
import { TemporaryLoginModule } from './pages/temporary-login/temporary-login.module';
import { NewCustomerEntryModule } from './pages/new-customer-entry/new-customer-entry.module';
import { PackingListModule } from './pages/packing-list/packing-list.module';
import { UserListModule } from './pages/user-list/user-list.module';
import { UserModule } from './pages/user/user.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedAngularMaterialModule,
        CustomerSelectModule,
        NavigationModule,
        DashboardModule,
        CustomerSummaryBarModule,
        OrderEntryModule,
        SharedModule,
        OrderSubmitModule,
        OrderListModule,
        NativeDateModule,
        MatNativeDateModule,
        OrderViewModule,
        SignatureConfirmationModule,
        TemporaryLoginModule,
        NewCustomerEntryModule,
        PackingListModule,
        UserListModule,
        UserModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
