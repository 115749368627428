import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyPipe } from './pipes/money.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { SharedAngularMaterialModule } from './modules/shared-angular-material/shared-angular-material.module';
import { CollectionSummaryComponent } from './components/collection-summary/collection-summary.component';
import { ShipToComponent } from './components/ship-to/ship-to.component';
import { OrderReviewSummaryComponent } from './components/order-review-summary/order-review-summary.component';
import { AddressComponent } from './components/address/address.component';
import { AddressSelectComponent } from './components/address-select/address-select.component';
import { AddressSelectDialogComponent } from './components/address-select-dialog/address-select-dialog.component';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { StatusInfoDialogComponent } from './components/status-info-dialog/status-info-dialog.component';
import { ProformaLineSummaryComponent } from './components/proforma-line-summary/proforma-line-summary.component';
import { StockBalanceLineSummaryComponent } from './components/stock-balance-line-summary/stock-balance-line-summary.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { StatusComponent } from './components/status/status.component';
import { ExpandableFieldDirective } from './directives/expandable-field/expandable-field.directive';
import { ItemImageComponent } from './components/item-image/item-image.component';
import { DuplicateLineItemDialogComponent } from './modules/edit-item/components/duplicate-line-item-dialog/duplicate-line-item-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArDenyOrderDialogComponent } from './components/ar-deny-order-dialog/ar-deny-order-dialog.component';
import { PreventPasteDirective } from './directives/prevent-paste/prevent-paste.directive';
import { ItemImageGalleryDialogComponent } from './components/item-image-gallery-dialog/item-image-gallery-dialog.component';
import { HideErroredDirective } from './directives/hide-errored/hide-errored.directive';
import { ViewItemDetailsComponent } from './components/view-item-details/view-item-details.component';
import { OrderSaveIndicatorComponent } from './components/order-save-indicator/order-save-indicator.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { StockBalanceCustomerStatsComponent } from './components/stock-balance-customer-stats/stock-balance-customer-stats.component';
import { ArHoldDialogComponent } from './components/ar-hold-dialog/ar-hold-dialog.component';
import { ExistingCustomerSelectDialogComponent } from './components/existing-customer-select-dialog/existing-customer-select-dialog.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { NewCustomerDiffDialogComponent } from './components/new-customer-diff-dialog/new-customer-diff-dialog.component';
import { NewCustomerFormComponent } from './components/new-customer-form/new-customer-form.component';
import { ApproveCustomerDialogComponent } from './components/approve-customer-dialog/approve-customer-dialog.component';
import { PatternEnforcerDirective } from './directives/pattern-enforcer/pattern-enforcer.directive';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { EditUserDialogComponent } from './components/edit-user-dialog/edit-user-dialog.component';
import { CustomerSelectDialogComponent } from './components/customer-select-dialog/customer-select-dialog.component';
import { CustomerAutoCompleteComponent } from './components/customer-auto-complete/customer-auto-complete.component';



@NgModule({
  declarations: [
    MoneyPipe,
    YesNoPipe,
    CollectionSummaryComponent,
    ShipToComponent,
    OrderReviewSummaryComponent,
    AddressComponent,
    AddressSelectComponent,
    AddressSelectDialogComponent,
    ShortDatePipe,
    StatusInfoDialogComponent,
    ProformaLineSummaryComponent,
    StockBalanceLineSummaryComponent,
    ConfirmDialogComponent,
    StatusComponent,
    ExpandableFieldDirective,
    ItemImageComponent,
    DuplicateLineItemDialogComponent,
    ArDenyOrderDialogComponent,
    PreventPasteDirective,
    ItemImageGalleryDialogComponent,
    HideErroredDirective,
    ViewItemDetailsComponent,
    OrderSaveIndicatorComponent,
    TimeAgoPipe,
    StockBalanceCustomerStatsComponent,
    ArHoldDialogComponent,
    ExistingCustomerSelectDialogComponent,
    LoadingIndicatorComponent,
    NewCustomerDiffDialogComponent,
    NewCustomerFormComponent,
    ApproveCustomerDialogComponent,
    PatternEnforcerDirective,
    PhoneInputComponent,
    EditUserDialogComponent,
    CustomerSelectDialogComponent,
    CustomerAutoCompleteComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MoneyPipe,
    YesNoPipe,
    CollectionSummaryComponent,
    ConfirmDialogComponent,
    ShipToComponent,
    OrderReviewSummaryComponent,
    AddressComponent,
    AddressSelectComponent,
    ShortDatePipe,
    StatusInfoDialogComponent,
    ProformaLineSummaryComponent,
    StockBalanceLineSummaryComponent,
    StatusComponent,
    ExpandableFieldDirective,
    ItemImageComponent,
    PreventPasteDirective,
    ViewItemDetailsComponent,
    OrderSaveIndicatorComponent,
    TimeAgoPipe,
    StockBalanceCustomerStatsComponent,
    ArHoldDialogComponent,
    ExistingCustomerSelectDialogComponent,
    LoadingIndicatorComponent,
    NewCustomerFormComponent,
    ApproveCustomerDialogComponent,
    PatternEnforcerDirective,
    PhoneInputComponent,
    CustomerAutoCompleteComponent
  ]
})
export class SharedModule { }
