import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { assert } from '../../utils/assert.util';
import { ArDenyOrderDialogComponent } from '../ar-deny-order-dialog/ar-deny-order-dialog.component';

@Component({
  selector: 'app-ar-hold-dialog',
  templateUrl: './ar-hold-dialog.component.html',
  styleUrls: ['./ar-hold-dialog.component.scss']
})
export class ArHoldDialogComponent implements OnInit {
  reasonFormControl = new FormControl(null, [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<ArDenyOrderDialogComponent, string>
  ) { }

  ngOnInit(): void {
  }

  hold() {
    if (this.reasonFormControl.valid) {
      assert(this.reasonFormControl.value, 'Reason was null.');
      this.dialogRef.close(this.reasonFormControl.value);
    } else {
      this.reasonFormControl.markAsTouched();
    }
  }
}
