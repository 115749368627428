import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-order-save-indicator',
  templateUrl: './order-save-indicator.component.html',
  styleUrls: ['./order-save-indicator.component.scss']
})
export class OrderSaveIndicatorComponent implements OnInit {
  @Input() isSaving = false;
  @Input() lastSaved: Date | string | null = null;
  @Input() hasUnsavedChanges = false;

  labelUpdateInterval: any = null;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    clearInterval(this.labelUpdateInterval);
  }

  ngOnChanges() {
    clearInterval(this.labelUpdateInterval);
    if (!this.isSaving && this.lastSaved) {
      this.labelUpdateInterval = setInterval(() => {
        if (this.lastSaved) {
          if (this.lastSaved.toString().toLowerCase().endsWith('z')) {
            this.lastSaved = new Date(this.lastSaved.toString().replace(/z|Z$/, ''));
          }
          this.lastSaved = new Date(this.lastSaved);
        }
      }, 60000);
    }
  }
}
