import { Injectable } from '@angular/core';
import { LastOrderedPerSkuResponseItem } from '../interfaces/last-ordered-per-sku-response';
import { QuantityPerStyleResponseItem } from '../interfaces/quantity-per-style-response';

@Injectable({
  providedIn: 'root'
})
export class OrderStatsService {

  private last15MonthsCustomer: QuantityPerStyleResponseItem[] | null = null;
  private last15MonthsStock: QuantityPerStyleResponseItem[] | null = null;
  private orderedDatesPerSKU: LastOrderedPerSkuResponseItem[] | null = null;
  orderedDatesPerSKUPromise: Promise<LastOrderedPerSkuResponseItem[]> | null = null;

  constructor() { }

  getLast15MonthsCustomer() {
    return this.last15MonthsCustomer;
  }

  getLast15MonthsStock() {
    return this.last15MonthsStock;
  }

  getOrderedDatesPerSKU() {
    return this.orderedDatesPerSKU;
  }

  setLast15MonthsCustomer(last15MonthsCustomer: QuantityPerStyleResponseItem[]) {
    this.last15MonthsCustomer = last15MonthsCustomer;
  }

  setLast15MonthsStock(last15MonthsStock: QuantityPerStyleResponseItem[]) {
    this.last15MonthsStock = last15MonthsStock;
  }

  setOrderedDatesPerSKU(orderedDatesPerSKU: LastOrderedPerSkuResponseItem[]) {
    this.orderedDatesPerSKU = orderedDatesPerSKU;
  }

  reset() {
    this.last15MonthsCustomer = null;
    this.last15MonthsStock = null;
    this.orderedDatesPerSKU = null;
  }
}
