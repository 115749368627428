import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { StockBalanceLineItemFactory } from 'src/app/shared/factories/stock-balance-line-item.factory';
import { BasicItem } from 'src/app/shared/interfaces/basic-item';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { AsyncAutoCompleteComponent } from 'src/app/shared/modules/auto-complete/components/async-auto-complete/async-auto-complete.component';
import { AutoCompleteComponent } from 'src/app/shared/modules/auto-complete/components/auto-complete/auto-complete.component';
import { AutoCompleteOption } from 'src/app/shared/modules/auto-complete/interfaces/auto-complete-option';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { DuplicateLineItemDialogComponent } from 'src/app/shared/modules/edit-item/components/duplicate-line-item-dialog/duplicate-line-item-dialog.component';
import { ItemProviderService } from 'src/app/shared/providers/item.provider.service';
import { OrderStatsService } from 'src/app/shared/services/order-stats.service';
import { StockBalanceLineItemsService } from 'src/app/shared/services/stock-balance-line-items.service';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { getEligibleLineItems, getTotal, getTotalQuantity } from 'src/app/shared/utils/order.util';

@Component({
  selector: 'app-stock-balance',
  templateUrl: './stock-balance.component.html',
  styleUrls: ['./stock-balance.component.scss']
})
export class StockBalanceComponent implements OnInit {
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @ViewChild(AsyncAutoCompleteComponent) autocomplete!: AsyncAutoCompleteComponent;
  options: Promise<AutoCompleteOption[]> = new Promise((res) => res([]));
  items: Promise<BasicItem[]> = new Promise((res) => res([]));
  lineItems: StockBalanceLineItem[] = [];
  autoCompleteClasses = "";
  searchableLineItemOptions: AutoCompleteOption[] = [];
  shouldHighlightDuplicates = false;
  autoCompleteTextChangeTimeout: any | null = null;

  get allEligibleLineItems(): LineItem[] {
    return getEligibleLineItems(this.lineItems);
  }

  get total(): number {
    return getTotal(this.lineItems);
  }

  get quantity() {
    return getTotalQuantity(this.lineItems);
  }

  get hasDuplicates() {
    return this.lineItems.some(lineItem => this.isDuplicateLineItem(lineItem));
  }

  get hasIncompleteFields() {
    return this.lineItems.some(lineItem => !lineItem.quantity || (lineItem.itemType === 'DiscontinuedCarvedItem' && !lineItem.freeformSKU) || (getLineItemOrderStatsRestrictionReason(lineItem) && !lineItem.returnReason));
  }

  constructor(
    private itemProvider: ItemProviderService,
    private lineItemFactory: StockBalanceLineItemFactory,
    private lineItemService: StockBalanceLineItemsService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private customerService: CustomerSummaryBarService,
    private orderStatsService: OrderStatsService
  ) { }

  getType(lineItem: LineItem) {
    if (lineItem.itemType === 'DiscontinuedCarvedItem') {
      return 'StockBalanceDiscontinuedCarvedItem';
    }
    return 'StockBalance'
  }

  ngOnInit(): void {
    this.lineItemService.subscribe((lineItems => {
      this.lineItems = lineItems;
      this.searchableLineItemOptions = this.lineItems.flatMap(lineItem => lineItem.item ? ({ index: lineItem.key, value: lineItem.item.sku }) : []);
    }));
  }

  lineitemsChanged(lineItems: StockBalanceLineItem[]) {
    this.lineItems = lineItems;
    this.lineItemService.set(lineItems);
  }

  autoCompleteTextChange(value: string | null) {
    clearInterval(this.autoCompleteTextChangeTimeout);
    if ((value?.length ?? 0) > 2) {
      this.autoCompleteTextChangeTimeout = setTimeout(async () => {
        this.items = this.itemProvider.getBasicStockBalanceItems(value);
        this.options = this.getAutoCompleteOptions(this.items)
      }, 500);
    } else {
      this.items = new Promise((res) => res([]));
      this.options = new Promise((res) => res([]));
    }
  }

  async getAutoCompleteOptions(items: Promise<BasicItem[]>) {
    const itemsResult = await items;
    const options = itemsResult.map(item => ({ index: item.id, value: item.sku }));
    return options;
  }

  async itemSelected(value: AutoCompleteOption | null) {
    const itemsResult = await this.items;
    const selectedItem = itemsResult.find(item => item.id === value?.index) ?? null;
    if (selectedItem) {
      this.isLoadingChange.emit(true);
      const customer = this.customerService.get();
      assert(customer, 'Customer was null');
      const item = await this.itemProvider.getSingle(selectedItem.id);
      const previouslyOrderedSKUs = await this.getPreviouslyOrderedSKUs();
      if (item.sku === 'Discontinued Carved Item' || !previouslyOrderedSKUs || previouslyOrderedSKUs.includes(item.sku)) {
        const selectedLineItem = this.lineItemFactory.create(item, customer);
        this.lineItems = this.lineItems.concat(selectedLineItem);
        this.lineItemService.add(selectedLineItem);
      } else {
        this.dialog.open(ConfirmDialogComponent, { data: { title: 'Stock Balance Item Not Allowed', body: 'The entered item has never been ordered. Please check the style and finger size and try again.', cancelButtonText: 'OK'} });
      }
      this.autocomplete.clear();
      this.isLoadingChange.emit(false);
    }
  }

  private async getPreviouslyOrderedSKUs() {
    const orderedDatesPerSKU = await this.orderStatsService.orderedDatesPerSKUPromise;
    const previouslyOrderedSKUs = orderedDatesPerSKU?.map(obj => obj.sku);
    return previouslyOrderedSKUs;
  }

  quickCopyLineItem(lineItem: StockBalanceLineItem) {
    this.duplicateLineItem(lineItem);
  }

  copyLineItem(lineItem: StockBalanceLineItem) {
    this.dialog.open(DuplicateLineItemDialogComponent).afterClosed().subscribe(copyQuantity => {
      this.duplicateLineItem(lineItem, copyQuantity);
    });
  }

  private duplicateLineItem(lineItem: StockBalanceLineItem, nTimes: number = 1) {
    const copiedLineItems = new Array(nTimes).fill(null).map(_ => Object.assign({}, lineItem, { key: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}` }));
    this.lineItems = copiedLineItems.concat(this.lineItems)
    this.lineItemService.set(this.lineItems);
  }

  lineItemChanged(changedLineItem: StockBalanceLineItem) {
    this.lineItemService.set(this.lineItems);
  }

  removeLineItem(removedLineItem: LineItem) {
    const dialogReference = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Remove line item?', body: 'Are you sure you want to remove this line item?', cancelButtonText: 'Cancel', confirmButtonText: 'Remove' } })
    dialogReference.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.lineItems = this.lineItems.filter(lineItem => lineItem.key !== removedLineItem.key);
        this.lineItemService.set(this.lineItems);
      } else if (removedLineItem.quantity <= 0) {
        removedLineItem.quantity = 1;
        const removedItemIndex = this.lineItems.findIndex(lineItem => lineItem.key === removedLineItem.key);
        this.lineItems[removedItemIndex] = Object.assign({}, this.lineItems[removedItemIndex]);
        this.lineItems = this.lineItems.concat();
        this.lineItemService.set(this.lineItems);
        this.changeDetector.detectChanges();
      }
    });
  }

  searchSelected(value: AutoCompleteOption | null) {
    const matchingLineItem = this.lineItems.find(lineItem => lineItem.item && lineItem.key === value?.index) ?? null;
    if (matchingLineItem) {
      const $matchingElement = document.querySelector(`[data-key="${matchingLineItem.key}"]`);
      if ($matchingElement) {
        window.scrollTo({ top: $matchingElement.getBoundingClientRect().top - 193, behavior: 'smooth' })
        const $editItemContainer = $matchingElement.querySelector('.EditItemContainer')
        $editItemContainer?.classList.toggle('animation', true);
        $editItemContainer?.addEventListener('animationend', () => {
          $editItemContainer.classList.toggle('animation', false);
        });
      }
    }
  }

  showDuplicates() {
    this.shouldHighlightDuplicates = true;
    this.lineItems = this.lineItems.reduce((newLineItems, lineItem) => {
      const duplicateItemIndex = newLineItems.findIndex(newLineItem => this.duplicateCompare(newLineItem, lineItem));
      if (duplicateItemIndex >= 0) {
        const itemsBeforeDuplicate = newLineItems.slice(0, duplicateItemIndex);
        const itemsAfterDuplicate = newLineItems.slice(duplicateItemIndex, newLineItems.length);

        return itemsBeforeDuplicate.concat(lineItem, itemsAfterDuplicate);
      } else {
        return newLineItems.concat(lineItem);
      }
    }, [] as StockBalanceLineItem[]);
  }


  isDuplicateLineItem(currentLineItem: StockBalanceLineItem) {
    return this.lineItems.some(lineItem => this.duplicateCompare(currentLineItem, lineItem));
  }

  private duplicateCompare(a: StockBalanceLineItem, b:  StockBalanceLineItem) {
    return a.key !== b.key && a.item && ((a.item.type !== 'DiscontinuedCarvedItem' && a.item.sku === b.item?.sku) || (a.item.type === 'DiscontinuedCarvedItem') && a.freeformSKU === b.freeformSKU);
  }
}
