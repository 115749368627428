import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { assert } from 'src/app/shared/utils/assert.util';
import { getThumbnailImageURL } from 'src/app/shared/utils/item.util';
import { getMetalString } from 'src/app/shared/utils/metal.util';

@Component({
  selector: 'app-view-item-details[lineItem]',
  templateUrl: './view-item-details.component.html',
  styleUrls: ['./view-item-details.component.scss']
})
export class ViewItemDetailsComponent implements OnInit {
  @Input() lineItem!: LineItem;

  get imageSrc(): string | null {
    if (this.lineItem.item) {
      return getThumbnailImageURL(this.lineItem.item);
    }
    return null;
  }

  get lineItemKarat(): string {
    assert(this.lineItem.selectedMetals, 'No selected metals for line item.')
    return getMetalString(this.lineItem.selectedMetals);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
