import { Injectable } from '@angular/core';
import { ITEM } from '../constants/item';
import { AvailableRingOptions } from '../interfaces/AvailableRingOptions';
import { BasicItem } from '../interfaces/basic-item';
import { Item } from '../interfaces/item';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class ItemProviderService {
  constructor(private webClient: WebProviderService) { }

  async getAll(): Promise<Item[]> {
    const result = await this.webClient.get<Item[]>(`${ITEM.API_URL}`);
    assert(result, 'Items response was null');
    return result;
  }

  async getAllBasic(search: string | null = null): Promise<BasicItem[]> {
    const result = await this.webClient.get<BasicItem[]>(`${ITEM.API_URL}/basic${search ? `?search=${search}` : ''}`);
    assert(result, 'Items response was null');
    return result;
  }

  async getBasicDiscontinuedCarvedItems(): Promise<BasicItem[]> {
    const result = await this.webClient.get<BasicItem[]>(`${ITEM.API_URL}/BasicDiscontinuedCarved`);
    assert(result, 'Items response was null');
    return result;
  }

  async getBasicStockBalanceItems(search: string | null = null): Promise<BasicItem[]> {
    const result = await this.webClient.get<BasicItem[]>(`${ITEM.API_URL}/BasicStockBalance${search ? `?search=${search}` : ''}`);
    assert(result, 'Items response was null');
    return result;
  }

  async getAllWithFamily(key: string): Promise<Item[]> {
    const result = await this.webClient.get<Item[]>(`${ITEM.API_URL}`, { params: { familyKey: key } });
    assert(result, 'Item was null for the given key');
    return result;
  }

  async getAvailableRingOptions(key: string): Promise<AvailableRingOptions[]> {
    const result = await this.webClient.get<AvailableRingOptions[]>(`${ITEM.API_URL}/availableRingOptions`, { params: { familyKey: key } });
    assert(result, 'Options was null for the given key');
    return result;
  }

  async getSingle(id: number) {
    const result = await this.webClient.get<Item>(`${ITEM.API_URL}/${id}`);
    assert(result, 'Item was null for the given ID');
    return result;
  }
}
