import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';

@Component({
  selector: 'app-proforma-line-summary[lineItem]',
  templateUrl: './proforma-line-summary.component.html',
  styleUrls: ['./proforma-line-summary.component.scss']
})
export class ProformaLineSummaryComponent implements OnInit {
  @Input() lineItem!: LineItem

  constructor() { }

  ngOnInit(): void {
  }
}
