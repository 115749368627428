<div class="ViewItemContainer Flexbox Flexbox--row">
  <div class="ItemContainerMeta Flexbox Flexbox--row">
    <div class="Flexbox Flexbox--column Flexbox--column-center">
      <span *ngIf="lineNumber">{{ lineNumber }}</span>
    </div>
    <app-item-image [item]="lineItem.item"></app-item-image>
    <div class="ItemDetailsContainer Flexbox Flexbox--column">
      <div class="Flexbox SKUContainer">
        <span class="SKU">
          <ng-container *ngIf="lineItem.item">{{ lineItem.item.sku }}</ng-container>
          <ng-container *ngIf="!lineItem.item">N/A</ng-container>
        </span>
        <span *ngIf="lineItem.poNumber" class="PO">PO: <b>{{ lineItem.poNumber }}</b></span>
      </div>
      <!-- <span class="ItemName">{{ lineItem.itemFamily.displayName }}</span> -->
    </div>
  </div>
  <div class="SummaryData Flexbox Flexbox--row">
    <app-proforma-line-summary [lineItem]="lineItem"></app-proforma-line-summary>
  </div>
  <!-- <ng-container *ngIf="type === 'Proforma' then proforma; else stockBalance"></ng-container> -->
  <!-- <ng-template #proforma><app-proforma-line-summary [lineItem]="lineItem"></app-proforma-line-summary></ng-template> -->
  <!-- <ng-template #stockBalance><app-stock-balance-line-summary [lineItem]="lineItem"></app-stock-balance-line-summary></ng-template> -->
</div>