import { Injectable } from '@angular/core';
import { Customer } from '../interfaces/customer';
import { Item } from '../interfaces/item';
import { ProformaLineItem } from '../interfaces/proforma-line-item';
import { LineItemFactory } from './line-item.factory';

@Injectable({
  providedIn: 'root'
})
export class ProformaLineItemFactory extends LineItemFactory<ProformaLineItem> {
  override create(item: Item, customer: Customer | null = null): ProformaLineItem {
    const lineItem = super.create(item, customer);
    return lineItem;
  }
}
