import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EditUserDialogComponent } from 'src/app/shared/components/edit-user-dialog/edit-user-dialog.component';
import { SalesRep } from 'src/app/shared/interfaces/sales-rep';
import { ManagedUser } from 'src/app/shared/interfaces/managed-user';
import { SalesRepProvidersService } from 'src/app/shared/providers/sales-rep.provider.service';
import { UsersProviderService } from 'src/app/shared/providers/users.provider.service';
import { EditUserDialogResult } from 'src/app/shared/interfaces/edit-user-dialog-result';
import { UpdateUserRequest } from 'src/app/shared/interfaces/update-user-request';
import { assert } from 'src/app/shared/utils/assert.util';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-user-root',
  templateUrl: './user-root.component.html',
  styleUrls: ['./user-root.component.scss']
})
export class UserRootComponent implements OnInit {
  user: ManagedUser | null = null;
  lastActive: string | null = null;
  isLoading = true;

  constructor(
    private usersProvider: UsersProviderService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const id = this.route.snapshot.params['userID'];
    this.user = await this.usersProvider.get(id);
    this.lastActive = await this.usersProvider.getLastActiveDate(id);
    this.isLoading = false;
  }

  editButtonClicked() {
    this.dialog.open(EditUserDialogComponent, {data: {user : this.user, showWarning: true} }).afterClosed().subscribe(async (userResult: EditUserDialogResult | undefined) => {
      assert(this.user, 'User was null');
      if (userResult) {
        const userRequest: UpdateUserRequest = {
          email: userResult.email,
          role: userResult.role,
          salesReps: userResult.salesReps.map(rep => rep.number),
          fullName: userResult.fullName
        };
        this.isLoading = true;
        await this.usersProvider.update(this.user.id, userRequest);
        window.location.reload();
      }
    });
  }

  changePasswordButtonClicked() {
    this.dialog.open(ChangePasswordDialogComponent, {data: this.user?.email}).afterClosed().subscribe(async (newPassword: string | undefined) => {
      assert(this.user, 'User was null');
      if (newPassword) {
        this.isLoading = true;
        await this.usersProvider.changePassword(this.user.id, newPassword);
        window.location.reload();
      }
    });
  }

  async inactivateButtonClicked() {
    assert(this.user, 'User was null');
    this.isLoading = true;
    await this.usersProvider.inactivate(this.user.id);
    window.location.reload();
  }

  async activateButtonClicked() {
    assert(this.user, 'User was null');
    this.isLoading = true;
    await this.usersProvider.activate(this.user.id);
    window.location.reload();
  }
}
