import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerSelectRootComponent } from './pages/customer-select/components/customer-select-root/customer-select-root.component';
import { DashboardRootComponent } from './pages/dashboard/components/dashboard-root/dashboard-root.component';
import { NotFoundRootComponent } from './pages/not-found/components/not-found-root/not-found-root.component';
import { OrderEntryRootComponent } from './pages/order-entry/components/order-entry-root/order-entry-root.component';
import { OrderListRootComponent } from './pages/order-list/components/order-list-root/order-list-root.component';
import { OrderSubmitRootComponent } from './pages/order-submit/components/order-submit-root/order-submit-root.component';
import { OrderViewRootComponent } from './pages/order-view/components/order-view-root/order-view-root.component';
import { SignatureConfirmationRootComponent } from './pages/signature-confirmation/components/signature-confirmation-root/signature-confirmation-root.component';
import { TemporaryLoginRootComponent } from './pages/temporary-login/components/temporary-login-root/temporary-login-root.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NewCustomerEntryRootComponent } from './pages/new-customer-entry/components/new-customer-entry-root/new-customer-entry-root.component';
import { PackingListRootComponent } from './pages/packing-list/components/packing-list-root/packing-list-root.component';
import { UserRootComponent } from './pages/user/components/user-root/user-root.component';
import { UserListRootComponent } from './pages/user-list/components/user-list-root/user-list-root.component';

const routes: Routes = [
  { path: 'login', component: TemporaryLoginRootComponent, data: { navBar: false } },
  { path: '', component: DashboardRootComponent, canActivate: [AuthGuard] },
  { path: 'order', component: CustomerSelectRootComponent, canActivate: [AuthGuard] },
  { path: 'order/edit/:orderID', component: OrderEntryRootComponent, data: { summaryBar: true, canAutoSave: true, canActivate: [AuthGuard] } },
  { path: 'order/edit', component: OrderEntryRootComponent, data: { summaryBar: true, canAutoSave: true, canActivate: [AuthGuard] } },
  { path: 'order/approval/:orderID', component: OrderSubmitRootComponent, data: { summaryBar: true, canAutoSave: true, canActivate: [AuthGuard] } },
  { path: 'order/view/:orderID', component: OrderViewRootComponent, data: { canActivate: [AuthGuard] } },
  { path: 'order/:orderViewToken', component: OrderViewRootComponent, data: { navBar: false } },
  { path: 'history', component: OrderListRootComponent, canActivate: [AuthGuard] },
  { path: 'signature/confirmation', component: SignatureConfirmationRootComponent, data: { navBar: false }, canActivate: [AuthGuard] },
  { path: 'newcustomer', component: NewCustomerEntryRootComponent, canActivate: [AuthGuard] },
  { path: 'order/:orderID/packingList', component: PackingListRootComponent },
  { path: 'users', component: UserListRootComponent, canActivate: [AuthGuard] },
  { path: 'users/:userID', component: UserRootComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundRootComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
