import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribableService<T> {
  private obj: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

  constructor() { }

  set(obj: T): void {
    this.obj.next(obj);
  }

  get(): T | null {
    const userValue = this.obj.value;
    return userValue;
  }

  delete(): void {
    this.obj.next(null);
  }

  subscribe(next?: (value: T | null) => void) {
    return this.obj.subscribe({ next: next });
  }
}
