import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { AutoCompleteOption } from '../../interfaces/auto-complete-option';

@Component({
  selector: 'app-async-auto-complete',
  templateUrl: './async-auto-complete.component.html',
  styleUrls: ['./async-auto-complete.component.scss']
})
export class AsyncAutoCompleteComponent implements OnInit {
  @Input() options: Promise<AutoCompleteOption[]> = new Promise(res => res([]));
  @Input() label?: string;
  @Input() itemLimit: number = 15;
  @Output() valueChanged = new EventEmitter<AutoCompleteOption | null>();
  @Output() focusChanged = new EventEmitter<boolean>();
  @Output() textChange = new EventEmitter<string | null>();
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  control = new FormControl<AutoCompleteOption | string>('');
  filteredOptions = new BehaviorSubject<AutoCompleteOption[]>([]);
  selectedOption: AutoCompleteOption | null = null;
  filteredOptionsSubscription?: Subscription
  enterMap = new BehaviorSubject<{ text: string | null, ready: boolean, options: AutoCompleteOption[] }>({ text: '', ready: false, options: [] });
  stopEnterHook = new Subject();
  isLoading = false;

  private get actualItemLimit() {
    if (this.itemLimit < 0) {
      return null;
    }
    return this.itemLimit;
  }

  private get controlValueString() {
    if (typeof this.control.value === 'object') {
      return this.control.value?.value ?? null
    } else {
      return this.control.value
    }
  }

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const optionResults = await this.options;
    this.isLoading = false;
    this.setFilteredOptions(optionResults);
  }

  setFilteredOptions(options: AutoCompleteOption[]) {
    this.filteredOptions.next(this.getFilteredOptions(options));
  }

  getFilteredOptions(options: AutoCompleteOption[]) {
    return options.slice(0, this.actualItemLimit ?? options.length)
  }

  async ngOnChanges(changes: { options: {currentValue: Promise<AutoCompleteOption[]>} }) {
    const controlVal = this.control.value;
    this.isLoading = true;
    this.options = changes.options.currentValue;
    this.autocomplete?.updatePosition();
    const optionResults = await this.options;
    this.isLoading = false;
    this.fireEnterMapChange(controlVal, true, optionResults);
    this.setFilteredOptions(optionResults);
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedOption = event.option.value
    this.valueChanged.emit(this.selectedOption);
  }

  inputKeyDown(e: KeyboardEvent) {
    if (this.control.value && e.key === 'Enter' && !this.selectedOption) {
      if ((this.controlValueString?.length ?? 0) > 2) {
        this.enterMap
        .pipe(takeUntil(this.stopEnterHook))
        .subscribe(mapObj => {
          if (mapObj.ready && mapObj.text === this.control.value) {
            const filteredOptions = this.getFilteredOptions(mapObj.options);
            this.selectedOption = filteredOptions[0];
            this.valueChanged.emit(this.selectedOption);
            this.stopEnterHook.next(null);
          }
        });
      }
    }
  }

  clear() {
    this.control.setValue('');
    this.selectedOption = null;
    this.autocomplete.writeValue('');
  }

  resetPanelSize() {
    this.autocomplete.closePanel();
    this.autocomplete.openPanel();
  }

  inputTextChanged(value: string | AutoCompleteOption | null) {
    this.fireEnterMapChange(value, false, []);
    this.textChange.emit(this.controlValueString);
  }

  fireEnterMapChange(value: string | AutoCompleteOption | null, ready: boolean, options: AutoCompleteOption[]) {
    if (typeof value === 'object') {
      return this.enterMap.next({ text: value?.value ?? null, ready, options });
    } else {
      return this.enterMap.next({ text: value, ready, options });
    }
  }
}
