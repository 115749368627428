import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { EditProformaItemPropertiesComponent } from './components/edit-proforma-item-properties/edit-proforma-item-properties.component';
import { EditStockBalanceItemPropertiesComponent } from './components/edit-stock-balance-item-properties/edit-stock-balance-item-properties.component';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { SharedModule } from '../../shared.module';
import { AsyncSelectModule } from '../async-select/async-select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProformaDisplayItemPropertiesComponent } from './components/edit-proforma-display-item-properties/edit-proforma-display-item-properties.component';
import { EditProformaProgramItemPropertiesComponent } from './components/edit-proforma-program-item-properties/edit-proforma-program-item-properties.component';
import { EditStockBalanceDiscontinuedCarvedItemPropertiesComponent } from './components/edit-stock-balance-discontinued-carved-item-properties/edit-stock-balance-discontinued-carved-item-properties.component';



@NgModule({
  declarations: [
    EditItemComponent,
    EditProformaItemPropertiesComponent,
    EditStockBalanceItemPropertiesComponent,
    EditProformaDisplayItemPropertiesComponent,
    EditProformaProgramItemPropertiesComponent,
    EditStockBalanceDiscontinuedCarvedItemPropertiesComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule,
    SharedModule,
    AsyncSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    EditItemComponent,
    EditProformaItemPropertiesComponent,
    EditStockBalanceItemPropertiesComponent,
  ]
})
export class EditItemModule { }
