<div [formGroup]="formGroup" class="StockBalance ItemPropertiesContainer Flexbox Flexbox--align-center">
  <!-- <span>Units Sold 15mo: {{ unitsSold15Months }}</span> -->
  <!-- <div class="Flexbox Flexbox--column">
    <span class="Eligible">Eligible: <b>{{ lineItem.isEligible | yesNo }}</b></span>
    <span *ngIf="!lineItem.isEligible">Reason: {{ eligibilityReason }}</span>
  </div> -->
  <mat-form-field appearance="outline">
    <mat-label>Qty:</mat-label>
    <input matInput type="number" formControlName="quantity" (change)="quantityEditEnded()">
    <div class="UpDownButtons Flexbox Flexbox--column">
      <button mat-icon-button (click)="upButtonClicked()"><mat-icon>expand_less</mat-icon></button>
      <button mat-icon-button (click)="downButtonClicked()"><mat-icon>expand_more</mat-icon></button>
    </div>
  </mat-form-field>
  <mat-form-field *ngIf="hasOrderStatsRestriction" appearance="outline" class="ReturnReason">
    <mat-label>Return Reason:</mat-label>
    <input matInput type="text" formControlName="returnReason" maxlength="100">
  </mat-form-field>
</div>