import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Customer } from 'src/app/shared/interfaces/customer';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { assert } from 'src/app/shared/utils/assert.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { getLineItemPricingObject } from 'src/app/shared/utils/pricing.util';
import { CustomerSummaryBarService } from '../../../customer-summary-bar/services/customer-summary-bar.service';

@Component({
  selector: 'app-edit-stock-balance-item-properties[lineItem]',
  templateUrl: './edit-stock-balance-item-properties.component.html',
  styleUrls: ['./edit-stock-balance-item-properties.component.scss']
})
export class EditStockBalanceItemPropertiesComponent implements OnInit {
  @Input() lineItem!: StockBalanceLineItem
  @Output() lineItemChange = new EventEmitter<LineItem>();
  eligibilityReason: string = "";
  // unitsSold15Months: number = 0;
  private customer: Customer | null = null;

  get hasOrderStatsRestriction() {
    return getLineItemOrderStatsRestrictionReason(this.lineItem);
  }

  formGroup = this.formBuilder.group({
    quantity: [this.lineItem?.quantity, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
    returnReason: [this.lineItem?.returnReason, [Validators.required]]
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity, { emitEvent: false });
    this.formGroup.controls.returnReason.setValue(this.lineItem.returnReason, { emitEvent: false });

    this.formGroup.controls.quantity.valueChanges.subscribe((value) => {
      this.lineItem.quantity = value ?? 0;
    });

    this.formGroup.controls.returnReason.valueChanges.subscribe((value) => {
      this.lineItem.returnReason = value;
      this.lineItemChange.emit(this.lineItem);
    });

    this.formGroup.controls.returnReason.markAsTouched();
  }

  upButtonClicked() {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity + 1);
    this.lineItemChange.emit(this.lineItem);
  }

  downButtonClicked() {
    this.formGroup.controls.quantity.setValue(this.lineItem.quantity - 1);
    this.lineItemChange.emit(this.lineItem);
  }

  quantityEditEnded() {
    if (this.lineItem.quantity === null) {
      this.lineItem.quantity = 0;
    }
    this.lineItemChange.emit(this.lineItem);
  }
}
