import { environment } from 'src/environments/environment';

export class APIBASE {
  static get API_URL() {
    if (environment.production) {
      return this.production();
    } else if (environment.type === 'local') {
      return this.local();
    } else if (environment.type === 'staging') {
      return this.staging();
    }
    throw 'Unsupported environment type'
  }

  static local(): string {
    throw 'Not implemented'
  }

  static production(): string {
    throw 'Not implemented'
  }

  static staging(): string {
    throw 'Not implemented'
  }
}
