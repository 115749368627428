<div class="ViewItemContainer Flexbox Flexbox--row">
  <div class="ItemContainerMeta Flexbox Flexbox--row">
    <div *ngIf="lineNumber" class="Flexbox Flexbox--column Flexbox--column-center">
      <span>{{ lineNumber }}</span>
    </div>
    <app-item-image [item]="lineItem.item" [isEligible]="lineItem.isApproved !== null"></app-item-image>
    <div class="ItemDetailsContainer Flexbox Flexbox--column">
      <div class="Flexbox SKUContainer">
        <div>
          <span [ngClass]="lineItem.isApproved === false ? 'Strikethrough' : ''" class="SKU">
            <ng-container *ngIf="lineItem.item">{{ lineItem.item.sku }}</ng-container>
            <pdf417barcode *ngIf="showBarcode && lineItem.item" [text]="lineItem.item.sku" [opts]="{pxw: 1, pxh: 1}"></pdf417barcode>
            <ng-container *ngIf="!lineItem.item">N/A</ng-container>
          </span>
        </div>
        <span *ngIf="lineItem.returnReason" class="ReturnReason">Return Reason: <b>{{ lineItem.returnReason }}</b></span>
      </div>
      <!-- <span class="ItemName">{{ lineItem.itemFamily.displayName }}</span> -->
      <div class="PropertiesContainer Flexbox" *ngIf="lineItem.freeformSKU || (!canViewLineAllowDeny && lineItem.isApproved === false)">
        <span *ngIf="lineItem.freeformSKU" class="Eligible">SKU: <b>{{ lineItem.freeformSKU | uppercase }}</b></span>
        <div *ngIf="!canViewLineAllowDeny  && lineItem.isApproved === false">
          <span class="Denied">Denied</span>
          <span> -- {{ lineItem.deniedReason }}</span>
        </div>
        <!-- <span class="Eligible">Eligible: <b>{{ lineItem.isEligible | yesNo }}</b></span> -->
      </div>
      <ng-container *ngIf="lineItem.itemType !== 'DiscontinuedCarvedItem'">
        <app-stock-balance-customer-stats *ngIf="user && lineItem && shouldShowOrderStats" [lineItem]="lineItem"></app-stock-balance-customer-stats>
      </ng-container>
    </div>
    <div *ngIf="canViewLineAllowDeny" [formGroup]="formGroup" class="AllowDenyContainer">
      <mat-form-field appearance="outline">
        <mat-label>Allow/Deny</mat-label>
        <mat-select formControlName="allowDeny">
          <mat-option [value]="true">Allow</mat-option>
          <mat-option [value]="false">Deny</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="lineItem.isApproved === false">
        <mat-label>Denial Reason</mat-label>
        <input matInput type="text" formControlName="reason" (change)="deniedReasonChanged()">
      </mat-form-field>
    </div>
  </div>
  <div class="SummaryData Flexbox Flexbox--row">
    <app-stock-balance-line-summary [lineItem]="lineItem" [type]="type === 'return' ? 'qty' : 'all'"></app-stock-balance-line-summary>
  </div>
</div>