import { Injectable } from '@angular/core';
import { ORDERSTATS } from '../constants/order-stats';
import { LastOrderedPerSkuResponseItem } from '../interfaces/last-ordered-per-sku-response';
import { QuantityPerStyleResponseItem } from '../interfaces/quantity-per-style-response';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class OrderStatsProviderService {

  constructor(
    private webProvider: WebProviderService
  ) { }

  async GetLast15MonthsOrderPerStyle(customerNumber: string, orderType: 'stock' | 'customer') {
    const results = await this.webProvider.get<QuantityPerStyleResponseItem[]>(`${ORDERSTATS.API_URL}/${customerNumber}/orderstats/last15months?ordertype=${orderType}`);
    assert(results, 'No order stats for the given customer and order type');
    return results;
  }

  async GetLastStockOrderedPerSKU(customerNumber: string) {
    const results = await this.webProvider.get<LastOrderedPerSkuResponseItem[]>(`${ORDERSTATS.API_URL}/${customerNumber}/orderstats/laststockorder`);
    assert(results, 'No order stats for the given customer and order type');
    return results;
  }

  async GetLastOrderedPerSKU(customerNumber: string) {
    const results = await this.webProvider.get<LastOrderedPerSkuResponseItem[]>(`${ORDERSTATS.API_URL}/${customerNumber}/orderstats/lastorder`);
    assert(results, 'No order stats for the given customer and order type');
    return results;
  }
}
