import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SalesRep } from '../../interfaces/sales-rep';
import { compareOptions } from '../../utils/select.util';
import { ManagedUser } from '../../interfaces/managed-user';
import { EditUserDialogResult } from '../../interfaces/edit-user-dialog-result';
import { assert } from '../../utils/assert.util';
import { SalesRepProvidersService } from '../../providers/sales-rep.provider.service';
import { UserRolesProviderService } from '../../providers/user-roles.provider.service';
import { EditUserDialogData } from '../../interfaces/edit-user-dialog-data';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {
  isLoading = false;
  isPasswordVisible = false;
  roles: string[] = [];
  reps: SalesRep[] = [];

  get user() {
    return this.dialogData?.user ?? null;
  }

  get shouldShowWarning() {
    return this.dialogData?.shouldShowWarning ?? false;
  }

  userFormGroup = this.formBuilder.group({
    email: [this.user?.email ?? null, [Validators.required, Validators.email]],
    password: [null as string | null, [Validators.required]],
    fullName: [this.user?.fullName ?? null, [Validators.required]],
    role: [this.user?.role ?? null, [Validators.required]],
    reps: [this.user?.salesReps ?? []]
  });
  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent, EditUserDialogResult>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EditUserDialogData | null,
    private formBuilder: FormBuilder,
    private repsProvider: SalesRepProvidersService,
    private rolesProvider: UserRolesProviderService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.reps = await this.repsProvider.getAll();
    this.roles = await this.rolesProvider.get();

    if (this.user) {
      this.userFormGroup.controls.password.disable();
      this.userFormGroup.setValue({
        email: this.user.email,
        password: null,
        fullName: this.user.fullName,
        role: this.user.role,
        reps: this.user.salesReps
      });
    }
    this.isLoading = false;
  }

  saveButtonClicked() {
    const userFormValues = this.userFormGroup.getRawValue();
    if (!this.userFormGroup.invalid) {
      assert(userFormValues.email && userFormValues.fullName && userFormValues.role, "Invalid form");
      assert(this.userFormGroup.controls.password.disabled || userFormValues.password, "Invalid password");
      const result: EditUserDialogResult = {
        email: userFormValues.email,
        password: userFormValues.password ?? '',
        fullName: userFormValues.fullName,
        role: userFormValues.role,
        salesReps: userFormValues.reps ?? []
      }
      this.dialogRef.close(result);
    } else {
      console.error('Invalid form');
    }
  }

  getError(formControl: AbstractControl) {
    if (formControl.hasError('required')) {
      return 'Please enter a value for this field'
    }

    if (formControl.hasError('email')) {
      return 'The email entered is not valid'
    }

    if (formControl.hasError('pattern')) {
      return 'The value entered is not valid';
    }

    const otherErrors = Object.keys(formControl.errors ?? {})

    if (otherErrors.length > 0) {
      const error = formControl.errors?.[otherErrors[0]];
      return error;
    }

    return '';
  }

  repComparator(option: SalesRep | null, value: SalesRep | null) {
    return option?.number === value?.number;
  }
}
