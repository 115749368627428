import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { StockBalanceLineItemsProviderService } from 'src/app/shared/providers/stock-balance-line-items.provider.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { getThumbnailImageURL } from 'src/app/shared/utils/item.util';

@Component({
  selector: 'app-view-stock-balance-item[lineItem][stockBalanceKey]',
  templateUrl: './view-stock-balance-item.component.html',
  styleUrls: ['./view-stock-balance-item.component.scss']
})
export class ViewStockBalanceItemComponent implements OnInit {
  @Input() lineItem!: StockBalanceLineItem;
  @Input() stockBalanceKey: string | null = null;
  @Input() lineNumber: number | null = null;
  @Input() orderStatus: string | null = null;
  @Input() type: 'normal' | 'return' = 'normal';
  @Input() showBarcode: boolean = false;

  formGroup = this.formBuilder.group({
    allowDeny: [this.lineItem?.isApproved, [Validators.required]],
    reason: [this.lineItem?.deniedReason, Validators.required]
  })

  get canViewLineAllowDeny() {
    const order = this.orderService.get();
    return !order && this.orderStatus && ['Pending AR', 'AR Hold'].includes(this.orderStatus) && (this.userService.isAR() || this.userService.isAdmin());
  }

  get shouldShowOrderStats() {
    return this.orderStatus && this.orderStatus !== 'Processing' && this.orderStatus !== 'Shipped' && this.orderStatus !== 'Delivered';
  }

  get user() {
    return this.userService.get();
  }

  get imageSrc(): string | null {
    if (this.lineItem.item) {
      return getThumbnailImageURL(this.lineItem.item);
    }
    return null;
  }

  constructor(
    private userService: UserService,
    private orderService: OrderService,
    private lineItemProvider: StockBalanceLineItemsProviderService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formGroup.controls.allowDeny.setValue(this.lineItem.isApproved, { emitEvent: false });
    this.formGroup.controls.reason.setValue(this.lineItem.deniedReason, { emitEvent: false });

    this.formGroup.controls.allowDeny.markAsTouched();
    this.formGroup.controls.reason.markAsTouched();

    this.formGroup.controls.allowDeny.valueChanges.subscribe(isApproved => {
      this.lineItem.isApproved = isApproved;
      this.formGroup.controls.reason.setValue(null);
      assert(this.stockBalanceKey, 'No stock balance key provided.');
      if (this.lineItem.isApproved) {
        this.lineItemProvider.approve(this.stockBalanceKey, this.lineItem.key);
      }
    });

    this.formGroup.controls.reason.valueChanges.subscribe(reason => {
      this.lineItem.deniedReason = reason;
    });
  }

  deniedReasonChanged() {
    assert(this.stockBalanceKey, 'No stock balance key provided.');
    assert(this.lineItem.deniedReason, 'No denied reason provided.');
    if (!this.lineItem.isApproved) {
      this.lineItemProvider.deny(this.stockBalanceKey, this.lineItem.key, this.lineItem.deniedReason);
    }
  }
}
