import { Component, Input, OnInit } from '@angular/core';
import { getStatusData } from '../../utils/status.util';

@Component({
  selector: 'app-status[status]',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @Input() status!: string

  get progress() {
    return getStatusData(this.status);
  }

  get filledProgressBars() {
    return Array(this.progress.progress).fill(null);
  }

  get emptyProgressBars() {
    return  Array(4 - this.progress.progress).fill(null);
  }

  constructor() { }

  ngOnInit(): void {
  }
}
