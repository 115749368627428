import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})

export class JWTUserMapper {

  constructor(private cookieService: CookieService) { }

  get() {
    try {
      const encodedJWT = this.cookieService.get('jwtBearerToken');
      const user = jwt_decode<User>(encodedJWT);
      return user;
    } catch {
      return null;
    }
  }
}
