import { environment } from 'src/environments/environment';
import { APIBASE } from './api-base';

export class LOGIN extends APIBASE {
  static override local() {
    return `${environment.apiURL}/authentication`;
  }

  static override staging() {
    return `${environment.apiURL}/authentication`;
  }

  static override production() {
    return `${environment.apiURL}/authentication`;
  }
}
