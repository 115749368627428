import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, Subject } from 'rxjs';
import { formatPhone } from '../../utils/text-formatters.util';
import { compareOptions } from '../../utils/select.util';
import { getAllPhoneCountryCodeInfo, getPhoneCountryCodeInfoWithCountryCode } from '../../utils/address.util';
import { CountryCodeSelectOption } from './interfaces/country-code-select-option';
import { CountryCodeInfo } from '../../interfaces/country-code-info';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { assert } from '../../utils/assert.util';
import { PhoneInputValidators } from './validators/phone-input-validators';

// https://material.angular.io/guide/creating-a-custom-form-field-control

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputComponent }],
  host: {
    '[id]': 'id',
  },
})
export class PhoneInputComponent implements MatFormFieldControl<string>, ControlValueAccessor {
  static nextId = 0;
  private _required = false;
  private _disabled = false;
  private _touched: boolean = false;
  private _placeholder: string = "Phone";
  private _shouldFocusPhone = false;
  private get _allCountryCodes () {
    return getAllPhoneCountryCodeInfo().map(countryCodeInfo => countryCodeInfo.countryCode);
  }
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  @ViewChild('countryCode', { read: ElementRef }) countryCodeInput!: ElementRef<HTMLInputElement>;
  @ViewChild('phone', { read: ElementRef }) phoneInput!: ElementRef<HTMLInputElement>;
  parts: FormGroup<{
    countryCode: FormControl<string | null>;
    phone: FormControl<string | null>;
  }>;

  @Input() defaultCountryCode: string | number | null = null;
  @Input()
  get value(): string | null {
    const parts = this.parts.getRawValue();
    return [parts.countryCode?.replace(/^\+/,''), parts.phone].filter(part => !!part).join('');
  }
  set value(val: string | null) {
    const sanitizedVal = val?.replace(/\s/g, '')?.replace(/^\+/, '');
    const phone = !sanitizedVal ? null : parsePhoneNumber(`+${sanitizedVal}`);
    const phoneReplacerRegex = new RegExp(`^\\+${phone?.countryCode ?? ''}\s*`);
    const phoneString = phone?.number?.international?.replace(phoneReplacerRegex, '')?.replace(/[()-]/g, ' ')?.replace(/\s{2,}/, ' ')?.trim() ?? null;
    const countryCode = !phone?.countryCode ? null : `+${phone.countryCode.toString()}`;
    this.parts.setValue({ countryCode: countryCode, phone: phoneString }, { emitEvent: false });
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  @Input('aria-describedby') userAriaDescribedBy: string | undefined;
  stateChanges = new Subject<void>();
  @HostBinding() id = `phone-input-${PhoneInputComponent.nextId++}`;
  focused: boolean = false;
  get touched(): boolean {
    return this._touched;
  }
  set touched(_touched: boolean) {
    this._touched = _touched;
    this.stateChanges.next();
  }
  get empty(): boolean {
    const parts = this.parts.value;
    return !parts.countryCode && !parts.phone;
  }
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  get errorState(): boolean {
    return (this.ngControl.errors || this.parts.invalid) && this.touched;
  }
  controlType: string = 'phone-input';
  autofilled?: boolean | undefined;
  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.PhoneInputContainer')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input')?.focus();
    }
  }
  onChange = (_: any) => {}
  onTouched = () => {};
  selectedCountryCode: CountryCodeSelectOption | null = null;
  filteredCountryCodes = new BehaviorSubject< CountryCodeSelectOption[]>([]);

  constructor(
      private formBuilder: FormBuilder,
      private _elementRef: ElementRef<HTMLElement>,
      private _focusMonitor: FocusMonitor,
      @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
      @Optional() @Self() public ngControl: NgControl,
    ) {
      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
      }

      this.parts = this.formBuilder.group({
        countryCode: ['', [Validators.required, Validators.pattern(/^\+\d+/), PhoneInputValidators.isCountryCodeValid(this._allCountryCodes)]],
        phone: ['', [Validators.required, PhoneInputValidators.isPhoneValid(4)]],
      });

      if (this.defaultCountryCode) {
        const defaultCountryCodeOption = this.getCountryCodeOptionWithCountryCode(+this.defaultCountryCode);
        this.selectedCountryCode = defaultCountryCodeOption;
        this.parts.controls.countryCode.setValue(`+${this.selectedCountryCode.value}`);
      }

      this.parts.valueChanges.subscribe((_) => this.onChange(this.value));
      this.parts.valueChanges.subscribe(this.onFormChanged.bind(this));

      this.setupSelectOptions();

      this.stateChanges.subscribe(() => {
        this.setFormControlErrors();
      });

      if (this.ngControl && this.ngControl.control) {
        const controlMarkAsTouched = this.ngControl.control.markAllAsTouched;
        this.ngControl.control.markAllAsTouched = () => {
          this.touched = true;
          this.parts.markAllAsTouched();
          this.setFormControlErrors();
          controlMarkAsTouched.call(this.ngControl.control);
        };
      }
    }

  setupSelectOptions() {
    this.filteredCountryCodes.next(this.getFilteredCountryCodes(this.parts.controls.countryCode.value).map(this.buildCountryCodeSelectOption));
    this.parts.controls.countryCode.valueChanges.subscribe(v => {
      if (!v) {
        this.filteredCountryCodes.next(getAllPhoneCountryCodeInfo().map(this.buildCountryCodeSelectOption));
      } else if (typeof v === 'string' && v.length > 0) {
        const matchingResults = this.getFilteredCountryCodes(v)
        this.filteredCountryCodes.next(matchingResults.map(this.buildCountryCodeSelectOption));
      } else if (v && typeof v === 'object') {
        this.filteredCountryCodes.next([v]);
      } else {
        this.filteredCountryCodes.next([]);
      }
    });
  }

  getFilteredCountryCodes(v: string | null) {
    const countryCodeInfo = getAllPhoneCountryCodeInfo();
    if (v) {
      const startsWithResult = countryCodeInfo.filter(info => info.countryCode.toString().startsWith(v.replace('+', '')) || info.country.toLowerCase().startsWith(v.toLowerCase()) || info.countryISO.toLowerCase().startsWith(v.toLowerCase()));
      const includesResults = countryCodeInfo.filter(info => info.countryCode.toString().includes(v.replace('+', '')) || info.country.toLowerCase().includes(v.toLowerCase()) || info.countryISO.toLowerCase().includes(v.toLowerCase()));
      const remainingIncludesResults = includesResults.filter(result => !startsWithResult.map(startsWith => startsWith.countryCode).includes(result.countryCode))
      const matchingResults = startsWithResult.concat(remainingIncludesResults);
      return matchingResults;
    } else {
      return countryCodeInfo;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  writeValue(val: string | null): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  onFormChanged() {
    const countryCode = this.parts.controls.countryCode.value;
    assert(countryCode, 'Country code was null');
    const countryCodeInt = +countryCode.replace('+', '');
    const formattedPhone = formatPhone(this.parts.controls.phone.value, countryCodeInt);
    if (typeof formattedPhone !== 'undefined' && formattedPhone !== null) {
      this.parts.controls.phone.setValue(formattedPhone, { emitEvent: false });
    }
    this.setFormControlErrors();
  }

  setFormControlErrors() {
    if (this.ngControl.errors || this.parts.controls.countryCode.errors || this.parts.controls.phone.errors) {
      this.ngControl.control?.setErrors(Object.assign({}, this.ngControl.errors, this.parts.controls.countryCode.errors, this.parts.controls.phone.errors));
    } else {
      this.ngControl.control?.setErrors(null);
    }
  }

  optionComparator(option: CountryCodeSelectOption | null, value: CountryCodeSelectOption | null) {
    return compareOptions(option, value);
  }

  getCountryCodeOptionWithCountryCode(countryCode: number) {
    const countryCodeInfo = getPhoneCountryCodeInfoWithCountryCode(countryCode);
    const countryCodeOption = this.buildCountryCodeSelectOption(countryCodeInfo);
    return countryCodeOption;
  }

  private buildCountryCodeSelectOption(countryCodeInfo: CountryCodeInfo) {
    const countryCodeSelectOption: CountryCodeSelectOption = {
      index: countryCodeInfo.countryCode,
      value: countryCodeInfo.countryCode.toString(),
      displayValue: `+${countryCodeInfo.countryCode} ${countryCodeInfo.country}`
    }
    return countryCodeSelectOption;
  }

  countryCodeSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedCountryCode = event.option.value.value
    this._shouldFocusPhone = true;
  }

  onCountryCodePanelClosed() {
    if (this._shouldFocusPhone) {
      this.phoneInput.nativeElement.focus();
      this._shouldFocusPhone = false;
    }
  }

  countryCodeInputKeyDown(e: KeyboardEvent) {
    if (this.countryCodeInput.nativeElement.value && (e.key === 'Enter' || e.key === 'Tab')) {
      this.parts.controls.countryCode.setValue(`+${this.filteredCountryCodes.value[0].value}`);
      this.autocomplete.closePanel();
    }
  }
}
