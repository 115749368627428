<h1 mat-dialog-title>User</h1>
<div mat-dialog-content>
  <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
  <div class="Flexbox Flexbox--column MainDialogContent">
    <div class="Flexbox Flexbox--column Form">
      <div>
        <p>
          <span class="Warning">Editing users will invalidate all active sessions.</span>
        </p>
      </div>
      <div class="Flexbox Flexbox--column" [formGroup]="userFormGroup">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email">
          <mat-error *ngIf="userFormGroup.controls.email.invalid">{{getError(userFormGroup.controls.email)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="userFormGroup.controls.password.enabled">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="!isPasswordVisible ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="isPasswordVisible = !isPasswordVisible"><mat-icon><ng-container *ngIf="!isPasswordVisible">visibility</ng-container><ng-container *ngIf="isPasswordVisible">visibility_off</ng-container></mat-icon></button>
          <mat-error *ngIf="userFormGroup.controls.password.invalid">{{getError(userFormGroup.controls.password)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Full Name</mat-label>
          <input matInput formControlName="fullName" type="text">
          <mat-error *ngIf="userFormGroup.controls.fullName.invalid">{{getError(userFormGroup.controls.fullName)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Reps</mat-label>
          <mat-select [compareWith]="repComparator" formControlName="reps" multiple>
            <mat-option *ngFor="let rep of reps" [value]="rep">{{ rep.number }}-{{ rep.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions class="Flexbox">
  <button mat-raised-button [mat-dialog-close]>Cancel</button>
  <button mat-raised-button color="accent" (click)="saveButtonClicked()">Save</button>
</mat-dialog-actions>
