import { Injectable } from '@angular/core';
import { WebProviderService } from './web.provider.service';
import { SALESREPS } from '../constants/sales-reps';
import { assert } from '../utils/assert.util';
import { SalesRep } from '../interfaces/sales-rep';

@Injectable({
  providedIn: 'root'
})
export class SalesRepProvidersService {

  constructor(
    private webProvider: WebProviderService
  ) { }

  async getReps(reps: number[]) {
    const results = await this.webProvider.get<SalesRep[]>(`${SALESREPS.API_URL}`);
    assert(results, 'Reps was null');
    return results;
  }

  async getAll(username?: string) {
    const results = await this.webProvider.get<SalesRep[]>(`${SALESREPS.API_URL}/all${username ? `?username=${username}` : ''}`);
    assert(results, 'Reps was null');
    return results;
  }
}
