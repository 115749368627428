import { Injectable } from '@angular/core';
import { ORDER } from '../constants/order';
import { WebProviderService } from './web.provider.service';
import { Order } from '../interfaces/order';
import { OrderCreateRequest } from '../interfaces/order-create-request';
import { OrderUpdateRequest } from '../interfaces/order-update-request';
import { assert } from '../utils/assert.util';
import { BasicOrder } from '../interfaces/BasicOrder';
import { OrderType } from '../types/order-type';

@Injectable({
  providedIn: 'root'
})
export class OrderProviderService {
  constructor(
    private webClient: WebProviderService
  ) { }

  async getAll() {
    const result = await this.webClient.get<Order[]>(`${ORDER.API_URL}`);
    assert(result, 'Orders response was null');
    return result;
  }

  async getAllBasic() {
    const result = await this.webClient.get<BasicOrder[]>(`${ORDER.API_URL}/basic`);
    assert(result, 'Orders response was null');
    return result;
  }

  async getAllDeletedExpired() {
    const result = await this.webClient.get<BasicOrder[]>(`${ORDER.API_URL}/basic/deletedExpired`);
    assert(result, 'Orders response was null');
    return result;
  }

  async getWithSearch(searchString: string) {
    const result = await this.webClient.get<Order[]>(`${ORDER.API_URL}?search=${searchString}`);
    assert(result, 'Orders search response was null');
    return result;
  }

  async get(key: string) {
    const result = await this.webClient.get<Order>(`${ORDER.API_URL}/${key}`);
    assert(result, 'Order was null for given key.');
    return result;
  }

  async getWithViewToken(viewToken: string) {
    const result = await this.webClient.get<Order | null>(`${ORDER.API_URL}/view/${viewToken}`);
    return result;
  }

  async create(createRequest: OrderCreateRequest) {
    const result = await this.webClient.post<Order>(`${ORDER.API_URL}`, createRequest);
    assert(result, 'Created order response was null');
    return result;
  }

  async update(key: string, updateRequest: OrderUpdateRequest) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}`, updateRequest);
    assert(result, 'Updated order response was null');
    return result;
  }

  async delete(key: string) {
    const result = await this.webClient.delete<Order>(`${ORDER.API_URL}/${key}`);
    return result;
  }

  async restoreDraft(key: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/restoreDraft`);
    return result;
  }

  async restoreOrder(key: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/restoreOrder`);
    return result;
  }

  async convertToOrder(key: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/convert`);
    return result;
  }

  async approve(key: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/approve`);
    return result;
  }

  async retailerApprove(key: string, signature: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/retailerApprove`, { signature });
    return result;
  }

  async deny(key: string, reason: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/deny`, { reason });
    return result;
  }

  async hold(key: string, reason: string) {
    const result = await this.webClient.put<Order>(`${ORDER.API_URL}/${key}/hold`, { reason });
    return result;
  }

  async setCustomer(key: string, customerID: number, addressID: number) {
    await this.webClient.put(`${ORDER.API_URL}/${key}/customer`, { customerID, addressID });
  }

  async changeType(key:string, type: OrderType) {
    await this.webClient.put(`${ORDER.API_URL}/${key}/changeType`, { type });
  }
}
