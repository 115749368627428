<table [ngClass]="lineItem.isApproved === false ? 'Strikethrough' : ''" class="ItemSummaryInfo">
  <tr>
    <td *ngIf="type === 'all'">Line Qty</td>
    <td *ngIf="type === 'qty'">Qty</td>
    <td class="SummaryValue">{{ lineItem.quantity }}</td>
  </tr>
  <ng-container *ngIf="type === 'all'">
    <tr>
      <td>Unit Credit</td>
      <td class="SummaryValue">{{ lineItem.unitRate | money }}</td>
    </tr>
    <tr>
      <td>Ext. Disc</td>
      <td class="SummaryValue" [ngClass]="lineItem.extendedDiscount < 0 ? 'mat-critical' : ''">{{ lineItem.extendedDiscount | money }}</td>
    </tr>
    <ng-container *ngIf="lineItem.itemType === 'DiscontinuedCarvedItem' then discontinuedCarvedItem;else item"></ng-container>
  </ng-container>
</table>

<ng-template #item>
  <tr>
    <td>Stock Bal. Credit</td>
    <td class="SummaryValue">{{ lineItem.extendedTotal | money }}</td>
  </tr>
</ng-template>

<ng-template #discontinuedCarvedItem>
  <tr>
    <td>Stock Bal. Credit</td>
    <td class="SummaryValue">TBD</td>
  </tr>
</ng-template>
