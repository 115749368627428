<ng-container *ngIf="lineItem.areOrderStatsLoaded then statsTemplate else loadingTemplate"></ng-container>
<ng-template #statsTemplate>
  <div class="StatsContainer Flexbox Flexbox--row">
    <span>Special Ordered Last 15 Months: <b>{{ lineItem.recentCustomerOrderedCount ?? 0 }}</b></span>
    <span>Last Stock Order: <b> <ng-container *ngIf="lineItem.lastStockOrdered">{{ lineItem.lastStockOrdered | shortDate }}</ng-container><ng-container *ngIf="!lineItem.lastStockOrdered">Never</ng-container></b></span>
  </div>
</ng-template>
<ng-template #loadingTemplate>
  <div class="LoadingContainer Flexbox Flexbox--align-center">
    <span>Loading historical data</span>
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>