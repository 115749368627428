<div class="Flexbox TitleContainer">
  <h2>Orders</h2>
  <a class="StatusHeaderInfo" href="#" (click)="orderInfoButtonClicked($event)"><mat-icon color="accent">info</mat-icon></a>
</div>
<p class="TitleHint">*Only shows orders placed through the system.</p>

<div class="Flexbox Flexbox--column MainContent">
  <mat-card>
    <mat-card-content>
      <div class="SearchFilterContainer">
        <div class="FilterRow Flexbox">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput #search (input)="searchChanged(search.value)">
          </mat-form-field>
        </div>
        <div class="FilterRow Flexbox">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select [value]="selectedStatuses" (valueChange)="statusChanged($event)" [compareWith]="optionComparator" multiple>
              <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption"> {{ statusOption.value }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Rep</mat-label>
            <mat-select [value]="selectedRep" (valueChange)="repChanged($event)" [compareWith]="optionComparator">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let repOption of repOptions" [value]="repOption"> {{ repOption.value }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div *ngIf="shouldShowTabBar" class="TabContainer Flexbox">
    <button mat-flat-button class="TabButton StockBalanceButton" [ngClass]="selectedTab === 0 ? 'TabActive' : ''" (click)="tabButtonClicked(0)">All Orders</button>
    <button mat-flat-button class="TabButton OrderButton" [ngClass]="selectedTab === 1 ? 'TabActive' : ''" (click)="tabButtonClicked(1)">My Placed Orders</button>
  </div>
  <div>
    <ng-container *ngIf="isLoading then loadingTemplate; else tableTemplate"></ng-container>
    <ng-template #loadingTemplate>
      <div class="LoadingContainer Flexbox Flexbox--align-center Flexbox--justify-center">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </ng-template>
    <ng-template #tableTemplate>
      <table mat-table [dataSource]="dataSource" class="OrdersListTable">
        <ng-container matColumnDef="webNumber">
          <th mat-header-cell *matHeaderCellDef> Web Order # </th>
          <td class="MatCell" class="MatCell" mat-cell *matCellDef="let order">
            {{ order.webNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> System Order # </th>
          <td class="MatCell" class="MatCell" mat-cell *matCellDef="let order"> {{order.number}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> {{order.dateCreated | shortDate: { suffixZuluTime: true } }} </td>
        </ng-container>

        <ng-container matColumnDef="customerNumber">
          <th mat-header-cell *matHeaderCellDef> Cust # </th>
          <td class="MatCell" mat-cell *matCellDef="let order">
            <div>
              <span *ngIf="order.isOpportunity && !order.isCustomerActive" class="InactiveLabel mat-critical"><b>INACTIVE</b></span>
              <span>
                {{order.customerNumber}}
              </span>
            </div>
            <div *ngIf="order.isOpportunity && !order.customerNumber" class="Flexbox Flexbox--align-center Opportunity">
              <mat-icon class="mat-success">rocket_launch</mat-icon>
              <div class="OpportunityLabel">NEW</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef> Cust Name </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> {{order.customerName}} </td>
        </ng-container>

        <ng-container matColumnDef="repNumber">
          <th mat-header-cell *matHeaderCellDef> Rep # </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> {{order.salesRepNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="poNumber">
          <th mat-header-cell *matHeaderCellDef> PO # </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> {{order.poNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> Total </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> {{order.netTotal | money}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef><div class="StatusHeader Flexbox"><span>Status</span> <a class="StatusHeaderInfo" href="#" (click)="statusClicked($event)"><mat-icon color="accent">info</mat-icon></a></div></th>
          <td class="MatCell" mat-cell *matCellDef="let order"><app-status [status]="order.status"></app-status></td>
        </ng-container>

        <ng-container matColumnDef="placedBy">
          <th mat-header-cell *matHeaderCellDef> Placed By </th>
          <td class="MatCell" mat-cell *matCellDef="let order"> <a *ngIf="order.owner">{{ order.owner }}</a> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" (click)="tableRowClicked(row)"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="MatRow" *matNoDataRow>
          <td class="MetCell EmptyOrders" [attr.colspan]="tableColumns.length">No orders found</td>
        </tr>
      </table>
    </ng-template>
    <mat-paginator [pageSizeOptions]="[25]" showFirstLastButtons></mat-paginator>
  </div>
</div>
