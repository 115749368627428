import { Component, Input, OnInit } from '@angular/core';
import { Collection } from 'src/app/shared/interfaces/collection';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { SummaryCollection } from 'src/app/shared/interfaces/summary-collection';
import { getCollectionRestrictionReason } from 'src/app/shared/utils/collection.util';
import { getTotal, getTotalQuantity } from 'src/app/shared/utils/order.util';

@Component({
  selector: 'app-collection-group[collection]',
  templateUrl: './collection-group.component.html',
  styleUrls: ['./collection-group.component.scss']
})
export class CollectionGroupComponent implements OnInit {
  @Input() collection!: SummaryCollection;
  @Input() restrictionReason: string | null = null
  @Input() areRestrictionReasonsLoading: boolean = false
  @Input() restrictionReasonUpdatedDate: Date | string | null = null;
  @Input() shouldShowRestrictionReasons: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}

