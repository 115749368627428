import { getAsYouType, getRegionCodeForCountryCode, getCountryCodeForRegionCode } from 'awesome-phonenumber';

export function formatMoney(value: string | number, options?: 'whole' | 'cents') {
  if (value === 0) {
    return '$0';
  }

  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: options === 'whole' ? 0 : 2,
    signDisplay: 'auto',
    currencySign: 'accounting'
  })

  const formattedValue = formatter.format(options === 'whole' ? Math.round(+value) : +value);
  if (+formattedValue.replace('$','') < 0) {
    return `(${formattedValue})`;
  }
  return formattedValue;
}

export function formatPhoneUS(value: string | null) {
  const digits: string[] = [];

  if (value) {
    const sanitizedValue = value.replace(/[^0-9+]/g, '');
    if (sanitizedValue.match(/^\+\d/)) {
      digits.push(
        sanitizedValue.slice(0,2),
        sanitizedValue.slice(2,5),
        sanitizedValue.slice(5,8),
        sanitizedValue.slice(8,12)
      );
    } else {
      digits.push(
        sanitizedValue.slice(0, 3),
        sanitizedValue.slice(3, 6),
        sanitizedValue.slice(6, 10)
      );
    }
    const formattedPhone = digits.flatMap(group => group === '' ? [] : group).join(' ');
    return formattedPhone;
  }
  return null;
}

export function formatPhone(value: string | null, countryCode: string | number) {
  if (!value) {
    return null;
  }

  const sanitizedValue = value.replace(/\D+/g, '');
  const regionString = getPhoneRegionString(countryCode);
  const getAsYouTypeValue = getAsYouType(regionString);
  const formattedString = getAsYouTypeValue.reset(sanitizedValue)?.replace(/[()-]/g, ' ')?.replace(/\s{2,}/,' ')?.trim();

  if (getAsYouTypeValue.getPhoneNumber().valid) {
    const phone = getAsYouTypeValue.getPhoneNumber();
    const countryCodeNumber = getCountryCodeForRegionCode(regionString);
    const phoneReplacerRegex = new RegExp(`^\\+${countryCodeNumber}\s*`);
    const phoneString = phone?.number?.international?.replace(phoneReplacerRegex, '')?.replace(/[()-]/g, ' ')?.replace(/\s{2,}/,' ')?.trim() ?? null;
    return phoneString ?? formattedString;
  }

  return formattedString;
}

function getPhoneRegionString(countryCode: string | number) {
  if (typeof countryCode === 'string' && isNaN(+countryCode)) {
    return countryCode;
  }
  return getRegionCodeForCountryCode(+countryCode);
}
