
<div class="Flexbox">
  <h3 class="Title">{{ summaryCollection.name }}</h3>
  <div class="Flexbox Border"><span class="RestrictionReason">{{ restrictionReason }}</span></div>
</div>
<div class="GroupContainer Flexbox">
  <div class="OrderContainer Column">
    <!-- <h4>Order</h4> -->
    <table>
      <tr>
        <td>Qty: {{ proformaQuantity }}</td>
        <td>Total: {{ proformaTotal | money }}</td>
      </tr>
    </table>
  </div>
  <!-- <div class="StockBalanceContainer Column">
    <h4>Stock Balance</h4>
    <table>
      <tr>
        <td>Eligible Qty: {{ stockBalanceEligibleQuantity }}</td>
        <td>Eligible Total: {{ stockBalanceEligibleTotal | money }}</td>
      </tr>
      <tr>
        <td>Ineligible Qty: {{ stockBalanceIneligibleQuantity }}</td>
        <td>Ineligible Total: {{ stockBalanceIneligibleTotal | money }}</td>
      </tr>
    </table>
  </div> -->
</div>