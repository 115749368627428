<div class="TopContainer">
  <div class="EnvironmentCallout" *ngIf="environmentType !== 'production'">
    {{ environmentType }}
  </div>
  <app-navigation-bar *ngIf="isNavBarVisible"></app-navigation-bar>
  <div *ngIf="isSummaryBarVisible" class="SummaryBarContainer Flexbox Flexbox--justify-center">
    <app-customer-summary-bar></app-customer-summary-bar>
  </div>
</div>
<div class="PageContent">
  <router-outlet></router-outlet>
</div>
