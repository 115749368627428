import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackingListRootComponent } from './components/packing-list-root/packing-list-root.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { OrderViewCollectionGroupsModule } from 'src/app/shared/modules/order-view-collection-groups/order-view-collection-groups.module';



@NgModule({
  declarations: [
    PackingListRootComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule,
    OrderViewCollectionGroupsModule
  ],
  exports: [
    PackingListRootComponent
  ]
})
export class PackingListModule { }
