import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/interfaces/user';
import { UserService } from 'src/app/shared/services/user.service';
import { NavigationBarUserService } from '../../services/navigation-bar-user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  userName!: string
  userInitials!: string
  homeURL!: string

  constructor(
    private navigationBarUserService: NavigationBarUserService,
    private userService: UserService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    const user = this.navigationBarUserService.get();
    this.userName = user?.name ?? '';
    this.userInitials = this.getUserInitials(this.userName);
    this.homeURL = '';
    this.navigationBarUserService.subscribe(this.userChanged.bind(this));
  }

  userChanged(user: User | null) {
    this.userName = user?.name ?? '';
    this.userInitials = this.getUserInitials(this.userName);
  }

  getUserInitials(userName: string) {
    return userName.split(' ').map(name => name.split('').shift()).join('');
  }

  async logout() {
    try {
      await this.userService.logout();
    } catch(e) {
      console.error(e);
    } finally {
      this.router.navigateByUrl('').then(() => window.location.reload());
    }
  }
}
