import { Injectable } from '@angular/core';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { LineItemsService } from './line-items.service';

@Injectable({
  providedIn: 'root'
})
export class ProformaLineItemsService extends LineItemsService<ProformaLineItem> {

  constructor() {
    super();
   }
}
