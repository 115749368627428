import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureConfirmationRootComponent } from './components/signature-confirmation-root/signature-confirmation-root.component';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';



@NgModule({
  declarations: [
    SignatureConfirmationRootComponent
  ],
  imports: [
    CommonModule,
    SharedAngularMaterialModule
  ],
  exports: [
    SignatureConfirmationRootComponent
  ]
})
export class SignatureConfirmationModule { }
