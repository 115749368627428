<table class="ItemSummaryInfo">
  <tr>
    <td>Line Qty</td>
    <td class="SummaryValue">{{ lineItem.quantity }}</td>
  </tr>
  <tr>
    <td>Unit Cost</td>
    <td class="SummaryValue">{{ lineItem.unitRate | money }}</td>
  </tr>
  <tr>
    <td>Ext. Disc</td>
    <td class="SummaryValue" [ngClass]="lineItem.extendedDiscount < 0 ? 'mat-critical' : ''">{{ lineItem.extendedDiscount | money }}</td>
  </tr>
  <tr>
    <td>Ext. Cost</td>
    <td class="SummaryValue">{{ lineItem.extendedTotal | money }}</td>
  </tr>
</table>

<!-- <div class="ItemSummaryInfo Flexbox Flexbox--column">
  <div>
    <span>Qty:</span>
    <span class="SummaryValue">{{ lineItem.quantity }}</span>
  </div>
  <div>
    <span>Unit Cost:</span>
    <span class="SummaryValue">{{ lineItem.unitRate | money }}</span>
  </div>
  <div>
    <span>Ext. Discount:</span>
    <span class="SummaryValue" [ngClass]="lineItem.extendedDiscount < 0 ? 'mat-critical' : ''">{{ lineItem.extendedDiscount | money }}</span>
  </div>
  <div>
    <span>Ext. Cost:</span>
    <span class="SummaryValue">{{ lineItem.extendedTotal | money }}</span>
  </div>
</div> -->