import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';

@Component({
  selector: 'app-view-program-item',
  templateUrl: './view-program-item.component.html',
  styleUrls: ['./view-program-item.component.scss']
})
export class ViewProgramItemComponent implements OnInit {
  @Input() lineItem!: LineItem;
  @Input() lineNumber: number | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
