import { environment } from 'src/environments/environment';
import { APIBASE } from './api-base';

export class ORDERSTATS extends APIBASE {
  static override local() {
    return `${environment.apiURL}/customer`;
  }

  static override staging() {
    return `${environment.apiURL}/customer`;
  }

  static override production() {
    return `${environment.apiURL}/customer`;
  }
}
