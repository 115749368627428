import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Address } from 'src/app/shared/interfaces/address';
import { Customer } from 'src/app/shared/interfaces/customer';
import { SubmitOrder } from 'src/app/shared/interfaces/submit-order';
import { AutoCompleteOption } from 'src/app/shared/modules/auto-complete/interfaces/auto-complete-option';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { CustomerProviderService } from 'src/app/shared/providers/customer.provider.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SubmitOrderService } from 'src/app/shared/services/submit-order.service';
import { assert } from 'src/app/shared/utils/assert.util';

@Component({
  selector: 'app-customer-select-root',
  templateUrl: './customer-select-root.component.html',
  styleUrls: ['./customer-select-root.component.scss']
})
export class CustomerSelectRootComponent implements OnInit {
  isLoading = true;
  customers: Customer[] = [];
  selectedCustomer: Customer | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerProvider: CustomerProviderService,
    private customerSummaryBarService: CustomerSummaryBarService,
    private submitOrderService: SubmitOrderService,
    private orderService: OrderService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    (async () => {
      this.isLoading = true;
      this.customers = await this.customerProvider.getAll();
      this.isLoading = false;

      const queryCustomerID = this.route.snapshot.queryParams['customerID'];
      if (queryCustomerID) {
        const customer = this.customers.find(customer => customer.id.toString() === queryCustomerID);
        this.changed(customer ?? null);
      }
    })();
  }

  changed(customer: Customer | null) {
    this.selectedCustomer = customer;
    if (this.selectedCustomer && this.selectedCustomer.addresses.length === 1) {
      this.startNewOrder(this.selectedCustomer.addresses[0]);
    }
  }

  addressSelected(address: Address) {
    this.startNewOrder(address);
  }

  startNewOrder(address: Address) {
    assert(this.selectedCustomer, 'No customer selected');
    const submitOrder: SubmitOrder = {
      customer: this.selectedCustomer,
      address: address,
      proformaKey: null,
      stockBalanceKey: null,
      orderKey: null,
      orderDetails: null,
      proformaLineItems: [],
      stockBalanceLineItems: []
    };
    this.submitOrderService.set(submitOrder);
    this.customerSummaryBarService.set(this.selectedCustomer);
    this.orderService.set(null);

    this.router.navigateByUrl('/order/edit');
  }
}
