import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Address } from 'src/app/shared/interfaces/address';
import { Order } from 'src/app/shared/interfaces/order';
import { SubmitOrder } from 'src/app/shared/interfaces/submit-order';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { SignatureBoxComponent } from 'src/app/shared/modules/signature-box/components/signature-box/signature-box.component';
import { OrderStatsProviderService } from 'src/app/shared/providers/order-stats.provider.service';
import { OrderProviderService } from 'src/app/shared/providers/order.provider.service';
import { ProformaLineItemProviderService } from 'src/app/shared/providers/proforma-line-item.provider.service';
import { StockBalanceLineItemsProviderService } from 'src/app/shared/providers/stock-balance-line-items.provider.service';
import { TermsProviderService } from 'src/app/shared/providers/terms.provider.service';
import { OrderSaveService } from 'src/app/shared/services/order-save.service';
import { OrderStatsService } from 'src/app/shared/services/order-stats.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SubmitOrderService } from 'src/app/shared/services/submit-order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { buildSummaryCollections, getCollectionRestrictionReason } from 'src/app/shared/utils/collection.util';
import { getRestrictedCollections } from 'src/app/shared/utils/customer.util';
import { buildSubmitOrderFromOrder } from 'src/app/shared/utils/submit-order.util';
import { OrderInfo } from '../../interfaces/order-info';
import { OrderInfoComponent } from '../order-info/order-info.component';

@Component({
  selector: 'app-order-submit-root',
  templateUrl: './order-submit-root.component.html',
  styleUrls: ['./order-submit-root.component.scss']
})
export class OrderSubmitRootComponent implements OnInit {
  order : Order | null = null;
  submitOrder!: SubmitOrder;
  isFormValid = false;
  isLoading = false;
  signature: string | null = null;
  isAutoSaving = false;
  isSubmittingOrder = false;
  shouldUnscheduleSave = true;
  @ViewChild(SignatureBoxComponent) signaturePad!: SignatureBoxComponent;
  @ViewChild(OrderInfoComponent) orderInfoComponent: OrderInfoComponent | null = null;

  get hasChanges() {
    return this.orderSaveService.hasChanges;
  }

  get lastSavedAt() {
    return this.order?.dateUpdated ?? null;
  }

  get shouldSeeSignature() {
    return !this.userService.isAR() && this.order?.status === 'Draft';
  }

  get saveButtonText() {
    if (this.order?.status !== 'Draft') {
      return 'Save';
    }
    return 'Save as draft';
  }

  get canSaveAsDraft() {
    return (this.order?.status === 'Draft' && this.order.ownerUsername === this.userService.get()?.email) || this.order?.status === 'Pending Retailer' || ((this.order?.status === 'Pending AR' || this.order?.status === 'AR Hold') && (this.userService.isAR() || this.userService.isAdmin()));
  }

  get canSaveAndClose() {
    return (this.userService.isAR() || this.userService.isAdmin()) && this.canSaveAsDraft;
  }

  get canSubmit() {
    return this.order?.status === 'Draft' && this.order.ownerUsername === this.userService.get()?.email;
  }

  get isOrderOwner() {
    return this.order && this.userService.get()?.reps.includes(this.order.salesRep.number);
  }

  get canDelete() {
    return this.order && this.isOrderOwner && this.order.status === 'Draft';
  }

  get proformaLineItems() {
    return this.submitOrder?.proformaLineItems ?? [];
  }

  get stockBalanceLineItems() {
    return this.submitOrder?.stockBalanceLineItems ?? [];
  }

  get shippingAddress() {
    return this.submitOrder?.address;
  }

  set shippingAddress(address: Address) {
    this.submitOrder.address = address;
    this.submitOrderService.set(this.submitOrder);
  }

  get customer() {
    return this.submitOrder?.customer;
  }
  get orderTerms() {
    return this.submitOrder?.orderDetails?.terms ?? null
  }

  get restrictedCollections() {
    return this.customer ? getRestrictedCollections(this.customer) : [];
  }

  get shippingCost() {
    return this.orderService.get()?.shippingCost ?? null;
  }

  get orderInfo(): OrderInfo | null {
    if (this.submitOrder.orderDetails) {
      const orderInfo: OrderInfo = {
        buyerEmail: this.submitOrder.orderDetails?.buyerEmail ?? null,
        buyerConfirmEmail: this.submitOrder.orderDetails.buyerConfirmEmail ?? null,
        buyerName: this.submitOrder.orderDetails?.buyerName ?? null,
        buyerPhone: this.submitOrder.orderDetails?.buyerPhone ?? null,
        arEmail: this.submitOrder.orderDetails?.arEmail ?? null,
        arConfirmEmail: this.submitOrder.orderDetails.arConfirmEmail ?? null,
        arName: this.submitOrder.orderDetails?.arName ?? null,
        arPhone: this.submitOrder.orderDetails?.arPhone ?? null,
        po: this.submitOrder.orderDetails?.po ?? null,
        terms: this.submitOrder.orderDetails?.terms ?? null
      }
      return orderInfo;
    }
    return null
  }

  set orderInfo(value: OrderInfo | null) {
    if (value) {
      this.submitOrder.orderDetails = {
        buyerEmail: value.buyerEmail,
        buyerConfirmEmail: value.buyerConfirmEmail,
        buyerPhone: value.buyerPhone,
        buyerName: value.buyerName,
        arEmail: value.arEmail,
        arConfirmEmail: value.arConfirmEmail,
        arPhone: value.arPhone,
        arName: value.arName,
        po: value.po,
        terms: value.terms
      }
    } else {
      this.submitOrder.orderDetails = null;
    }
    this.submitOrderService.set(this.submitOrder);
  }

  constructor(
    private orderService: OrderService,
    private submitOrderService: SubmitOrderService,
    private customerSummaryBarService: CustomerSummaryBarService,
    private orderProvider: OrderProviderService,
    private proformaLineItemProvider: ProformaLineItemProviderService,
    private stockBalanceLineItemProvider: StockBalanceLineItemsProviderService,
    private termsProvider: TermsProviderService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private orderSaveService: OrderSaveService,
    private orderStatsService: OrderStatsService,
    private orderStatsProvider: OrderStatsProviderService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const submitOrder = this.submitOrderService.get();
    if (submitOrder) {
      this.submitOrder = submitOrder;
      assert(this.customer, 'Customer was null.');
    } else {
      const orderKey = this.route.snapshot.paramMap.get('orderID');
      assert(orderKey, 'No order key given.')
      const apiOrder = await this.orderProvider.get(orderKey)
      this.orderService.set(apiOrder);
      apiOrder.proforma.lineItems = await this.proformaLineItemProvider.get(apiOrder.proforma.key);
      assert(apiOrder, 'Order was null for the given key.');

      if (apiOrder.stockBalance) {
        apiOrder.stockBalance.lineItems = await this.stockBalanceLineItemProvider.get(apiOrder.stockBalance.key);
      }

      this.submitOrder = buildSubmitOrderFromOrder(apiOrder);
      this.submitOrderService.set(this.submitOrder);
      this.customerSummaryBarService.set(apiOrder.customer);
    }

    if (this.customer && !this.customer?.availableTerms) {
      this.customer.availableTerms = await this.termsProvider.getAvailable(this.customer.id);
      if (!this.customer.availableTerms.includes(this.customer.defaultTerms) && this.customer.number && this.customer.isActive) {
        this.customer.availableTerms.push(this.customer.defaultTerms);
      }
      this.customerSummaryBarService.set(this.customer);
    }

    if (this.customer && (this.userService.isAR() || this.userService.isAdmin())) {
      const arTerms = await this.termsProvider.get();
      this.customer.availableTerms = arTerms.map(term => term.name);
      this.customerSummaryBarService.set(this.customer);
    }

    this.order = this.orderService.get();
    if (this.order) {
      this.order.proforma.lineItems = this.submitOrder.proformaLineItems;

      if (this.order.stockBalance) {
        this.order.stockBalance.lineItems = this.submitOrder.stockBalanceLineItems;

        if (this.customer && (!this.orderStatsService.getLast15MonthsCustomer() || !this.orderStatsService.getLast15MonthsStock() || !this.orderStatsService.getOrderedDatesPerSKU())) {
          const Last15MonthsCustomerOrderPerStylePromise = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(this.customer.number, 'customer');
          const Last15MonthsStockOrderPerStylePromise = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(this.customer.number, 'stock');
          const LastStockOrderedPerSKUPromise = this.orderStatsProvider.GetLastStockOrderedPerSKU(this.customer.number);
          const order = this.order;
          Promise.all([Last15MonthsCustomerOrderPerStylePromise, Last15MonthsStockOrderPerStylePromise, LastStockOrderedPerSKUPromise]).then(([Last15MonthsCustomerOrderPerStyle, Last15MonthsStockOrderPerStyle, LastStockOrderedPerSKU]) => {
            order.stockBalance?.lineItems.forEach(lineItem => {
            lineItem.areOrderStatsLoaded = true

            const recentStockOrderedCount = Last15MonthsStockOrderPerStyle.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
            const recentCustomerOrderedCount = Last15MonthsCustomerOrderPerStyle.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
            const lastStockOrdered = LastStockOrderedPerSKU.find(stat => stat.sku === lineItem.item?.sku) ?? null;

            lineItem.lastStockOrdered = lastStockOrdered ? new Date(lastStockOrdered.date) : null;
            lineItem.recentStockOrderedCount = recentStockOrderedCount?.quantity ?? null;
            lineItem.recentCustomerOrderedCount = recentCustomerOrderedCount?.quantity ?? null;
          })});
        }
      }
    }

    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.shouldUnscheduleSave) {
      this.orderSaveService.reset();
    }
  }

  backButtonClicked() {
    this.shouldUnscheduleSave = false;
    this.location.back();
  }

  deleteButtonClicked() {
    this.orderSaveService.unschedule();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Remove draft?', body: 'Are you sure you want to remove this draft?', cancelButtonText: 'Cancel', confirmButtonText: 'Remove' } });
    dialogRef.afterClosed().subscribe(async (didConfirm) => {
      if (didConfirm) {
        try {
          assert(this.order, 'Order was null');
          await this.orderProvider.delete(this.order.key);
          this.router.navigateByUrl('/');
        } catch (e) {
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { icon: 'error', iconColor: 'warn', body: 'An error has occurred. If the issue persists, please contact support.', confirmButtonText: 'Ok' } });
          console.error(e);
        }
      } else {
        this.scheduleSave();
      }
    });
  }

  async submitButtonClicked() {
    this.signaturePad.savePad();
    if (this.isFormValid) {
      if(this.doesOrderHaveRestrictions()) {
        const didConfirmNoSignature = await this.showConfirmationPrompt('This order appears to contain invalid lines. Would you like to submit anyway?', 'Submit', 'No');
        if (!didConfirmNoSignature) {
          return;
        }
      }

      if (!this.signature) {
        const didConfirmNoSignature = await this.showConfirmationPrompt(`A buyer signature has not been added. An email will be sent to the buyer (${this.submitOrder.orderDetails?.buyerEmail}) for approval. The order will be set to "Pending Retailer" until approved. Would you like to proceed?`, 'Submit', 'No');
        if (!didConfirmNoSignature) {
          return;
        }
      }

      this.postOrder();
    } else {
      this.orderInfoComponent?.showFormErrors();
      console.error('Form was not valid');
    }
  }

  private doesOrderHaveRestrictions() {
    const collections = buildSummaryCollections(this.proformaLineItems, this.stockBalanceLineItems);

    const hasRestrictionReason = collections.some(collection => !!getCollectionRestrictionReason(collection, this.restrictedCollections))
    return hasRestrictionReason;
  }

  private showConfirmationPrompt(promptContent: string, confirmOption: string, cancelOption: string): Promise<boolean> {
    return new Promise((res) => {
      this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: promptContent, confirmButtonText: confirmOption, cancelButtonText: cancelOption } }).afterClosed().subscribe(confirmed => {
        res(confirmed)
      });
    })
  }

  async postOrder() {
    assert(this.submitOrder.orderKey, 'No order key given');

    this.isSubmittingOrder = true;
    this.orderSaveService.unschedule();
    await this.save(this.signature);

    const order = await this.orderProvider.convertToOrder(this.submitOrder.orderKey);
    this.isLoading = false;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { icon: 'check', iconColor: 'success',  title: 'ORDER SUBMITTED', body: ['Pending AR', 'AR Hold'].includes(order?.status ?? '') ? 'The order has been submitted. Please check your inbox for updates regarding the status of the order.' : 'The order as been submitted. An email has been sent to the customer for approval.' } });
    dialogRef.afterClosed().subscribe(() => {
      this.orderService.set(null);
      this.submitOrderService.delete();
      this.router.navigateByUrl('/order');
    });

    setTimeout(() => {
      dialogRef.close();
    }, 3000);
  }

  validChanged(value: boolean) {
    this.isFormValid = value;
  }

  async saveAndCloseClicked() {
    await this.saveAsDraftClicked();
    this.router.navigateByUrl(`/history`).then(() => window.location.reload());
  }

  async saveAsDraftClicked() {
    const order = this.orderService.get();
    if (order?.status === 'Pending Retailer') {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: `Are you sure you want to update this order? An email will be sent to the buyer (${order.buyerEmail}) for approval.`, confirmButtonText: 'Save', cancelButtonText: 'Cancel' } });
      await new Promise<void>((res) => dialogRef.afterClosed().subscribe(async (didConfirm) => {
        if (didConfirm) {
          await this.save();
          res();
        }
      }));
    } else {
      await this.save();
    }
  }

  private async save(signature: string | null = null) {
    this.isLoading = true;
    this.orderSaveService.unschedule();
    this.submitOrder.proformaLineItems = this.proformaLineItems
    this.submitOrder.stockBalanceLineItems = this.stockBalanceLineItems;
    this.order = await this.orderSaveService.save(this.submitOrder, signature);
    this.orderService.set(this.order);
    this.submitOrderService.set(this.submitOrder);
    this.isLoading = false || this.isSubmittingOrder;
    this.snackBar.open('Saved!', undefined, { duration: 3000 });
  }

  orderChanged() {
    const user = this.userService.get();
    if (this.order?.status === 'Draft' && this.order.ownerUsername === user?.email) {
      this.orderSaveService.hasChanges = true;
      this.scheduleSave();
    }
  }

  scheduleSave() {
    this.submitOrder.proformaLineItems = this.proformaLineItems;
    this.submitOrder.stockBalanceLineItems = this.stockBalanceLineItems;
    const scheduleResult = this.orderSaveService.scheduleSave(this.submitOrder, 15, 30);

    scheduleResult.beforeSave.subscribe(() => {
      this.isAutoSaving = true;
    });

    scheduleResult.afterSave.subscribe(({ submitOrder, order }) => {
      this.submitOrderService.set(submitOrder);
      this.order = order;
      this.orderService.set(order);
      this.isAutoSaving = false;
    });
  }
}
