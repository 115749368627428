import { Pipe, PipeTransform } from '@angular/core';
import { formatMoney } from '../utils/text-formatters.util';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(value: number, args?: 'whole' | 'cents'): string {
    return formatMoney(value, args)
  }

}
