import { environment } from 'src/environments/environment';
import { APIBASE } from './api-base';

export class PROFORMA_LINES extends APIBASE {
  static override local() {
    return `${environment.apiURL}/proforma`;
  }

  static override staging() {
    return `${environment.apiURL}/proforma`;
  }

  static override production() {
    return `${environment.apiURL}/proforma`;
  }

}
