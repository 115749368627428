<div *ngIf="isSaving">Saving...</div>
<div *ngIf="!isSaving">
  <div class="Flexbox Container">
    <span>Last Saved:</span>
    <span *ngIf="!lastSaved">Never</span>
    <span class="LastSaved" *ngIf="lastSaved">{{ lastSaved | timeAgo: { suffixZuluTime: true } }}</span>
  </div>
  <div *ngIf="hasUnsavedChanges">
    <span>*Unsaved</span>
  </div>
</div>
