<div mat-dialog-content>
  <div class="Flexbox Flexbox--column Content">
    <img *ngIf="currentTab === 1" [src]="imageSource" class="GalleryImage">
    <iframe class="VideoEmbed" *ngIf="currentTab === 2" [src]="iframeURL" allowfullscreen></iframe>
  </div>
</div>
<div class="Flexbox Flexbox--align-center" mat-dialog-actions>
  <div class="Flexbox Flexbox--align-center TabContainer">
    <button [ngClass]="currentTab === 1 ? 'Fill' : ''" class="ImgTab" (click)="switchTab(1)"></button>
    <button *ngIf="iframeURL" [ngClass]="currentTab === 2 ? 'Fill' : ''" class="PlayTab" (click)="switchTab(2)">
      <img *ngIf="currentTab === 1" src="https://assets.benchmarkrings.com/assets/tri4.png" alt="">
      <img *ngIf="currentTab !== 1" src="https://assets.benchmarkrings.com/assets/trifill.png" alt="">
    </button>
  </div>
  <button mat-button [mat-dialog-close]>Close</button>
</div>
