import { Status } from '../interfaces/status';

export function getStatusData(statusName: string): Status {
  switch(statusName) {
    case 'Pending Retailer':
    return { progress: 1, color: 'caution' };
    case 'Pending AR':
    return { progress: 2, color: 'caution' };
    case 'AR Hold':
    return { progress: 2, color: 'serious' };
    case 'Processing':
    return { progress: 4, color: 'standby' };
    case 'Invoiced':
    return { progress: 4, color: 'normal' };
    case 'AR Denied':
    return { progress: 4, color: 'critical' };
    default:
    return { progress: 0, color: '' };
  }
}

export function getStatusProgress(statusName: string) {
  return getStatusData(statusName).progress;
}
