import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(date: Date | string | null, args?: { suffixZuluTime?: boolean }): string | null {
    if (!date) {
      return null;
    }

    const dateComponents = [date.toString()];

    if (args?.suffixZuluTime && !date.toLocaleString().toLowerCase().endsWith('z')) {
      dateComponents.push('Z');
    }


    const compareDate = new Date(dateComponents.join(''));
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const milisecondsInSecond = 1000
    const milisecondsInMinute = milisecondsInSecond * 60;
    const milisecondsInHour = milisecondsInMinute * 60;
    const milisecondsInDay = milisecondsInHour * 24;
    const milisecondsInMonth = new Date().getTime() - lastMonth.getTime();

    const milisecondDifferenceSinceNow = new Date().getTime() - compareDate.getTime();
    const relativeTimeFormatter = new Intl.RelativeTimeFormat()

    if (Math.abs(milisecondDifferenceSinceNow) < milisecondsInMinute) {
      return 'now'
    }

    if (Math.abs(milisecondDifferenceSinceNow) < milisecondsInHour) {
      return relativeTimeFormatter.format(Math.round(milisecondDifferenceSinceNow / milisecondsInMinute) * -1, 'minutes');
    }

    if (Math.abs(milisecondDifferenceSinceNow) < milisecondsInDay) {
      return relativeTimeFormatter.format(Math.round(milisecondDifferenceSinceNow / milisecondsInHour) * -1, 'hours');
    }

    if (Math.abs(milisecondDifferenceSinceNow) < milisecondsInMonth) {
      return relativeTimeFormatter.format(Math.round(milisecondDifferenceSinceNow / milisecondsInDay) * -1, 'days');
    }

    if (Math.abs(milisecondDifferenceSinceNow) < milisecondsInMonth + milisecondsInDay * 10) {
      return relativeTimeFormatter.format(Math.round(milisecondDifferenceSinceNow / milisecondsInMonth) * -1, 'months');
    }

    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', year: '2-digit', month: '2-digit' };
    return compareDate.toLocaleDateString('en-us', dateOptions);;
  }
}
