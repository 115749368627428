import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { Order } from 'src/app/shared/interfaces/order';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { SummaryCollection } from 'src/app/shared/interfaces/summary-collection';
import { UserService } from 'src/app/shared/services/user.service';
import { buildSummaryCollections, getCollectionRestrictionReason } from 'src/app/shared/utils/collection.util';
import { getLineItemOrderStatsRestrictionReason } from 'src/app/shared/utils/line-item.util';
import { getTotal, getTotalQuantity } from 'src/app/shared/utils/order.util';

@Component({
  selector: 'app-order-view-collection-groups',
  templateUrl: './order-view-collection-groups.component.html',
  styleUrls: ['./order-view-collection-groups.component.scss']
})
export class OrderViewCollectionGroupsComponent implements OnInit {
  private _order: Order | null = null;
  private _proformaLineItems: ProformaLineItem[] = [];
  private _stockBalanceLineItems: StockBalanceLineItem[] = [];

  @Input() set order(value: Order | null) {
    this._order = value;
    this._proformaLineItems = value?.proforma?.lineItems ?? [];
    this._proformaLineItems.sort((a, b) => a.itemCollection.localeCompare(b.itemCollection));
    this.collectionGroups = buildSummaryCollections(this.proformaLineItems, this.stockBalanceLineItems);

    this._stockBalanceLineItems = value?.stockBalance?.lineItems ?? [];
    this.collectionGroups = buildSummaryCollections(this.proformaLineItems, this.stockBalanceLineItems);
  }

  get proformaLineItems() {
    return this._proformaLineItems;
  }

  get stockBalanceLineItems() {
    return this._stockBalanceLineItems;
  }

  get order() {
    return this._order;
  }

  get user() {
    return this.userService.get();
  }

  @Input() restrictedCollections: string[] = [];

  get shouldShowRestrictionReasons() {
    return !!this._order && this._order.status !== 'Processing' && this._order.status !== 'Shipped' && this._order.status !== 'Delivered';
  }

  collectionGroups: SummaryCollection[] = [];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  getProformaItemIndex(lineItem: LineItem) {
    return this.getIndex(lineItem, this.proformaLineItems);
  }

  getStockBalanceItemIndex(lineItem: LineItem) {
    return this.getIndex(lineItem, this.stockBalanceLineItems);
  }

  private getIndex(referenceLineItem: LineItem, lineItems: LineItem[]) {
    return lineItems.findIndex(lineItem => lineItem.key === referenceLineItem.key);
  }

  isLineItemInRestrictedCollection(lineItem: LineItem) {
    return this.restrictedCollections.includes(lineItem.item?.class.fullName ?? lineItem.itemCollection);
  }

  didOrderStatsLoad(collection: SummaryCollection) {
    return collection.stockBalanceItems.length === 0 || collection.stockBalanceItems.some(lineItem => lineItem.areOrderStatsLoaded);
  }

  getCollectionRestrictionReason(collection: SummaryCollection) {
    if (this.userService.hasRole()) {
      const collectionReason = getCollectionRestrictionReason(collection, this.restrictedCollections);
      const hasOrderStatsReason = collection.stockBalanceItems.some(lineItem => !!getLineItemOrderStatsRestrictionReason(lineItem));
      if (collectionReason && hasOrderStatsReason) {
        return `${collectionReason} and Stock Balance Exception`
      }

      if (collectionReason) {
        return collectionReason;
      }

      if (hasOrderStatsReason) {
        return 'Stock Balance Exception';
      }
    }
    return null;
  }
}
