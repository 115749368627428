<app-loading-indicator [isLoading]="isLoading" type="Relative"></app-loading-indicator>
<div *ngIf="formGroup" class="Flexbox Flexbox--column Formgroup" [formGroup]="formGroup">
  <div class="ItemPropertiesContainer Flexbox Flexbox--row">
    <app-async-select formControlName="material" required class="Karat" [options]="metalTypeOptions" label="Material" type="image"></app-async-select>
    <app-async-select formControlName="width" required [options]="widthOptions" label="Width"></app-async-select>
    <app-async-select formControlName="fingerSize" required [options]="fingerSizeOptions" label="Finger Size"></app-async-select>
  </div>
  <div class="ItemPropertiesContainer Flexbox Flexbox--row">
    <app-async-select class="Edge" *ngIf="edgeOptions.length > 1" formControlName="edge" required [options]="edgeOptions" label="Edge"></app-async-select>
    <mat-form-field appearance="outline">
      <mat-label>Qty</mat-label>
      <input matInput type="number" formControlName="quantity" (change)="lineItemChange.emit(lineItem)">
      <div class="UpDownButtons Flexbox Flexbox--column">
        <button mat-icon-button (click)="upButtonClicked()"><mat-icon>expand_less</mat-icon></button>
        <button mat-icon-button (click)="downButtonClicked()"><mat-icon>expand_more</mat-icon></button>
      </div>
    </mat-form-field>
    <mat-form-field class="PO" appearance="outline">
      <mat-label>Line Item PO</mat-label>
      <input matInput type="text" formControlName="po">
    </mat-form-field>
  </div>
</div>
