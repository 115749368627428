<h1 mat-dialog-title>Inactive Customers Found</h1>
<div mat-dialog-content>
  <div class="Flexbox Flexbox--column CustomerCardContainerWrapper">
    <span class="CustomerSelectText">Please select a customer to continue</span>
    <div class="Flexbox Flexbox--row CustomerCardContainer">
      <mat-card class="CustomerCard" *ngFor="let customer of customers">
        <button mat-button class="CustomerButton" (click)="closeDialog(customer)">
          <div class="CustomerContent Flexbox Flexbox--column">
            <div class="Flexbox CustomerInfo">
              <span>{{ customer.number }}</span>
              <span>{{ customer.name }}</span>
            </div>
            <div class="Flexbox Flexbox--column Flexbox--align-flex-start AddressInfo" *ngIf="customer.billingAddress">
              <div>
                <span>{{ customer.billingAddress.street1 }}</span>
                <span *ngIf="customer.billingAddress.street2">{{ customer.billingAddress.street2 }}</span>
              </div>
              <div>
                <span>{{ customer.billingAddress.city }}</span>
                <span *ngIf="customer.billingAddress.stateCode">, {{ customer.billingAddress.stateCode }}</span>
              </div>
            </div>
          </div>
        </button>
      </mat-card>
    </div>
  </div>
</div>
