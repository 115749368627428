<h2>Users</h2>

<div class="Flexbox Flexbox--column MainContent">
  <div class="ActionContainer">
    <div class="Row Flexbox">
      <button mat-raised-button color="accent" (click)="newUserButtonClicked()">New User</button>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput #search (input)="searchChanged(search.value)">
      </mat-form-field>
    </div>
  </div>
  <div>
    <ng-container *ngIf="isLoading then loadingTemplate; else tableTemplate"></ng-container>
    <ng-template #loadingTemplate>
      <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
    </ng-template>
    <ng-template #tableTemplate>
      <table mat-table [dataSource]="dataSource" class="OrdersListTable">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td class="MatCell" class="MatCell" mat-cell *matCellDef="let user">
            <span>
              {{ user.email }}
            </span>
            <span *ngIf="user.isDeleted" class="mat-critical">
              <b>INACTIVE</b>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td class="MatCell" mat-cell *matCellDef="let user"> {{ user.fullName }} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td class="MatCell" mat-cell *matCellDef="let user"> {{ user.role.toUpperCase() }} </td>
        </ng-container>

        <ng-container matColumnDef="dateUpdated">
          <th mat-header-cell *matHeaderCellDef> Updated </th>
          <td class="MatCell" mat-cell *matCellDef="let user"> {{user.dateUpdated | shortDate: { suffixZuluTime: true } }} </td>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td class="MatCell" mat-cell *matCellDef="let user"> {{user.dateCreated | shortDate: { suffixZuluTime: true } }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" (click)="tableRowClicked(row)"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="MatRow" *matNoDataRow>
          <td class="MetCell EmptyOrders" [attr.colspan]="tableColumns.length">No orders found</td>
        </tr>
      </table>
    </ng-template>
    <mat-paginator [pageSizeOptions]="[25]" showFirstLastButtons></mat-paginator>
  </div>
</div>
