import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { compareOptions } from 'src/app/shared/utils/select.util';
import { AsyncSelectOption } from '../../interfaces/async-select-option';

@Component({
  selector: 'app-async-select[options]',
  templateUrl: './async-select.component.html',
  styleUrls: ['./async-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AsyncSelectComponent)
    }
  ]
})

export class AsyncSelectComponent implements OnInit, ControlValueAccessor {
  private _required: string | boolean = false;

  @Input() type: 'standard' | 'image' = 'standard';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() options!: AsyncSelectOption[];
  @Input() label?: string;
  @Input() value: AsyncSelectOption | null = null;
  @Output() valueChange = new EventEmitter<AsyncSelectOption | null>();
  @Output() openChange = new EventEmitter();
  @Input() disabled = false;
  @Input() formError: string | null = null;
  @Input() set required(value: string | boolean | undefined | null) {
    if (value) {
      this._required = value;
    } else {
      this._required = true;
    }
  }

  get required(): string | boolean {
    return this._required;
  }

  @ViewChild('selectElement') selectElement?: MatSelect;

  private onTouchedFns: (() => {})[] = [];

  private get formControlName() {
    return this.elementRef.nativeElement.getAttribute('formControlName') ?? null;
  }


  constructor(
    private formGroupDirective: FormGroupDirective,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.selectElement?.registerOnTouched(() => {
      this.onTouchedFns.forEach(fn => fn());
      return {};
    });
  }

  writeValue(value: AsyncSelectOption): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.valueChange.subscribe((value) => {
      this.value = value;
      fn(value);
    });
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouchedFns.push(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  optionComparator(option: AsyncSelectOption | null, value: AsyncSelectOption | null) {
    return compareOptions(option, value);
  }

  buildErrorStateMatcher() {
    const matcher = new ErrorStateMatcher()
    matcher.isErrorState = () => this.formGroupDirective.control.get(this.formControlName)?.invalid ?? false;
    return matcher;
  }
}
