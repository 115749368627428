export function unique<T>(array: T[], compare: (a: T, b: T) => boolean = defaultCompare): T[] {
  const uniqueArray = array.reduce((arr, item) => {
    if (!arr.some(arrItem => compare(item, arrItem))) {
      return arr.concat([item]);
    }
    return arr;
  }, [] as Array<T>);
  return uniqueArray;
}

function defaultCompare<T>(a: T, b: T) {
  return a === b;
}
