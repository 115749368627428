import { Contact } from '../interfaces/contact';
import { Customer } from '../interfaces/customer';
import { NewCustomer } from '../interfaces/new-customer';
import { NewCustomerAddress } from '../interfaces/new-customer-address';
import { NewCustomerFormValues } from '../interfaces/new-customer-form-values';
import { assert } from './assert.util';

export function isNewCustomerDifferent(newCustomer: NewCustomer, customer: Customer) {
  const isStoreNameDifferent = customer.name !== newCustomer.storeName;
  const isRequestedTermsDifferent = newCustomer.requestedTerms !== customer.defaultTermsID

  assert(customer.billingAddress, 'Billing address was null');

  const newCustomerAddress: { [key: string]: any } = newCustomer.address;
  const customerAddress: { [key: string]: any } = customer.billingAddress;
  const isAddressDifferent = !Object.keys(newCustomer.address).reduce((flag, key) => flag && newCustomerAddress[key] === customerAddress[key], true);

  return isStoreNameDifferent || isRequestedTermsDifferent || isAddressDifferent;
}

export function doesNewCustomerHaveConflictingFields(newCustomer: NewCustomer, customer: Customer) {
  const isStoreNameConflicting = doesNewCustomerHaveConflictingStoreName(newCustomer, customer);
  const isRequestedTermsConflicting = doesNewCustomerHaveConflictingRequestedTerms(newCustomer, customer);
  const isAddressConflicting = doesNewCustomerHaveConflictingAddress(newCustomer, customer);

  return isStoreNameConflicting || isRequestedTermsConflicting || isAddressConflicting;
}

export function doesNewCustomerHaveConflictingStoreName(newCustomer: NewCustomer, customer: Customer) {
  return !!newCustomer.storeName && customer.name !== newCustomer.storeName;
}

export function doesNewCustomerHaveConflictingRequestedTerms(newCustomer: NewCustomer, customer: Customer) {
  return !!newCustomer.requestedTerms && newCustomer.requestedTerms !== customer.defaultTermsID;
}

export function doesNewCustomerHaveConflictingAddress(newCustomer: NewCustomer, customer: Customer) {
  assert(customer.billingAddress, 'Billing address was null');
  const newCustomerAddress: { [key: string]: any } = newCustomer.address;
  const customerAddress: { [key: string]: any } = customer.billingAddress;
  return !Object.keys(newCustomer.address).reduce((flag, key) => flag && (!newCustomerAddress[key] || newCustomerAddress[key] === customerAddress[key]), true);
}

export function newCustomerFromFormValues(newCustomerFormValues: NewCustomerFormValues, repNumber: number | string) {
  assert(newCustomerFormValues.generalInfo && newCustomerFormValues.generalInfo.requestedTerms && newCustomerFormValues.generalInfo.name, 'Malformed generalInfo');
  const uppercaseAddress = convertAddressToUpper(newCustomerFormValues.address);
  assert(uppercaseAddress.country, 'Address has no country');
  const newCustomer: NewCustomer = {
    jbtNumber: newCustomerFormValues.generalInfo.jbtNumber?.toUpperCase() ?? null,
    storeName: newCustomerFormValues.generalInfo.name.toUpperCase(),
    requestedTerms: newCustomerFormValues.generalInfo.requestedTerms.id,
    buyer: buildCustomerContact(newCustomerFormValues.buyer, uppercaseAddress.country, 'Buyer'),
    ar: buildCustomerContact(newCustomerFormValues.ar, uppercaseAddress.country, 'Accounts Receivable'),
    address: uppercaseAddress,
    repNumber: repNumber.toString()
  }
  return newCustomer;
}

export function buildCustomerContact(formContact: { name: string | null, phone: string | null, email: string | null }, country: string, type: 'Buyer' | 'Accounts Receivable') {
  const contact: Contact = {
    name: formContact.name?.toUpperCase() ?? null,
    email: formContact.email?.toLowerCase() ?? null,
    phone: formContact.phone,
    country,
    type: type
  }
  return contact;
}

function convertAddressToUpper(address: NewCustomerAddress) {
  const newAddress = Object.assign({}, address);
  newAddress.city = newAddress.city?.toUpperCase() ?? null;
  newAddress.street1 = newAddress.street1?.toUpperCase() ?? null;
  newAddress.street2 = newAddress.street2?.toUpperCase() ?? null;
  return newAddress;
}