import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { SalesRep } from '../../interfaces/sales-rep';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatPhoneUS } from '../../utils/text-formatters.util';
import { NewCustomerFormValues } from '../../interfaces/new-customer-form-values';
import { getProvincesWithCountry, getStateCode } from '../../utils/address.util';
import { SROEValidators } from '../../validators/sales-rep-order-entry-validators';
import { Term } from '../../interfaces/term';

@Component({
  selector: 'app-new-customer-form',
  templateUrl: './new-customer-form.component.html',
  styleUrls: ['./new-customer-form.component.scss']
})
export class NewCustomerFormComponent implements OnInit {
  @Input() availableTerms: Term[] = [];
  @Input() availableCountries: string[] = [];
  @Input() availableReps: SalesRep[] | null = null;
  @Input() values: NewCustomerFormValues | null = null;
  @Input() disabledFields: string[] = [];
  @Output() jbtChange = new EventEmitter<string | null>();
  @Output() contactInfoChange = new EventEmitter<string | null>();
  @Output() valuesChange = new EventEmitter<NewCustomerFormValues>();
  @Output() isValidChange = new EventEmitter<boolean>();
  @ViewChild('jbtInput') jbtInput!: ElementRef<HTMLInputElement>;

  availableProvinces: string[] = [];
  shouldHideStateCode: boolean = false;

  generalInfoFormGroup!: FormGroup<{
    jbtNumber: FormControl<string | null>,
    name: FormControl<string | null>,
    requestedTerms: FormControl<Term | null>,
    salesRep: FormControl<SalesRep | null>
  }>;

  buyerFormGroup!: FormGroup<{
    email: FormControl<string | null>,
    confirmEmail: FormControl<string | null>,
    name: FormControl<string | null>,
    phone: FormControl<string | null>
  }>;

  arFormGroup!: FormGroup<{
    email: FormControl<string | null>,
    confirmEmail: FormControl<string | null>,
    name: FormControl<string | null>,
    phone: FormControl<string | null>,
  }>;

  addressFormGroup!: FormGroup<{
    street1: FormControl<string | null>,
    street2: FormControl<string | null>,
    city: FormControl<string | null>,
    stateCode: FormControl<string | null>,
    postalCode: FormControl<string | null>,
    country: FormControl<string | null>
   }>;

   get isValid() {
    return !this.generalInfoFormGroup.invalid && !this.buyerFormGroup.invalid && !this.arFormGroup.invalid && !this.addressFormGroup.invalid;
   }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnChanges(change?: { availableReps: SimpleChange, values: SimpleChange }) {
    if (!change?.availableReps?.firstChange && change?.availableReps?.currentValue && !this.disabledFields.includes('general') && !this.disabledFields.includes('general.salesRep')) {
      this.generalInfoFormGroup.controls.salesRep.enable({ emitEvent: false });
    }

    if (!change?.values?.firstChange && change?.values?.currentValue && !change.values.previousValue) {
      const newCustomerFormValues: NewCustomerFormValues = change?.values?.currentValue;
      this.generalInfoFormGroup.setValue(newCustomerFormValues.generalInfo, {emitEvent: false});
      const arFormVals = Object.assign({}, newCustomerFormValues.ar, { confirmEmail: newCustomerFormValues.ar.email });
      this.arFormGroup.setValue(arFormVals, {emitEvent: false});

      const buyerFormVals = Object.assign({}, newCustomerFormValues.buyer, { confirmEmail: newCustomerFormValues.buyer.email });
      this.buyerFormGroup.setValue(buyerFormVals, {emitEvent: false});
      this.addressFormGroup.setValue(newCustomerFormValues.address, {emitEvent: false});
      this.isValidChange.emit(this.isValid);

      const countryValue = this.values?.address.country;
      if (countryValue && this.doesCountryHaveStateField(countryValue)) {
        if (!this.disabledFields.includes('address.stateCode') && !this.disabledFields.includes('address')) {
          this.addressFormGroup.controls.stateCode.enable();
        }
        this.availableProvinces = getProvincesWithCountry(countryValue);
        this.shouldHideStateCode = false;
      } else {
        this.addressFormGroup.controls.stateCode.disable();
        this.shouldHideStateCode = true;
      }
    }
  }

  ngOnInit(): void {
    this.generalInfoFormGroup = this.formBuilder.group({
      jbtNumber: [this.values?.generalInfo?.jbtNumber ?? ''],
      name: [this.values?.generalInfo?.name ?? '', [Validators.required]],
      requestedTerms: [this.values?.generalInfo.requestedTerms ?? null, [Validators.required]],
      salesRep: [this.values?.generalInfo?.salesRep ?? null, [Validators.required]]
    });

    this.buyerFormGroup = this.formBuilder.group({
      email: [this.values?.buyer?.email ?? '', [Validators.required, Validators.email]],
      confirmEmail: [this.values?.buyer?.email ?? '', [Validators.required, Validators.email, SROEValidators.matchesField('email', { errorMessage: 'The value entered does not match the Buyer Email' })]],
      name: [this.values?.buyer?.name ?? '', [Validators.required]],
      phone: [this.values?.buyer?.phone ?? '', [Validators.required]],
    });

    this.arFormGroup = this.formBuilder.group({
      email: [this.values?.ar?.email ?? '', [Validators.required, Validators.email]],
      confirmEmail: [this.values?.ar?.email ?? '', [Validators.required, Validators.email, SROEValidators.matchesField('email', { errorMessage: 'The value entered does not match the AR Email' })]],
      name: [this.values?.ar?.name ?? '', [Validators.required]],
      phone: [this.values?.ar?.phone ?? '', [Validators.required]],
    });

    this.addressFormGroup = this.formBuilder.group({
      street1: [this.values?.address?.street1 ?? '', [Validators.required]],
      street2: [this.values?.address?.street2 ?? ''],
      city: [this.values?.address?.city ?? '', [Validators.required]],
      stateCode: [this.values?.address?.stateCode ?? '', [Validators.required]],
      postalCode: [this.values?.address?.postalCode ?? '', [Validators.required]],
      country: [this.values?.address?.country ?? 'United States', [Validators.required]],
    });

    const countryValue = this.addressFormGroup.controls.country.getRawValue();
    if (countryValue && this.doesCountryHaveStateField(countryValue)) {
      this.addressFormGroup.controls.stateCode.enable();
      this.availableProvinces = getProvincesWithCountry(countryValue);
      this.shouldHideStateCode = false;
    } else {
      this.addressFormGroup.controls.stateCode.disable();
      this.shouldHideStateCode = true;
    }

    if (!this.availableReps) {
      this.generalInfoFormGroup.controls.salesRep.disable();
    }

    const formGroups: Record<string, FormGroup> = { generalInfo: this.generalInfoFormGroup, buyer: this.buyerFormGroup, ar: this.arFormGroup, address: this.addressFormGroup };
    this.disabledFields.forEach(fieldPath => {
      const [formGroupKey, controlKey] = fieldPath.split('.');
      if (controlKey) {
        formGroups[formGroupKey].controls[controlKey].disable({emitEvent: false});
      } else {
        formGroups[formGroupKey].disable({emitEvent: false});
      }
    });

    this.addressFormGroup.controls.country.valueChanges.subscribe((country) => {
      this.addressFormGroup.controls.stateCode.setValue(null);

      if (country && this.doesCountryHaveStateField(country)) {
        this.addressFormGroup.controls.stateCode.enable();
        this.availableProvinces = getProvincesWithCountry(country);
        this.shouldHideStateCode = false;
      } else {
        this.addressFormGroup.controls.stateCode.disable();
        this.shouldHideStateCode = true;
      }
    });

    this.generalInfoFormGroup.controls.jbtNumber.valueChanges.subscribe((value) => {
      const selectionEnd = this.jbtInput.nativeElement.selectionEnd;
      if (value && value.match(/[^0-9]+/)) {
        const newValue = value.replace(/[^0-9]/g, '');
        this.generalInfoFormGroup.controls.jbtNumber.setValue(newValue, {emitEvent: false});
        if (selectionEnd) {
          this.jbtInput.nativeElement.selectionEnd = selectionEnd - 1;
        }
      }
    });

    this.generalInfoFormGroup.valueChanges.subscribe(this.onFormChange.bind(this));
    this.buyerFormGroup.valueChanges.subscribe(this.onFormChange.bind(this));
    this.arFormGroup.valueChanges.subscribe(this.onFormChange.bind(this));
    this.addressFormGroup.valueChanges.subscribe(this.onFormChange.bind(this));

    if (this.values) {
      this.isValidChange.emit(this.isValid);
    }
  }

  doesCountryHaveStateField(country: string) {
    var countriesWithState = ['CANADA', 'United States'];
    return countriesWithState.map(country => country.toLowerCase()).includes(country.toLowerCase());
  }

  onFormChange() {
    const formValues: NewCustomerFormValues = {
      generalInfo: this.generalInfoFormGroup.getRawValue(),
      buyer: this.buyerFormGroup.getRawValue(),
      ar: this.arFormGroup.getRawValue(),
      address: this.addressFormGroup.getRawValue(),
    };

    this.valuesChange.emit(formValues);
    this.isValidChange.emit(this.isValid);
  }

  selectOptionComparator(option: string | null, value: string | null) {
    return option === value;
  }

  repOptionComparator(option: SalesRep | null, value: SalesRep | null) {
    return option?.number === value?.number;
  }

  termsOptionComparator(option: Term | null, value: Term | null) {
    return option?.id === value?.id;
  }

  getError(formControl: AbstractControl) {
    if (formControl.hasError('required')) {
      return 'Please enter a value for this field'
    }

    if (formControl.hasError('email')) {
      return 'The email entered is not valid'
    }

    if (formControl.hasError('pattern')) {
      return 'The value entered is not valid';
    }

    const otherErrors = Object.keys(formControl.errors ?? {})

    if (otherErrors.length > 0) {
      const error = formControl.errors?.[otherErrors[0]];
      return error;
    }

    return '';
  }

  jbtChanged() {
    this.arFormGroup.controls.phone.setValue('', { })
    this.jbtChange.emit(this.generalInfoFormGroup.controls.jbtNumber.getRawValue());
  }

  contactInfoChanged(control: FormControl<string | null>) {
    if (control.valid) {
      this.contactInfoChange.emit(control.getRawValue());
    }
  }

  getProvinceAbbreviation(province: string) {
    return getStateCode(province);
  }
}
