<app-collection-group *ngFor="let collectionGroup of collectionGroups" [collection]="collectionGroup" [areRestrictionReasonsLoading]="!!user && !didOrderStatsLoad(collectionGroup)" [restrictionReasonUpdatedDate]="order?.dateUpdated ?? null" [restrictionReason]="getCollectionRestrictionReason(collectionGroup)" [shouldShowRestrictionReasons]="shouldShowRestrictionReasons">
  <div *ngIf="(collectionGroup.proformaItems?.length ?? 0) > 0" class="GroupSectionTitleContainer Flexbox Flexbox--align-center">
    <mat-icon>shopping_cart</mat-icon>
    <span class="GroupSectionTitle">Order</span>
  </div>
  <div class="ItemContainer" *ngFor="let lineItem of collectionGroup.proformaItems ?? []">
    <app-view-item [lineItem]="lineItem" [lineNumber]="getProformaItemIndex(lineItem) + 1" *ngIf="lineItem.itemType !== 'Display' && lineItem.itemType !== 'Program'"></app-view-item>
    <app-view-display-item [lineItem]="lineItem" [lineNumber]="getProformaItemIndex(lineItem) + 1" *ngIf="lineItem.itemType === 'Display'" type="Proforma"></app-view-display-item>
    <app-view-program-item [lineItem]="lineItem" [lineNumber]="getProformaItemIndex(lineItem) + 1" *ngIf="lineItem.itemType === 'Program'" type="Proforma"></app-view-program-item>
  </div>
  <div *ngIf="(collectionGroup.stockBalanceItems?.length ?? 0) > 0" class="GroupSectionTitleContainer StockBalanceTitle Flexbox Flexbox--align-center">
    <mat-icon>savings</mat-icon>
    <span class="GroupSectionTitle">Stock Balance</span>
  </div>
  <div class="ItemContainer" *ngFor="let lineItem of collectionGroup.stockBalanceItems ?? []">
    <app-view-stock-balance-item [orderStatus]="order?.status ?? null" [lineItem]="lineItem" [lineNumber]="getStockBalanceItemIndex(lineItem) + 1" [stockBalanceKey]="order?.stockBalance?.key ?? null"></app-view-stock-balance-item>
  </div>
</app-collection-group>
