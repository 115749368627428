<div class="ItemPropertiesContainer Flexbox Flexbox--row">
  <mat-form-field appearance="outline">
    <mat-label>Qty:</mat-label>
    <input matInput type="number" [ngModel]="lineItem.quantity" (ngModelChange)="quantityChanged($event)" (change)="quantityEditEnded()">
    <div class="UpDownButtons Flexbox Flexbox--column">
      <button mat-icon-button (click)="upButtonClicked()"><mat-icon>expand_less</mat-icon></button>
      <button mat-icon-button (click)="downButtonClicked()"><mat-icon>expand_more</mat-icon></button>
    </div>
  </mat-form-field>
  <mat-form-field class="PO" appearance="outline">
    <mat-label>Line Item PO:</mat-label>
    <input matInput type="text" [(ngModel)]="lineItem.poNumber" (change)="poChanged(lineItem.poNumber)">
  </mat-form-field>
</div>
