import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user';
import { SubscribableService } from 'src/app/shared/services/subscribable.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationBarUserService extends SubscribableService<User> {

  constructor() {
    super();
  }
}
