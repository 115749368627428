import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appHideErrored]'
})
export class HideErroredDirective {

  constructor() { }

  @HostListener('error', ['$event']) onError(e: ErrorEvent) {
    const target = e.target as HTMLImageElement;
    target.style.display = 'none';
  }
}
