import { LineItem } from '../interfaces/line-item';
import { ProformaLineItemAddRequest } from '../interfaces/proforma-line-item-add-request';

export function buildProformaLineItemRequest(lineItem: LineItem): ProformaLineItemAddRequest {
  const lineItemAddRequest: ProformaLineItemAddRequest = {
    key: lineItem.key,
    itemID: lineItem.item?.id ?? null,
    poNumber: lineItem.poNumber,
    quantity: lineItem.quantity,
    parentLineItemKey: lineItem.parentLineItem?.key ?? null,
    selectedWidth: lineItem.selectedWidth,
    selectedFingerSize: lineItem.selectedFingerSize,
    itemFamilyID: lineItem.item?.family?.id ?? lineItem.itemFamily.id,
    selectedMetalIDs: lineItem.selectedMetals?.map(metal => metal.id) ?? null,
    itemCollection: lineItem.item?.collection ?? lineItem.itemCollection,
    itemType: lineItem.itemType,
    discount: lineItem.discountRate ?? null,
    selectedEdgeID: lineItem.item?.edge?.id ?? null
  }
  return lineItemAddRequest;
}
