import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/shared/interfaces/customer';
import { CustomerSummaryBarService } from '../../services/customer-summary-bar.service';

@Component({
  selector: 'app-customer-summary-bar',
  templateUrl: './customer-summary-bar.component.html',
  styleUrls: ['./customer-summary-bar.component.scss']
})
export class CustomerSummaryBarComponent implements OnInit {
  customer: Customer | null = null;

  get customerNumber() {
    return this.customer?.number ?? '';
  };

  get customerName() {
    return this.customer?.name ?? '';
  }

  get commissionType() {
    return this.customer?.type ?? '';
  }

  get buyingGroup() {
    return this.customer?.buyingGroup?.name ?? '';
  }

  get accountTerms() {
    return this.customer?.defaultTerms ?? '';
  }

  get retailerLevelImage() {
    const level = this.customer?.level ?? '';
    if (level === 'silver') {
      return 'Silver_Retailer.png'
    } else if (level === 'gold') {
      return 'Gold_Retailer.png'
    } else if (level === 'platinum') {
      return 'PT_Retailer.png'
    }
    return '';
  }

  get restrictedClasses() {
    const classes = this.customer?.itemRestrictions?.map((restriction) => restriction.shortName) ?? [];
    if (classes?.length === 0) {
      return 'None';
    }
    return classes.join(', ');
  }

  constructor(private customerSummaryBarService: CustomerSummaryBarService) { }

  ngOnInit(): void {
    this.customerSummaryBarService.subscribe(this.customerChanged.bind(this))
  }

  customerChanged(customer: Customer | null) {
    this.customer = customer;
  }
}
