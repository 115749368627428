import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DuplicateLineItemDialogComponent } from 'src/app/shared/modules/edit-item/components/duplicate-line-item-dialog/duplicate-line-item-dialog.component';
import { ProformaLineItemFactory } from 'src/app/shared/factories/proforma-line-item.factory';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { AutoCompleteOption } from 'src/app/shared/modules/auto-complete/interfaces/auto-complete-option';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { ItemProviderService } from 'src/app/shared/providers/item.provider.service';
import { ProformaLineItemsService } from 'src/app/shared/services/proforma-line-items.service';
import { getTotal, getTotalQuantity } from 'src/app/shared/utils/order.util';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { BasicItem } from 'src/app/shared/interfaces/basic-item';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { AsyncAutoCompleteComponent } from 'src/app/shared/modules/auto-complete/components/async-auto-complete/async-auto-complete.component';
import { EditItemComponent } from 'src/app/shared/modules/edit-item/components/edit-item/edit-item.component';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-proforma',
  templateUrl: './proforma.component.html',
  styleUrls: ['./proforma.component.scss']
})
export class ProformaComponent implements OnInit {
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @ViewChild(AsyncAutoCompleteComponent) autocomplete!: AsyncAutoCompleteComponent;
  @ViewChildren(EditItemComponent) editItems!: EditItemComponent[];
  options: Promise<AutoCompleteOption[]> = new Promise((res) => res([]));
  lineItems: ProformaLineItem[] = [];
  items: Promise<BasicItem[]> = new Promise((res) => res([]));
  selectedSearchItem: ProformaLineItem | null = null;
  searchableLineItemOptions: AutoCompleteOption[] = [];
  autoCompleteClasses = "";
  shouldHighlightDuplicates = false;
  autoCompleteTextChangeTimeout: any | null = null;

  get orderTotal(): number {
    return getTotal(this.lineItems);
  }

  get orderQuantity() {
    return getTotalQuantity(this.lineItems);
  }

  get hasDuplicates() {
    return this.lineItems.some(lineItem => this.lineItems.filter(filterLineItem => filterLineItem.key !== lineItem.key && lineItem.item?.sku === filterLineItem.item?.sku && lineItem.item).length > 0);
  }

  get hasIncompleteFields() {
    return this.lineItems.some(lineItem => !lineItem.quantity || !lineItem.item) || this.lineItems.filter(lineItem => lineItem.itemType !== 'Display' && lineItem.itemType !== 'Program').some(lineItem => !lineItem.selectedFingerSize || !lineItem.selectedWidth);
  }

  get shouldPromptForDisplay() {
    return (this.orderQuantity > 9 || this.lineItems.some(lineItem => lineItem.itemCollection === 'Programs')) && !this.lineItems.some(lineItem => lineItem.itemCollection === 'Materials');
  }

  getType(lineItem: ProformaLineItem) {
    if (lineItem.itemType === 'Display') {
      return 'ProformaDisplay';
    }
    if (lineItem.itemType === 'Program') {
      return 'ProformaProgram';
    }
    return 'Proforma'
  }

  constructor(
      private itemProvider: ItemProviderService,
      private lineItemFactory: ProformaLineItemFactory,
      private lineItemService: ProformaLineItemsService,
      private customerSummaryBarService: CustomerSummaryBarService,
      private dialog: MatDialog,
      private changeDetector: ChangeDetectorRef,
      private userService: UserService,
      private orderService: OrderService,
    ) { }

  ngOnInit(): void {
    this.isLoadingChange.emit(true);
    this.isLoadingChange.emit(false);
    this.lineItemService.subscribe((lineItems => {
      this.lineItems = lineItems;
      this.searchableLineItemOptions = this.lineItems.flatMap(lineItem => lineItem.item ? ({ index: lineItem.key, value: lineItem.item.sku }) : []);
    }));
  }

  autoCompleteTextChange(value: string | null) {
    clearInterval(this.autoCompleteTextChangeTimeout);
    if ((value?.length ?? 0) > 2) {
      this.autoCompleteTextChangeTimeout = setTimeout(async () => {
        this.items = this.itemProvider.getAllBasic(value);
        this.options = this.getAutoCompleteOptions(this.items)
      }, 500);
    } else {
      this.items = new Promise((res) => res([]));
      this.options = new Promise((res) => res([]));
    }
  }

  async getAutoCompleteOptions(items: Promise<BasicItem[]>) {
    const itemsResult = await items;
    const options = itemsResult.map(item => ({ index: item.id, value: item.sku }));
    return options;
  }

  async itemSelected(value: AutoCompleteOption | null) {
    const itemsResult = await this.items;
    const selectedItem = itemsResult.find(item => item.id === value?.index) ?? null;
    if (selectedItem) {
      this.stopAllEdits();
      this.isLoadingChange.emit(true);
      const customer = this.customerSummaryBarService.get();
      assert(customer, 'Customer was null');
      const item = await this.itemProvider.getSingle(selectedItem.id);
      if (this.orderService.get()?.type === 'W2W' && !item.class.isW2WEligible) {
        this.dialog.open(ConfirmDialogComponent, { data: { title: 'Line Item Not Allowed', body: 'Only items classified as Plains and Carved can be added to Weight to Weight orders.', cancelButtonText: 'OK'} })
      } else if (item.type === 'Ring' && this.lineItems.some(a => a.itemType === 'Program')) {
        this.dialog.open(ConfirmDialogComponent, { data: { title: 'Line Item Not Allowed', body: 'At least one program exists on this order. Rings cannot be added alongside programs.', cancelButtonText: 'OK'} })
      } else if (item.type === 'Program' && this.lineItems.some(a => a.itemType === 'Ring')) {
        this.dialog.open(ConfirmDialogComponent, { data: { title: 'Line Item Not Allowed', body: 'At least one ring exists on this order. Rings cannot be added alongside programs.', cancelButtonText: 'OK'} })
      } else {
        const selectedLineItem = this.lineItemFactory.create(item, customer);
        this.lineItems = this.lineItems.concat(selectedLineItem);
        this.lineItemService.add(selectedLineItem);
      }
      this.autocomplete.clear();
      this.isLoadingChange.emit(false);
    }
  }

  stopAllEdits() {
    this.editItems.forEach(editItem => editItem.isEditing = false);
  }

  quickCopyLineItem(lineItem: ProformaLineItem) {
    this.duplicateLineItem(lineItem);
  }

  copyLineItem(lineItem: ProformaLineItem) {
    this.dialog.open(DuplicateLineItemDialogComponent).afterClosed().subscribe(copyQuantity => {
      this.duplicateLineItem(lineItem, copyQuantity);
    });
  }

  private duplicateLineItem(lineItem: ProformaLineItem, nTimes: number = 1) {
    const copiedLineItems = new Array(nTimes).fill(null).map(_ => Object.assign({}, lineItem, { key: `${this.userService.getKeyPrefix()}-${crypto.randomUUID()}` }));
    this.lineItems = copiedLineItems.concat(this.lineItems)
    this.lineItemService.set(this.lineItems);
  }

  lineItemChanged(changedLineItem: ProformaLineItem) {
    this.lineItemService.set(this.lineItems);
  }

  removeLineItem(removedLineItem: ProformaLineItem) {
    const dialogReference = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Remove line item?', body: 'Are you sure you want to remove this line item?', cancelButtonText: 'Cancel', confirmButtonText: 'Remove' } })
    dialogReference.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.lineItems = this.lineItems.filter(lineItem => lineItem.key !== removedLineItem.key);
        this.lineItemService.set(this.lineItems);
      } else if (removedLineItem.quantity <= 0) {
        removedLineItem.quantity = 1;
        const removedItemIndex = this.lineItems.findIndex(lineItem => lineItem.key === removedLineItem.key);
        this.lineItems[removedItemIndex] = Object.assign({}, this.lineItems[removedItemIndex]);
        this.lineItems = this.lineItems.concat();
        this.lineItemService.set(this.lineItems);
        this.changeDetector.detectChanges();
      }
    });
  }

  searchSelected(value: AutoCompleteOption | null) {
    const matchingLineItem = this.lineItems.find(lineItem => lineItem.item && lineItem.key === value?.index) ?? null;
    if (matchingLineItem) {
      const $matchingElement = document.querySelector(`[data-key="${matchingLineItem.key}"]`);
      if ($matchingElement) {
        window.scrollTo({ top: $matchingElement.getBoundingClientRect().top - 193, behavior: 'smooth' })
        const $editItemContainer = $matchingElement.querySelector('.EditItemContainer')
        $editItemContainer?.classList.toggle('animation', true);
        $editItemContainer?.addEventListener('animationend', () => {
          $editItemContainer.classList.toggle('animation', false);
        });
      }
    }
  }

  showDuplicates() {
    this.shouldHighlightDuplicates = true;
    this.lineItems = this.lineItems.reduce((newLineItems, lineItem) => {
      const duplicateItemIndex = newLineItems.findIndex(newLineItem => newLineItem.key !== lineItem.key && newLineItem.item && newLineItem.item?.sku === lineItem.item?.sku);
      if (duplicateItemIndex >= 0) {
        const itemsBeforeDuplicate = newLineItems.slice(0, duplicateItemIndex);
        const itemsAfterDuplicate = newLineItems.slice(duplicateItemIndex, newLineItems.length);

        return itemsBeforeDuplicate.concat(lineItem, itemsAfterDuplicate);
      } else {
        return newLineItems.concat(lineItem);
      }
    }, [] as ProformaLineItem[]);
  }

  isDuplicateLineItem(currentLineItem: LineItem) {
    return this.lineItems.some(lineItem =>  currentLineItem.key !== lineItem.key && currentLineItem.item && currentLineItem.item.sku === lineItem.item?.sku);
  }

  onEditButtonClicked(index: number) {
    this.editItems.forEach((item, i) => {
      if (index !== i) {
        item.stopEdit();
      }
    });
  }
}
