import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchesField(formControlNameToMatch: string, options?: { comparator?: (a: any, b: any) => boolean, errorMessage?: string}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !control.parent) {
      return null;
    }

    const compare = options?.comparator ?? ((a, b) => a === b);

    return !compare(control.value, control.parent.get(formControlNameToMatch)?.value) ? { matchesValue: options?.errorMessage ?? 'The given value did not match the expected value' } : null;
  }
}
