import { Injectable } from '@angular/core';
import { WebProviderService } from './web.provider.service';
import { USER_ROLES } from '../constants/user-roles';
import { assert } from '../utils/assert.util';

@Injectable({
  providedIn: 'root'
})
export class UserRolesProviderService {

  constructor(private webProvider: WebProviderService) { }

  async get() {
    const roles = await this.webProvider.get<string[]>(`${USER_ROLES.API_URL}`);
    assert(roles, 'User Roles response was null.');
    return roles;
  }
}
