<div *ngFor="let navigationItem of navigationItems" class="MenuItemContainer">
  <mat-card class="MenuItem">
    <button [routerLink]="navigationItem.url" class="MenuItemContent" mat-menu-item>
      <div class="ButtonContent">
        <mat-icon color="success">{{ navigationItem.icon }}</mat-icon>
        <span>{{ navigationItem.title }}</span>
        <span class="subtitle">{{ navigationItem.subTitle }}</span>
      </div>
    </button>
  </mat-card>
</div>