import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './shared/interfaces/user';
import { NavigationBarUserService } from './shared/modules/navigation/services/navigation-bar-user.service';
import { OrderProviderService } from './shared/providers/order.provider.service';
import { ProformaLineItemProviderService } from './shared/providers/proforma-line-item.provider.service';
import { SubmitOrderService } from './shared/services/submit-order.service';
import { UserService } from './shared/services/user.service';
import { assert } from './shared/utils/assert.util';
import { buildProformaLineItemRequest } from './shared/utils/proforma.util';
import * as LogRocket from 'logrocket';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'sales-rep-order-entry-frontend';
  isSummaryBarVisible = false;
  user: User | null = null;
  autoSaveSubscription: Subscription | null = null;
  lastSaved: Date | null = null;
  canAutoSave = false;
  isNavBarVisible = true;
  checkForUpdateSubscription?: Subscription

  get environmentType() {
    return environment.type;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private navigationBarUserService: NavigationBarUserService,
    private submitOrderService: SubmitOrderService,
    private orderProvider: OrderProviderService,
    private proformaLineItemProvider: ProformaLineItemProviderService,
    private swUpdate: SwUpdate,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.userService.get();
    if (this.user) {
      this.navigationBarUserService.set(this.user);
    }
    this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe(this.routerNavigated.bind(this));

    if (environment.production) {
      LogRocket.init('2dfqbc/rob-production');
    }

    if (environment.type === 'staging') {
      LogRocket.init('2dfqbc/rob-staging');
    }

    if ((environment.type === 'production' || environment.type === 'staging') && this.user) {
      LogRocket.identify(this.user.email, {
        name: this.user.name,
        email: this.user.email
      });
    }


    if (this.swUpdate.isEnabled) {
      console.log('Scheduling update check');
      this.scheduleUpdateCheck();
    } else {
      console.warn('Update check not scheduled');
    }
    // this.toggleAutoSave(this.canAutoSave);
  }

  private scheduleUpdateCheck() {
    this.checkForUpdateSubscription?.unsubscribe();
    this.checkForUpdateSubscription = interval(30000).subscribe(async () => {
      this.checkForUpdateSubscription?.unsubscribe();
      console.log('Checking for client updates...');
      try {
        const hasUpdate = await this.swUpdate.checkForUpdate();
        if (hasUpdate) {
          console.log('Update found! Prompting user to reload');
          this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { title: 'Update Available', body: 'A new version of the Sales Rep Order Pad is available. Please reload the page for the changes to take effect.', cancelButtonText: 'Reload' } }).afterClosed().subscribe(() => {
            window.location.reload();
          });
        } else {
          console.log('No updates.');
        }
      } catch(e) {
        console.error('Error checking for updates.', e);
      } finally {
        this.scheduleUpdateCheck();
      }
    });
  }

  @HostListener('window:beforeprint')
  onWindowBeforePrint() {
    console.log('Hiding all empty flexboxes with gap')
    Array.from(document.querySelectorAll('*')).filter(node => node.children.length == 0 && window.getComputedStyle(node).display === 'flex' && window.getComputedStyle(node).gap && window.getComputedStyle(node).gap !== 'normal').forEach((node: any) => node.style.display = 'none');
  }

  routerNavigated(event: any) {
    const realEvent = event as RoutesRecognized;
    this.isSummaryBarVisible = realEvent.state.root.firstChild?.data['summaryBar'];
    this.isNavBarVisible = realEvent.state.root.firstChild?.data['navBar'] ?? true;
    document.title = realEvent.state.root.firstChild?.data['title'] ?? 'Sales Rep Order Pad | Benchmark'
  }
}
