import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LOGIN } from '../constants/login';
import { assert } from '../utils/assert.util';
import { WebProviderService } from './web.provider.service';

@Injectable({
  providedIn: 'root'
})
export class LoginProviderService {

  constructor(private webProvider: WebProviderService) { }

  async login(username: string, password: string) {
    try {
      await this.webProvider.makeRequest<void>('POST', `${LOGIN.API_URL}/login`, { username, password });
      return true;
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async logout() {
    try {
      await this.webProvider.makeRequest<void>('DELETE', `${LOGIN.API_URL}/logout`);
      return true;
    } catch(e) {
      console.error(e);
      return false;
    }
  }
}
