<h1 mat-dialog-title class="Title">Overwrite Form?</h1>
<div mat-dialog-content>
  <div class="Flexbox Flexbox--column CustomerDiffContainer">
    <span class="Subtitle">Would you like to overwrite this form with the following values?</span>
    <table>
      <thead *ngIf="shouldShowNameDiff">
        <tr>
          <th><h5>Current Store Name</h5></th>
          <th><h5>New Store Name</h5></th>
        </tr>
      </thead>
      <tbody *ngIf="shouldShowNameDiff">
        <tr>
          <td>{{ existingCustomer.storeName }}</td>
          <td>{{ replacingCustomer.name }}</td>
        </tr>
      </tbody>
      <thead *ngIf="shouldShowTermsDiff"><tr>
        <th><h5>Current Requested Account Terms</h5></th>
        <th><h5>New Requested Account Terms</h5></th>
      </tr></thead>
      <tbody *ngIf="shouldShowTermsDiff">
        <tr>
          <td>{{ existingCustomer.requestedTerms }}</td>
          <td>{{ replacingCustomer.defaultTerms }}</td>
        </tr>
      </tbody>
      <thead *ngIf="replacingCustomer.billingAddress && shouldShowAddressDiff">
        <tr>
          <th><h5>Current Address</h5></th>
          <th><h5>New Address</h5></th>
        </tr>
      </thead>
      <tbody *ngIf="replacingCustomer.billingAddress && shouldShowAddressDiff">
        <tr>
          <td><app-address [address]="buildAddressToShow(existingCustomer.address)"></app-address></td>
          <td><app-address [address]="buildAddressToShow(replacingCustomer.billingAddress)"></app-address></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<mat-dialog-actions class="Flexbox">
  <button mat-button [mat-dialog-close] cdkFocusInitial>Keep My Values</button>
  <button mat-button [mat-dialog-close]="true">Replace My Values</button>
</mat-dialog-actions>
