<h1 mat-dialog-title>Deny Order</h1>
<mat-dialog-content>
  <mat-form-field class="ReasonsField" appearance="outline">
    <mat-label>Reason(s) for denial</mat-label>
    <textarea matInput [formControl]="reasonFormControl"></textarea>
    <mat-error *ngIf="reasonFormControl.invalid">Please provide a reason for denial.</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Cancel</button>
  <button mat-button (click)="deny()">Deny</button>
</mat-dialog-actions>
