import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appPatternEnforcer]'
})
export class PatternEnforcerDirective {
  @Input() appPatternEnforcer!: string;

  constructor(private element: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    // this.element.nativeElement.addEventListener('keypress', (event: KeyboardEvent) => {
    //   const input = this.element.nativeElement;
    //   const selectionEnd = input.selectionEnd;
    //   const regex = new RegExp(this.appPatternEnforcer, 'g');
    //   const matchResults = event.key.match(regex);
    //   if (matchResults && matchResults.length > 0) {
    //     input.value = input.value;
    //   }
    // });
  }
}
