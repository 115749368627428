import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderListRootComponent } from './components/order-list-root/order-list-root.component';
import { SharedAngularMaterialModule } from 'src/app/shared/modules/shared-angular-material/shared-angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';



@NgModule({
  declarations: [
    OrderListRootComponent,
    InfoDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedAngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NativeDateModule,
    SharedModule,
    RouterModule
  ]
})
export class OrderListModule { }
