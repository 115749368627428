import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appExpandableField]'
})
export class ExpandableFieldDirective {
  @Output() animationEnd = new EventEmitter();
  @Output() openAnimationEnd = new EventEmitter();
  @Output() closeAnimationEnd = new EventEmitter();
  @Output() focusChanged = new EventEmitter<boolean>()
  input: HTMLInputElement | null = null;

  constructor(private element: ElementRef) {}

   ngAfterViewInit() {
    if (this.element.nativeElement.tagName === 'INPUT') {
      this.input = this.element.nativeElement;
    } else {
      this.input = this.element.nativeElement.querySelector('input');
    }

    this.input?.addEventListener('focus', () => {
      this.element.nativeElement.classList.toggle('Expanded', true)
      this.focusChanged.emit(true)
    });

    this.input?.addEventListener('blur', () => {
      this.element.nativeElement.classList.toggle('Expanded', false)
      this.focusChanged.emit(false);
    });

    this.element.nativeElement.addEventListener('animationend', (a: AnimationEvent) => {
      if (a.animationName === 'accordion-close' || a.animationName === 'accordion-open') {
        this.animationEnd.emit();
      }

      if (a.animationName === 'accordion-close') {
        this.closeAnimationEnd.emit();
      }

      if (a.animationName === 'accordion-open') {
        this.openAnimationEnd.emit();
      }
    });
   }
}
