import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/shared/interfaces/line-item';
import { StockBalanceLineItem } from '../../interfaces/stock-balance-line-item';

@Component({
  selector: 'app-stock-balance-line-summary[lineItem]',
  templateUrl: './stock-balance-line-summary.component.html',
  styleUrls: ['./stock-balance-line-summary.component.scss']
})
export class StockBalanceLineSummaryComponent implements OnInit {
  @Input() lineItem!: StockBalanceLineItem;
  @Input() type: 'all' | 'qty' = 'all'

  constructor() { }

  ngOnInit(): void {
  }

}
