<app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
<div class="Flexbox Flexbox--align-center TopContainer">
  <div class="TabContainer Flexbox">
    <button mat-flat-button class="TabButton OrderButton" [ngClass]="selectedTab === 0 ? 'TabActive' : ''" (click)="tabButtonClicked(0)">Order</button>
    <button mat-flat-button class="TabButton StockBalanceButton" *ngIf="canEnterStockBalance" [ngClass]="selectedTab === 1 ? 'TabActive' : ''" (click)="tabButtonClicked(1)">Stock Balance</button>
  </div>
  <app-order-save-indicator [lastSaved]="lastSavedAt" [isSaving]="isAutoSaving" [hasUnsavedChanges]="hasOrderChanges"></app-order-save-indicator>
</div>
<div class="Divider"></div>
<div [hidden]="selectedTab !== 0" class="TabContent">
  <app-collection-summary [collections]="summaryCollections" [restrictedCollections]="restrictedCollections"></app-collection-summary>
  <div class="Divider BellowSummaryDivider" *ngIf="summaryCollections.length > 0"></div>
  <app-proforma (isLoadingChange)="isProformaLoadingChanged($event)"></app-proforma>
</div>
<div [hidden]="selectedTab !== 1" class="TabContent">
  <app-collection-summary [collections]="StockBalanceRelatedSummaryCollections" [restrictedCollections]="restrictedCollections"></app-collection-summary>
  <div class="Divider BellowSummaryDivider" *ngIf="StockBalanceRelatedSummaryCollections.length > 0"></div>
  <app-stock-balance></app-stock-balance>
</div>
<div class="BottomButtonContainer Flexbox Flexbox--row">
  <button mat-raised-button *ngIf="canDelete" [disabled]="isAutoSaving" color="warn" (click)="deleteButtonClicked()">Delete</button>
  <button mat-raised-button *ngIf="canSaveAsDraft" [disabled]="isAutoSaving" (click)="saveAsDraftClicked()">{{ saveButtonText }}</button>
  <button mat-raised-button *ngIf="canSaveAndClose" [disabled]="isAutoSaving" (click)="saveAndCloseClicked()">Save and Close</button>
  <button mat-raised-button *ngIf="shouldShowNextButton" color="accent" (click)="nextClicked()">Next</button>
</div>