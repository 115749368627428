import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSummaryBarComponent } from './components/customer-summary-bar/customer-summary-bar.component';



@NgModule({
  declarations: [
    CustomerSummaryBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomerSummaryBarComponent
  ]
})
export class CustomerSummaryBarModule { }
