import { Component, Input, OnInit } from '@angular/core';
import { StockBalanceLineItem } from '../../interfaces/stock-balance-line-item';

@Component({
  selector: 'app-stock-balance-customer-stats[lineItem]',
  templateUrl: './stock-balance-customer-stats.component.html',
  styleUrls: ['./stock-balance-customer-stats.component.scss']
})
export class StockBalanceCustomerStatsComponent implements OnInit {
  @Input() lineItem!: StockBalanceLineItem;

  constructor() { }

  ngOnInit(): void {
  }

}
